import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import React, { FC } from 'react';

import { LuneTheme } from '../theme';

const Pin: FC<{
  src?: string;
  title?: string;
  subTitle?: string;
  onClick?: () => void;
  children?: React.ReactNode;
  active?: boolean;
  // eslint-disable-next-line complexity
}> = ({ src, children, title, subTitle, onClick, active }) => {
  const { palette } = LuneTheme;
  const expandable = title || subTitle || !!onClick;

  if (src === undefined && children === undefined) {
    throw new Error('One of src or children must be defined');
  }

  const pinStyle: SxProps<typeof LuneTheme> = {
    position: 'absolute',
    left: 0,
    bottom: 0,
    display: 'flex',
    width: 'max-content',
    minWidth: '40px',
    maxWidth: '40px',
    backgroundColor: palette.Grey900,
    boxShadow:
      '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)',
    borderRadius: '32px 32px 32px 0px',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    p: 1 / 2,
    boxSizing: 'border-box',
    transition: 'all .3s cubic-bezier(0.25, 0.1, 0.25, 1)',
    height: '40px',
    cursor: onClick ? 'pointer' : 'normal',

    '.pin-image': {
      margin: 'auto',
      borderRadius: '80px',
      width: '32px',
      height: '32px',
      transition: 'all .3s cubic-bezier(0.25, 0.1, 0.25, 1)',
    },
    '.pin-text': {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      flexDirection: 'column',
      gap: '4px',
      transition: 'all .3s cubic-bezier(0.25, 0.1, 0.25, 1)',
      ml: 1 / 2,
      mr: onClick ? 0 : 1 / 2,
      opacity: 0,
    },
    '.pin-arrow': {
      color: palette.White,
      height: '12px',
      transition: 'all .3s cubic-bezier(0.25, 0.1, 0.25, 1)',
      mr: 1,
      ml: 1 / 2,
      opacity: 0,
    },

    ...(expandable && {
      '&.active, &:hover, &:focus': {
        p: 1,
        maxWidth: '420px',
        height: '64px',

        '.pin-image': {
          width: '48px',
          height: '48px',
        },

        '.pin-text': {
          ml: 1,
          mr: onClick ? 0 : 1,
          opacity: 1,
        },
        '.pin-arrow': {
          ml: 1,
          opacity: 1,
        },
      },
    }),
  };

  return (
    <Box
      sx={{
        position: 'relative',
        height: '44px',
        width: '44px',
      }}
    >
      <Box className={active ? 'active' : ''} {...(!!onClick && { onClick })} sx={{ ...pinStyle }}>
        {children || <img className={'pin-image'} alt={'pin-img'} src={src} />}
        {expandable && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: 'row',
              overflow: 'hidden',
            }}
          >
            <Box className={'pin-text'}>
              {title && (
                <Typography variant={'body3'} sx={{ color: palette.White }}>
                  {title}
                </Typography>
              )}
              {subTitle && (
                <Typography variant={'caption'} sx={{ color: palette.Grey500 }}>
                  {subTitle}
                </Typography>
              )}
            </Box>
            {!!onClick && <ArrowForwardIosIcon className={'pin-arrow'} />}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Pin;
