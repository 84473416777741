import ChevronRight from '@mui/icons-material/ChevronRight';
import type { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import React, { FC } from 'react';

import { LuneTheme } from '../theme';

const Image = styled('img')(
  LuneTheme.unstable_sx({
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  }),
);

const CtaTile: FC<{
  title: string;
  subTitle?: string;
  image: string | React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  sxProps?: SxProps;
  mini?: boolean;
  showArrow?: boolean;
  href?: string;
  icon?: React.ReactNode;
  newPage?: boolean;
  // eslint-disable-next-line complexity
}> = ({ image, title, subTitle, onClick, disabled, href, sxProps, mini, showArrow = true, icon, newPage, ...rest }) => {
  const { palette } = LuneTheme;
  return (
    <Button
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: mini ? '12px' : '24px',
        width: '100%',
        padding: 0,
        '&:hover': {
          backgroundColor: palette.Grey100,
          cursor: 'pointer',
          transition: 'none',
        },
        '&.Mui-disabled': {
          opacity: 0.5,
          pointerEvents: 'none', // allow :hover styles to be triggered, tooltips need them
        },
        '&.Mui-disabled:hover': {
          backgroundColor: 'transparent',
        },
        ...(sxProps || ''),
      }}
      disableRipple
      disabled={disabled}
      onClick={onClick}
      href={href}
      {...(newPage ? { target: '_blank' } : {})}
      {...rest}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <Box
          sx={{
            backgroundColor: palette.Grey100,
            pt: mini ? '6px' : '12px',
            pr: mini ? '6px' : '12px',
            pl: mini ? '6px' : '12px',
            pb: 0,
            borderRadius: mini ? '12px' : '24px',
            overflow: 'hidden',
            minWidth: mini ? '75px' : '150px',
            maxHeight: '108px',
          }}
        >
          <Box
            sx={{
              mb: -1 / 2,
              width: mini ? `75px` : `150px`,
              height: mini ? `auto` : `108px`,
              img: {
                width: '100%',
              },
            }}
          >
            {typeof image === 'string' ? <Image src={image} /> : <>{image}</>}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            ml: 2,
          }}
        >
          {mini ? (
            <Typography variant={'body3'} sx={{ fontWeight: 500 }}>
              {title}
            </Typography>
          ) : (
            <Typography variant={'h6'}>{title}</Typography>
          )}
          {subTitle && (
            <Typography variant="body1" sx={{ mt: 1 }}>
              {subTitle}
            </Typography>
          )}
        </Box>
      </Box>
      {showArrow && (
        <Box
          sx={{
            height: `48px`,
            width: `48px`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {icon || (
            <ChevronRight
              sx={{
                color: palette.Grey900,
              }}
            />
          )}
        </Box>
      )}
    </Button>
  );
};

export default CtaTile;
