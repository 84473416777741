import { Option, None, Some } from 'ts-results-es'

import {
    InferredVesselShippingMethod,
} from '@lune-climate/lune'

export const FORMATTED_CARRIER_NAMES: Record<InferredVesselShippingMethod.carrier_name, string> = {
    [InferredVesselShippingMethod.carrier_name.ALIAN_A]: 'Aliança',
    [InferredVesselShippingMethod.carrier_name.ANL]: 'ANL',
    [InferredVesselShippingMethod.carrier_name.CMA_CGM]: 'CMA CGM',
    [InferredVesselShippingMethod.carrier_name.CNC]: 'CNC',
    [InferredVesselShippingMethod.carrier_name.COSCO_SHIPPING]: 'COSCO Shipping',
    [InferredVesselShippingMethod.carrier_name.CROWLEY]: 'Crowley',
    [InferredVesselShippingMethod.carrier_name.EVERGREEN]: 'Evergreen',
    [InferredVesselShippingMethod.carrier_name.GOLD_STAR_LINE]: 'Gold Star Line',
    [InferredVesselShippingMethod.carrier_name.HAIHUA_SHIPPING]: 'Haihua Shipping',
    [InferredVesselShippingMethod.carrier_name.HAMBURG_S_D]: 'Hamburg Süd',
    [InferredVesselShippingMethod.carrier_name.HAPAG_LLOYD]: 'Hapag-Lloyd',
    [InferredVesselShippingMethod.carrier_name.HMM]: 'HMM',
    [InferredVesselShippingMethod.carrier_name.ICL]: 'ICL',
    [InferredVesselShippingMethod.carrier_name.JINJIANG_SHIPPING]: 'Jinjiang Shipping',
    [InferredVesselShippingMethod.carrier_name.KAMBARA_KISEN]: 'Kambara Kisen',
    [InferredVesselShippingMethod.carrier_name.KOREA_MARINE_TRANSPORT]: 'Korea Marine Transport',
    [InferredVesselShippingMethod.carrier_name.MAERSK]: 'Maersk',
    [InferredVesselShippingMethod.carrier_name.MATSON]: 'Matson',
    [InferredVesselShippingMethod.carrier_name.MCC]: 'MCC',
    [InferredVesselShippingMethod.carrier_name.MSC]: 'MSC',
    [InferredVesselShippingMethod.carrier_name.NAMSUNG]: 'Namsung',
    [InferredVesselShippingMethod.carrier_name.ONE]: 'ONE',
    [InferredVesselShippingMethod.carrier_name.OOCL]: 'OOCL',
    [InferredVesselShippingMethod.carrier_name.PIL]: 'PIL',
    [InferredVesselShippingMethod.carrier_name.SEAGO]: 'Seago',
    [InferredVesselShippingMethod.carrier_name.SEALAND]: 'Sealand',
    [InferredVesselShippingMethod.carrier_name.SITC]: 'SITC',
    [InferredVesselShippingMethod.carrier_name.SM_LINE]: 'SM Line',
    [InferredVesselShippingMethod.carrier_name.WAN_HAI]: 'Wan Hai',
    [InferredVesselShippingMethod.carrier_name.YANG_MING]: 'Yang Ming',
    [InferredVesselShippingMethod.carrier_name.ZIM]: 'ZIM'
}

export function parseVesselInferenceDetails(details: string): Option<{formattedVessel: string}> {
    const match = details.match(/Picked (.+) from the following vessels/)
    if (match === null) {
        return None
    }
    return Some({formattedVessel: match[1]})
}
