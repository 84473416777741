import ArrowBack from '@mui/icons-material/ArrowBack';
import type { SxProps } from '@mui/material';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import React from 'react';

import { LuneTheme } from '../theme';
import Button from './Button';

const BackButton = ({
  label,
  onClick,
  sx,
  iconColor,
  href,
  ...rest
}: {
  label?: string;
  onClick: () => void;
  sx?: SxProps;
  iconColor?: string;
  href?: string;
}): JSX.Element => {
  const ButtonStyledWrapper = styled('div')(
    LuneTheme.unstable_sx({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: 'max-content',
      ...sx,
    }),
  );

  return (
    <ButtonStyledWrapper {...rest}>
      <Button
        variant="text"
        iconButton
        leftIcon={<ArrowBack sx={{ color: iconColor || 'black' }} />}
        onClick={onClick}
        {...(href ? { href } : {})}
      />
      {label && (
        <Typography
          sx={{
            ml: '12px',
          }}
          variant={'h5'}
        >
          {label}
        </Typography>
      )}
    </ButtonStyledWrapper>
  );
};

export default BackButton;
