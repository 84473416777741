import { GeographicCoordinates, Mass } from '@lune-climate/lune'
import { Big } from 'big.js'
import getUserLocale from 'get-user-locale'
import { toJpeg } from 'html-to-image'
import moment from 'moment'

import { IApiKey } from 'models/apiKey'

export const flattenObj = (input: Record<string, any>) => {
    const result: any = {}
    for (const key in input) {
        if (typeof input[key] === 'object' && !Array.isArray(input[key])) {
            const temp = flattenObj(input[key])
            for (const j in temp) {
                result[key + ' - ' + j] = temp[j]
            }
        } else {
            result[key] = input[key]
        }
    }
    return result
}

export const capitalize = (s: string): string => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

export const formatTimestamp = (isoTimestamp: string): string => {
    return moment(isoTimestamp).format('LLL')
}

export const truncateStringWithoutCutWords = (str: string | undefined, maxLen: number) => {
    const separator = ` `
    if (str === undefined || str.length <= maxLen) return str
    return str.substr(0, str.lastIndexOf(separator, maxLen))
}

export const maskify = (data: string, nrVisibleChars: number = 8) =>
    data.slice(0, -nrVisibleChars).replace(/./g, '∗') + data.slice(-nrVisibleChars)

export const formatNumbers = (number: number | string, decimals?: number) => {
    const userLocale = getUserLocale() || 'en-GB'
    let numberFormat

    if (decimals) {
        number = parseFloat(number.toString()).toFixed(decimals)
    }

    try {
        numberFormat = new Intl.NumberFormat(userLocale)
    } catch (e: any) {
        // ^ No type safety in this block, we can in principle receive anything and assume things.

        console.warn(
            `${e.name} was caught while constructing Intl.NumberFormat. 
                      Retrying with omitted currencyDisplay: 'narrowSymbol' (known not to be supported on some Safari versions...`,
        )
        // Fallback config omitting currencyDisplay: 'narrowSymbol',
        // Mainly needed for Safari 10-14
        // Details https://caniuse.com/mdn-javascript_builtins_intl_numberformat_numberformat_currencydisplay
        numberFormat = new Intl.NumberFormat(userLocale)
    }

    try {
        return numberFormat.format(Big(number).toNumber())
    } catch {}

    return number
}

export const formatToCurrency = (number: number | string, currency: string) => {
    if (!currency) {
        return number.toString()
    }

    const userLocale = getUserLocale() || 'en-GB'
    let numberFormat

    try {
        numberFormat = new Intl.NumberFormat(userLocale, {
            style: 'currency',
            currency,
            currencyDisplay: 'narrowSymbol',
        })
    } catch (e: any) {
        // ^ No type safety in this block, we can in principle receive anything and assume things.

        console.warn(
            `${e.name} was caught while constructing Intl.NumberFormat. 
                      Retrying with omitted currencyDisplay: 'narrowSymbol' (known not to be supported on some Safari versions...`,
        )
        // Fallback config omitting currencyDisplay: 'narrowSymbol',
        // Mainly needed for Safari 10-14
        // Details https://caniuse.com/mdn-javascript_builtins_intl_numberformat_numberformat_currencydisplay
        numberFormat = new Intl.NumberFormat(userLocale, {
            style: 'currency',
            currency,
        })
    }

    return numberFormat.format(Big(number).toNumber())
}

/**
 * This takes a bundle selection object and returns the sum of the percentages as Big
 * eg { 'bundle-id-1': 50, 'bundle-id-2': 50 } => Big(100)
 * @param bundleSelection
 * @param volume
 */
export const calculateBundleSelectionTotalSum = (
    bundleSelection: Record<string, number | string>,
    volume?: boolean,
): Big =>
    Object.values(bundleSelection).reduce((sum, currentAllocation) => {
        try {
            const amountAsBig = Big(currentAllocation)
            if (!!volume || (amountAsBig.gte(Big(0)) && amountAsBig.lte(Big(100)))) {
                return sum.add(amountAsBig)
            }
        } catch {}
        return sum
    }, Big(0))

export const refreshDocsTestApiKeyIfPossible = (apiKeyId: string, newKey: IApiKey) => {
    const currentTestAPIDoc = JSON.parse(localStorage.getItem('docs_test_api_key') || '{}')
    if (currentTestAPIDoc.id === apiKeyId) {
        const cookieDomain = process.env.REACT_APP_DOCS_COOKIE_DOMAIN
            ? `; domain=${process.env.REACT_APP_DOCS_COOKIE_DOMAIN}`
            : ''
        document.cookie = `docs_test_api_key=${newKey.fullSecret}; Secure; SameSite=Strict${cookieDomain}`
        localStorage.setItem('docs_test_api_key', JSON.stringify(newKey))
    }
}

export function getTestApiKey(): string | undefined {
    return document.cookie
        .split('; ')
        .find((row) => row.startsWith('docs_test_api_key='))
        ?.split('=')[1]
}

export const toTitleCase = (s: string) => {
    return s[0].toUpperCase() + s.slice(1)
}

export const snakeToCamel = (s: string) => {
    return s.replace(/([-_][a-z])/g, (group) =>
        group.toUpperCase().replace('-', '').replace('_', ''),
    )
}

export const pluralize = (word: string, number: number) => {
    return `${word}${number === 1 ? '' : 's'}`
}

/**
 * The function's only purpose is to fail at compile time if the parameter passed is *not*
 * of the type specified.
 *
 * This is useful to ensure, at compile time, what type are we dealing with. Particularly
 * helpful in making sure we handle all possible values in some context.
 *
 * @example
 *
 * ```
 * function f(value: 'a' | 'b') {
 *     if (value === 'a') {
 *         console.log('We got a')
 *     }
 *     else {
 *         assertType<'b'>(value)
 *         console.log('We got b')
 *     }
 * }
 * ```
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function assertType<T>(_value: T) {}

export const downloadImageFromHtml = (element: HTMLElement, fileName?: string) => {
    toJpeg(element, {
        backgroundColor: 'white',
        skipAutoScale: false,
        type: 'image/jpeg',
    })
        .then((dataUrl) => {
            const link = document.createElement('a')
            link.download = fileName || 'image.svg'
            link.href = dataUrl
            link.click()
        })
        .catch((err) => {
            console.log(err)
        })
}

export const luneAssetsToDynamicAssets = (
    imageUrl: string,
    width: number,
    height: number,
): string => {
    if (imageUrl.includes('https://assets.lune.co/')) {
        const newUrl = imageUrl.replace(
            'https://assets.lune.co/',
            'https://dynamic-assets.lune.co/',
        )
        return `${newUrl}?width=${width}&height=${height}`
    } else {
        return imageUrl
    }
}

/**
 * Takes an object A of type Partial<T> and object B of type T.
 * Throws an error if trying set a key to undefined that is not allowed to be undefined.
 *
 * This is a helper function for types with optional values. It's a workaround for TS's inability to distinguish
 * between "undefined" and "optional" keys in an object and thus allowing explicit undefined values to be passed in.
 * e.g. { key: undefined } is allowed by Partial<{ key?: string }> but it shouldn't be.
 * (rule address this https://www.typescriptlang.org/tsconfig#exactOptionalPropertyTypes, but we don't want to enable it)
 * @param partialUpdate - the partial update object (Partial<T>)
 * @param validStateWithUndefined - the valid state object (T) with undefined values for keys that are allowed to be
 * undefined (generally this will be the initial state of the object)
 */
export const validatePartialUpdate = <T extends Record<string, any | undefined>>({
    partialUpdate,
    validStateWithUndefined,
}: {
    partialUpdate: Partial<T>
    validStateWithUndefined: T
}) => {
    // Check which keys are undefined (and are therefore allowed to be undefined) from validState object
    const allowedUndefinedKeys = Object.keys(validStateWithUndefined).filter(
        (key) => validStateWithUndefined[key] === undefined,
    )

    Object.keys(partialUpdate).forEach((updateKey) => {
        if (!allowedUndefinedKeys.includes(updateKey) && partialUpdate[updateKey] === undefined) {
            throw new Error(
                `buildValidPartialUpdate: key ${updateKey} in a partial update is not allowed to be undefined according to the provided valid state. Make sure you're not passing in undefined values for keys that are OPTIONAL but not allowed to be undefined.`,
            )
        }
    })
}

/**
 * Converts degrees to radians.
 */
export function degToRad(deg: number): number {
    return (deg * Math.PI) / 180.0
}

/**
 * Converts radians to degrees.
 */
export function radToDeg(rad: number): number {
    return (rad * 180.0) / Math.PI
}

/**
 * Produces an array with numbers from 0 (inclusive) to `count` (exclusive).
 *
 * Providing a number lower than 1 or a floating point number as `count` is a programming error.
 */
export function range(count: number): number[] {
    if (!Number.isInteger(count) || count < 1) {
        throw new Error(`Floating point or too low count: ${count}`)
    }

    return Array.from(Array(count).keys())
}

/**
 * Produces an array of `count` intermediate points between `a` and `b`.
 *
 * Passing a number lower than 1 or a floating point number as `count` is a programming error.
 */
export function intermediateCoordinates(
    { lat: lat1deg, lon: lon1deg }: GeographicCoordinates,
    { lat: lat2deg, lon: lon2deg }: GeographicCoordinates,
    count: number,
): GeographicCoordinates[] {
    if (!Number.isInteger(count) || count < 1) {
        throw new Error(`Floating point or too low count: ${count}`)
    }

    // The algorithm is taken from https://www.movable-type.co.uk/scripts/latlong.html
    // Sections:
    //
    // * "Distance" – c is our ro
    // * "Intermediate point"

    // Calculating ro (the "Distance" section)
    const lat1 = degToRad(lat1deg)
    const lon1 = degToRad(lon1deg)
    const lat2 = degToRad(lat2deg)
    const lon2 = degToRad(lon2deg)
    const deltaLat = degToRad(lat2deg - lat1deg)
    const deltaLon = degToRad(lon2deg - lon1deg)

    const a =
        Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
        Math.cos(lat1) * Math.cos(lat2) * Math.sin(deltaLon / 2) * Math.sin(deltaLon / 2)
    const ro = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

    // Calculating intermediate points (the "Intermediate point" section)
    const SEGMENTS = count + 1
    return range(count).map((index) => {
        const f = (index + 1) / SEGMENTS
        const a = Math.sin((1 - f) * ro) / Math.sin(ro)
        const b = Math.sin(f * ro) / Math.sin(ro)
        const x = a * Math.cos(lat1) * Math.cos(lon1) + b * Math.cos(lat2) * Math.cos(lon2)
        const y = a * Math.cos(lat1) * Math.sin(lon1) + b * Math.cos(lat2) * Math.sin(lon2)
        const z = a * Math.sin(lat1) + b * Math.sin(lat2)
        const latRad = Math.atan2(z, Math.sqrt(x * x + y * y))
        const lonRad = Math.atan2(y, x)
        return {
            lat: radToDeg(latRad),
            lon: radToDeg(lonRad),
        }
    })
}

/**
 * Returns given route with intermediate points inserted into where appropriate.
 *
 * "Appropriate" means any two consecutive positions are relatively far from each other.
 *
 * The intermediate points are placed along the shortest paths between the points (following
 * the Earth's surface).
 *
 * The reason for this is so that we render shortest paths between distant points correctly
 * (if we give Mapbox two distant positions it'll just draw a straight line in screen
 * coordinates which will not reflect the actual path).
 *
 * See https://linear.app/lune/issue/LUN-3391/fix-the-straight-line-visualization-in-the-map-widget
 * for details.
 */
export function routeWithIntermediatePoints(
    route: GeographicCoordinates[],
): GeographicCoordinates[] {
    let result = [route[0]]
    let previous = route[0]
    // An arbitrary number, tune as needed. A higher number means more positions to render.
    const POSITIONS_PER_DEGREE = 0.5
    for (const p of route.slice(1)) {
        const deltaLat = Math.abs(p.lat - previous.lat)
        const deltaLon = Math.abs(p.lon - previous.lon)
        // Ideally we'd use both deltas here but we don't care to be *that* precise, just
        // pick the higher one as an approximation.
        const delta = Math.max(deltaLat, deltaLon)
        const intermediatePointCount = Math.trunc(delta * POSITIONS_PER_DEGREE)
        if (intermediatePointCount >= 1) {
            result = [...result, ...intermediateCoordinates(previous, p, intermediatePointCount)]
        }
        result.push(p)
        previous = p
    }
    return result
}

export const convertToTonne = (mass: Mass) => {
    return mass.unit === 't'
        ? Big(mass.amount)
        : mass.unit === 'kg'
          ? Big(mass.amount).div(1000)
          : Big(mass.amount).div(1000000)
}
