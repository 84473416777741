import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import type { SxProps } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { FC, useCallback, useState } from 'react';

const AccordionTile: FC<{
  children: React.ReactNode;
  title?: React.ReactNode;
  icon?: React.ReactNode;
  expanded?: boolean;
  sx?: SxProps;
}> = ({ expanded, title, children, sx, icon }) => {
  const [isOpen, setOpen] = useState<boolean>(expanded ?? false);
  const { palette } = useTheme();
  const toggle = useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen]);

  return (
    <Accordion
      onChange={toggle}
      expanded={isOpen}
      sx={{
        border: 'none',
        boxShadow: 'none',
        margin: '0 !important',
        borderRadius: '0 !important',
        '&:before': {
          display: 'none',
        },
        ...sx,
      }}
    >
      <AccordionSummary
        sx={{
          minHeight: 'auto !important',
          padding: 0,
          '.MuiAccordionSummary-expandIconWrapper': {
            margin: '0 16px',
          },
          '.MuiSvgIcon-root': {
            color: palette.Grey900,
          },
          '.MuiAccordionSummary-content': {
            margin: '12px 0 !important',
            display: 'flex',
            alignItems: 'center',
          },
        }}
        expandIcon={<ExpandMoreIcon />}
      >
        {icon && (
          <Box
            sx={{
              mr: 2,
              ml: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            {icon}
          </Box>
        )}
        <Typography sx={{ ml: 1 }} variant={'body2'}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          ml: icon ? 7 : 1,
          mr: 6,
          padding: '12px 0',
          mb: 3,
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionTile;
