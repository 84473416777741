import type { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import React from 'react';

import { LuneTheme } from '../theme';

export type UnSDGNumber = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17;

const UnSDG = ({ n, sx, ...rest }: { n: UnSDGNumber; sx?: SxProps }) => {
  const StyledBox = styled(Box)(
    LuneTheme.unstable_sx({
      aspectRatio: '1 / 1',
    }),
  );
  return (
    <StyledBox sx={sx} {...rest}>
      <img
        style={{
          width: '100%',
          height: '100%',
        }}
        src={`https://assets.lune.co/un-sdg/unsdg${n}.png`}
      />
    </StyledBox>
  );
};

export default UnSDG;
