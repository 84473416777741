import { MassUnit } from '@lune-climate/lune'
import { countriesList } from '@lune-fe/lune-ui-lib'
import { Big } from 'big.js'

/**
 * Takes a stringified number and converts it to a standardized format in terms of grams (G), kilograms (KG), or tons (T).
 *
 * @param {string} amount - The stringified number to be formatted.
 *
 * If the number is less than 1 (but not 0 or empty), it will be converted to kilograms if the number is greater than or equal to 0.001, or grams if it's less than 0.001.
 * If the number is greater than or equal to 1, it will be converted to tons.
 * If the input is 0, empty, or null, it will return 0 grams.
 *
 * @returns {Object} An object containing the formatted number and the mass unit. The object has the following properties:
 * - amountAsNumber: The formatted number
 * - amountUnit: The unit of mass, which can be 'G', 'KG', or 'T'
 */
export const getFormattedAmount = (
    amount: string,
): {
    amountAsNumber: number
    amountUnit: MassUnit
} => {
    const amountAsNumber = Big(amount || 0)
    if (amountAsNumber.lt(1)) {
        const amountInKilos = amountAsNumber.mul(1000).toNumber()
        if (amountInKilos < 1) {
            return {
                amountAsNumber: Big(amountInKilos).mul(1000).toNumber(),
                amountUnit: MassUnit.G,
            }
        } else {
            return {
                amountAsNumber: amountAsNumber.mul(1000).toNumber(),
                amountUnit: MassUnit.KG,
            }
        }
    } else {
        return {
            amountAsNumber: amountAsNumber.toNumber(),
            amountUnit: MassUnit.T,
        }
    }
}

/**
 * Accepts a three-letter country code and returns the full country name from a predefined list of countries.
 * Optionally, you can pass a callback function to be called if the country code is not found.
 */
export const getCountryNameByThreeLetterCode = (
    code: string,
    onFail?: () => void,
): string | undefined => {
    const country = countriesList.find((c) => c[2] === code)?.[0]

    if (!country && onFail) {
        onFail()
    }

    return country
}
