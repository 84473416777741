import type { SxProps } from '@mui/material';
import MuiChip from '@mui/material/Chip';
import { styled } from '@mui/system';
import React, { FC } from 'react';

import { LuneTheme } from '../theme';

const Chip: FC<{
  label: string;
  icon?: React.ReactElement;
  sx?: SxProps;
  href?: string;
}> = ({ label, icon, sx, href }) => {
  const { typography, palette } = LuneTheme;

  const StyledChip = styled(MuiChip)(
    LuneTheme.unstable_sx({
      ...typography.button,
      backgroundColor: palette.Grey100,
      borderRadius: '40px',
      height: '48px',
      paddingLeft: `${icon && '7px'}`,
      ...(sx && 'background' in sx && sx.background ? { '&:hover': { background: sx.background } } : {}),
      '.MuiChip-icon': {
        color: palette.Grey900,
      },
    }),
  );

  return (
    <StyledChip
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      // @ts-ignore
      href={href}
      target="_blank"
      component={href ? 'a' : 'div'}
      clickable={!!href}
      sx={sx}
      label={label}
      {...(icon ? { icon } : {})}
    />
  );
};

export default Chip;
