import { BundleSummary } from '@lune-climate/lune'
import {
    BackButton,
    Button,
    LabeledFormSection,
    LoadingWrapper,
    LuneTheme,
    MainLayoutContainer,
    MediaBox,
    Text,
    TwoColumnLayout,
    useIsMobile,
} from '@lune-fe/lune-ui-lib'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined'
import { Box, Link, Stack } from '@mui/material'
import Divider from '@mui/material/Divider'
import { RefObject, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import Map from 'components/Map/Map'
import MapMarker from 'components/Map/MapMarker'
import useProject from 'hooks/useProject'
import { formatToCurrency } from 'utils'
import BuyBundleButton from 'views/Projects/Project/BuyBundleButton'
import CaliforniaBillDetails from 'views/Projects/Project/CaliforniaBillDetails'
import CarbonCredits from 'views/Projects/Project/CarbonCredits/CarbonCredits'
import CarbonStorageChart from 'views/Projects/Project/CarbonStorageOverTime/Chart'
import MapWithDatasets from 'views/Projects/Project/MapWithDatasets/MapWithDatasets'
import { DatasetsProps } from 'views/Projects/Project/MapWithDatasets/utils'
import Links from 'views/Projects/Project/NewsAndUpdates/Links'
import NewsAndUpdates from 'views/Projects/Project/NewsAndUpdates/NewsAndUpdates'
import ProjectImpactSummary from 'views/Projects/Project/ProjectImpactSummary'
import ProjectQualityAssessment from 'views/Projects/Project/ProjectQualityAssessment'
import ProjectSpecs from 'views/Projects/Project/ProjectSpecs'
import ProjectTimeline from 'views/Projects/Project/ProjectTimeline/ProjectTimeline'
import UnSDGs from 'views/Projects/Project/UNSDGs'

// eslint-disable-next-line complexity
const Project = ({ scrollRef }: { scrollRef: RefObject<HTMLDivElement> }) => {
    const [allQueryParams] = useState<string>(window.location.search)
    const location = useLocation()
    const mapState = location.state?.mapState
    const navigateBack = location.state?.fromProposalView

    const { palette } = LuneTheme
    const { projectSlug: slug } = useParams<{ projectSlug: string }>()
    const { project, projectLoading } = useProject(slug)

    const navigate = useNavigate()
    const [projectBundleSummary, setProjectBundleSummary] = useState<BundleSummary>()
    const { isMobile } = useIsMobile()
    const [showAdvancedSections, setShowAdvancedSections] = useState<boolean>(false)

    const hasCaliforniaBillDetails =
        project &&
        (project.creditingStartDate ||
            project.creditingEndDate ||
            project.registrationDate ||
            project.protocol ||
            project.methodology ||
            project.durability ||
            project.additionalStandardsMet ||
            project.riskOfPredictedEmissions ||
            project.riskOfReversal ||
            project.avgAnnualEmissionReductions ||
            project.verifyingBody)

    const hasAdvancedSections = useMemo(() => {
        if (!project) {
            return false
        }
        const { carbonCredits, timeline, carbonStorageOverTime } = project
        return carbonCredits || timeline || carbonStorageOverTime
    }, [project])

    useEffect(() => {
        scrollRef.current!.scrollTo({ top: 0 })
    }, [scrollRef])

    useEffect(() => {
        if (project) {
            // Assume projects belong to one bundle
            setProjectBundleSummary(project.bundles[0])
        }
    }, [project])

    // Resizing images based on number of items and mediabox layout
    const getMediaPreviewHeight = (
        mediaIndex: number,
        totalItems: number,
    ): { previewWidth: number; previewHeight: number } => {
        if (mediaIndex !== 0) {
            return {
                previewWidth: 400,
                previewHeight: 400,
            }
        }
        // first image can always be bigger
        return {
            previewWidth: 900,
            previewHeight: totalItems > 3 ? 900 : totalItems > 1 ? 600 : 450,
        }
    }

    return (
        <LoadingWrapper loading={projectLoading} sx={{ height: 1 }}>
            <MainLayoutContainer>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <BackButton
                        sx={{ mb: 4, ml: -2 }}
                        onClick={() => {
                            if (navigateBack) {
                                navigate(-1)
                            } else {
                                navigate(`/projects${allQueryParams}`, {
                                    state: {
                                        mapState,
                                    },
                                })
                            }
                        }}
                    />
                    {projectBundleSummary && project && (
                        <BuyBundleButton
                            projectSlug={project.slug}
                            bundleId={projectBundleSummary.id}
                            bundleName={projectBundleSummary.name}
                        />
                    )}
                </Box>
                {project && (
                    <Stack spacing={9}>
                        <Box>
                            <Text variant="h6" sx={{ mb: 1 }}>
                                {project.projectType}
                            </Text>
                            <Text variant="h4" sx={{ mb: 1, paddingBottom: 4 }}>
                                {project.name}
                            </Text>
                            {project.bundles[0] && (
                                <ProjectSpecs
                                    registryName={project.registryName}
                                    registryLink={project.registryLink!}
                                    grossUnitPrice={project.bundles[0].grossUnitPrice}
                                    offsetType={project.bundles[0].offsetType}
                                    carbonPermanence={project.bundles[0].carbonPermanence}
                                />
                            )}
                        </Box>

                        {project.media.length !== 0 && (
                            <Box sx={{ width: '100%' }}>
                                <MediaBox
                                    media={project.media.map((mediaItem, index) => {
                                        return {
                                            ...mediaItem,
                                            ...getMediaPreviewHeight(index, project.media.length),
                                        } as any
                                    })}
                                />
                            </Box>
                        )}

                        <Box>
                            <TwoColumnLayout
                                columnWrapOrderOnMobile={`normal` as any}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'stretch',
                                    '.MuiGrid-root:last-of-type': {
                                        pt: 0,
                                        ...(isMobile && { minHeight: '300px' }),
                                    },
                                }}
                            >
                                <Box>
                                    <Text variant={'h5'} mb={4}>
                                        About
                                    </Text>
                                    <Text variant={'body3'} component="pre">
                                        {project.description}
                                    </Text>
                                </Box>
                                <Box
                                    sx={{
                                        borderRadius: '40px',
                                        width: '100%',
                                        height: '100%',
                                        overflow: 'hidden',
                                    }}
                                >
                                    <Map
                                        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN!}
                                        initialPosition={{
                                            latitude: project.latitude,
                                            longitude: project.longitude,
                                        }}
                                    >
                                        {
                                            (
                                                <MapMarker
                                                    latitude={project.latitude}
                                                    longitude={project.longitude}
                                                    src={project.logo!}
                                                >
                                                    {project.logo ? undefined : (
                                                        <PinDropOutlinedIcon
                                                            sx={{
                                                                width: '32px',
                                                                height: '32px',
                                                                margin: 'auto',
                                                                borderRadius: '80px',
                                                                backgroundColor: palette.Grey900,
                                                                color: palette.White,
                                                            }}
                                                        />
                                                    )}
                                                </MapMarker>
                                            ) as any
                                        }
                                    </Map>
                                </Box>
                            </TwoColumnLayout>
                        </Box>

                        <Divider />
                        {(project.impactSummary || project.datasets) && (
                            <>
                                <LabeledFormSection
                                    title={`Project impact summary`}
                                    subTile={`See where your climate contributions go. Explore how this project removes or reduces carbon while creating cascading co-benefits for local communities and ecosystems.`}
                                    titlePros={{
                                        variant: `h5`,
                                    }}
                                >
                                    {project.impactSummary && (
                                        <ProjectImpactSummary
                                            impactSummary={project.impactSummary}
                                            scrollRef={scrollRef}
                                        />
                                    )}
                                </LabeledFormSection>
                                {project.datasets && (
                                    <LabeledFormSection
                                        title={`Project impact maps`}
                                        titlePros={{
                                            variant: `h5`,
                                        }}
                                    >
                                        <MapWithDatasets
                                            project={project}
                                            datasets={project.datasets as DatasetsProps[]}
                                            scrollRef={scrollRef}
                                        />
                                    </LabeledFormSection>
                                )}
                            </>
                        )}
                        <LabeledFormSection
                            title={`UN Sustainable Development Goals`}
                            subTile={
                                <>
                                    These are the UN SDGs the project supports.
                                    <Link
                                        sx={{
                                            textDecoration: 'underline',
                                            cursor: 'pointer',
                                            color: 'inherit',
                                        }}
                                        href={'https://sdgs.un.org/goals'}
                                        target={'_blank'}
                                    >
                                        <Text
                                            variant={'body3'}
                                            sx={{
                                                display: 'inline',
                                                ml: 1 / 2,
                                                color: palette.Grey600,
                                            }}
                                        >
                                            Learn more.
                                        </Text>
                                    </Link>
                                </>
                            }
                            titlePros={{
                                variant: `h5`,
                            }}
                        >
                            <UnSDGs project={project} />
                        </LabeledFormSection>
                        {(hasAdvancedSections || hasCaliforniaBillDetails) && <Divider />}
                        {project.qualityAssessment && (
                            <>
                                <LabeledFormSection
                                    title={`Project quality assessment`}
                                    subTile={
                                        <div>
                                            Lune’s in-house analysis combined with leading external
                                            carbon project evaluation providers, such as BeZero and
                                            CarbonPlan, ensure trust and transparency in the
                                            project’s impact. For more information, access our{' '}
                                            <Link
                                                sx={{
                                                    textDecoration: 'underline',
                                                    cursor: 'pointer',
                                                    color: 'inherit',
                                                    fontStyle: 'initial',
                                                }}
                                                href={`https://lune.co/blog/quality-over-quantity-how-we-evaluate-carbon-projects-at-lune/`}
                                                target={'_blank'}
                                            >
                                                Project Evaluation Guide
                                            </Link>
                                            .
                                        </div>
                                    }
                                    titlePros={{
                                        variant: `h5`,
                                    }}
                                >
                                    <ProjectQualityAssessment
                                        dynamicSectionDescriptions={project.qualityAssessment}
                                    />
                                </LabeledFormSection>
                                <Divider />
                            </>
                        )}

                        {project.linksAndNews && (
                            <>
                                <LabeledFormSection
                                    title={`News and updates`}
                                    titlePros={{
                                        variant: `h5`,
                                    }}
                                >
                                    <Links
                                        links={project.linksAndNews.links}
                                        scrollRef={scrollRef}
                                    />
                                    <NewsAndUpdates
                                        news={project.linksAndNews.news}
                                        scrollRef={scrollRef}
                                    />
                                </LabeledFormSection>
                                <Divider />
                            </>
                        )}

                        {hasAdvancedSections && showAdvancedSections && (
                            <>
                                {project.carbonCredits && (
                                    <LabeledFormSection
                                        title={`Carbon credits`}
                                        titlePros={{
                                            variant: `h5`,
                                        }}
                                    >
                                        <CarbonCredits
                                            offsetDeliveryType={
                                                project.carbonCredits.offsetDelivery
                                            }
                                            offsetRetirementType={
                                                project.carbonCredits.retirementTimeframe
                                            }
                                            availableCredits={project.carbonCredits.inventory?.map(
                                                ({ vintage, quantity, price }) => ({
                                                    vintage,
                                                    quantity,
                                                    price: formatToCurrency(
                                                        price.value,
                                                        price.currency,
                                                    ),
                                                }),
                                            )}
                                        />
                                    </LabeledFormSection>
                                )}

                                {project.timeline && (
                                    <LabeledFormSection
                                        title={`Project timeline`}
                                        titlePros={{
                                            variant: `h5`,
                                        }}
                                    >
                                        <ProjectTimeline
                                            timeline={project.timeline}
                                            scrollRef={scrollRef}
                                        />
                                    </LabeledFormSection>
                                )}

                                {project.carbonStorageOverTime && (
                                    <LabeledFormSection
                                        title={`Carbon storage over time`}
                                        titlePros={{
                                            variant: `h5`,
                                        }}
                                    >
                                        <CarbonStorageChart
                                            carbonStorageOverTime={project.carbonStorageOverTime}
                                        />
                                    </LabeledFormSection>
                                )}
                            </>
                        )}

                        {hasCaliforniaBillDetails && showAdvancedSections && (
                            <CaliforniaBillDetails project={project} />
                        )}

                        {(hasAdvancedSections || hasCaliforniaBillDetails) &&
                            !showAdvancedSections && (
                                <Button
                                    wide
                                    sx={{ width: '100%' }}
                                    variant={'outlined'}
                                    onClick={() => setShowAdvancedSections(true)}
                                    leftIcon={<ArrowDownwardIcon />}
                                >
                                    Advanced project information
                                </Button>
                            )}
                    </Stack>
                )}
            </MainLayoutContainer>
        </LoadingWrapper>
    )
}

export default Project
