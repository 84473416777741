const countriesList = [
  ['Afghanistan', 'AF', 'AFG'],
  ['Åland Islands', 'AX', 'ALA'],
  ['Albania', 'AL', 'ALB'],
  ['Algeria', 'DZ', 'DZA'],
  ['American Samoa', 'AS', 'ASM'],
  ['Andorra', 'AD', 'AND'],
  ['Angola', 'AO', 'AGO'],
  ['Anguilla', 'AI', 'AIA'],
  ['Antarctica', 'AQ', 'ATA'],
  ['Antigua and Barbuda', 'AG', 'ATG'],
  ['Argentina', 'AR', 'ARG'],
  ['Armenia', 'AM', 'ARM'],
  ['Aruba', 'AW', 'ABW'],
  ['Australia', 'AU', 'AUS'],
  ['Austria', 'AT', 'AUT'],
  ['Azerbaijan', 'AZ', 'AZE'],
  ['Bahamas', 'BS', 'BHS'],
  ['Bahrain', 'BH', 'bhr'],
  ['Bangladesh', 'BD', 'BGD'],
  ['Barbados', 'BB', 'BRB'],
  ['Belarus', 'BY', 'BLR'],
  ['Belgium', 'BE', 'BEL'],
  ['Belize', 'BZ', 'BLZ'],
  ['Benin', 'BJ', 'BEN'],
  ['Bermuda', 'BM', 'BMU'],
  ['Bhutan', 'BT', 'BTN'],
  ['Bolivia', 'BO', 'BOL'],
  ['Bonaire, Sint Eustatius and Saba', 'BQ', 'BES'],
  ['Bosnia and Herzegovina', 'BA', 'BIH'],
  ['Botswana', 'BW', 'BWA'],
  ['Bouvet Island', 'BV', 'BVT'],
  ['Brazil', 'BR', 'BRA'],
  ['British Indian Ocean Territory', 'IO', 'IOT'],
  ['Brunei Darussalam', 'BN', 'BRN'],
  ['Bulgaria', 'BG', 'BGR'],
  ['Burkina Faso', 'BF', 'BFA'],
  ['Burundi', 'BI', 'BDI'],
  ['Cabo Verde', 'CV', 'CPV'],
  ['Cambodia', 'KH', 'KHM'],
  ['Cameroon', 'CM', 'CMR'],
  ['Canada', 'CA', 'CAN'],
  ['Cayman Islands', 'KY', 'CYM'],
  ['Central African Republic', 'CF', 'CAF'],
  ['Chad', 'TD', 'TCD'],
  ['Chile', 'CL', 'CHL'],
  ['China', 'CN', 'CHN'],
  ['Christmas Island', 'CX', 'CXR'],
  ['Cocos [Keeling] Islands', 'CC', 'CCK'],
  ['Colombia', 'CO', 'COL'],
  ['Comoros', 'KM', 'COM'],
  ['Congo', 'CG', 'COG'],
  ['Congo [Democratic Republic of the]', 'CD', 'COD'],
  ['Cook Islands', 'CK', 'COK'],
  ['Costa Rica', 'CR', 'CRI'],
  ["Côte d'Ivoire", 'CI', 'CIV'],
  ['Croatia', 'HR', 'HRV'],
  ['Cuba', 'CU', 'CUB'],
  ['Curaçao', 'CW', 'CUW'],
  ['Cyprus', 'CY', 'CYP'],
  ['Czech Republic', 'CZ', 'CZE'],
  ['Denmark', 'DK', 'DNK'],
  ['Djibouti', 'DJ', 'DJI'],
  ['Dominica', 'DM', 'DMA'],
  ['Dominican Republic', 'DO', 'DOM'],
  ['Ecuador', 'EC', 'ECU'],
  ['Egypt', 'EG', 'EGY'],
  ['El Salvador', 'SV', 'SLV'],
  ['Equatorial Guinea', 'GQ', 'GNQ'],
  ['Eritrea', 'ER', 'ERI'],
  ['Estonia', 'EE', 'EST'],
  ['Eswatini', 'SZ', 'SWZ'],
  ['Ethiopia', 'ET', 'ETH'],
  ['Falkland Islands', 'FK', 'FLK'],
  ['Faroe Islands', 'FO', 'FRO'],
  ['Fiji', 'FJ', 'FJI'],
  ['Finland', 'FI', 'FIN'],
  ['France', 'FR', 'FRA'],
  ['French Guiana', 'GF', 'GUF'],
  ['French Polynesia', 'PF', 'PYF'],
  ['French Southern Territories', 'TF', 'ATF'],
  ['Gabon', 'GA', 'GAB'],
  ['Gambia', 'GM', 'GMB'],
  ['Georgia', 'GE', 'GEO'],
  ['Germany', 'DE', 'DEU'],
  ['Ghana', 'GH', 'GHA'],
  ['Gibraltar', 'GI', 'GIB'],
  ['Greece', 'GR', 'GRC'],
  ['Greenland', 'GL', 'GRL'],
  ['Grenada', 'GD', 'GRD'],
  ['Guadeloupe', 'GP', 'GLP'],
  ['Guam', 'GU', 'GUM'],
  ['Guatemala', 'GT', 'GTM'],
  ['Guernsey', 'GG', 'GGY'],
  ['Guinea', 'GN', 'GIN'],
  ['Guinea-Bissau', 'GW', 'GNB'],
  ['Guyana', 'GY', 'GUY'],
  ['Haiti', 'HT', 'HTI'],
  ['Heard Island and McDonald Islands', 'HM', 'HMD'],
  ['Holy See', 'VA', 'VAT'],
  ['Honduras', 'HN', 'HND'],
  ['Hong Kong', 'HK', 'HKG'],
  ['Hungary', 'HU', 'HUN'],
  ['Iceland', 'IS', 'ISL'],
  ['India', 'IN', 'IND'],
  ['Indonesia', 'ID', 'IDN'],
  ['Iran', 'IR', 'IRN'],
  ['Iraq', 'IQ', 'IRQ'],
  ['Ireland', 'IE', 'IRL'],
  ['Isle of Man', 'IM', 'IMN'],
  ['Israel', 'IL', 'ISR'],
  ['Italy', 'IT', 'ITA'],
  ['Jamaica', 'JM', 'JAM'],
  ['Japan', 'JP', 'JPN'],
  ['Jersey', 'JE', 'JEY'],
  ['Jordan', 'JO', 'JOR'],
  ['Kazakhstan', 'KZ', 'KAZ'],
  ['Kenya', 'KE', 'KEN'],
  ['Kiribati', 'KI', 'KIR'],
  ['North Korea', 'KP', 'PRK'],
  ['South Korea', 'KR', 'KOR'],
  ['Kosovo', 'XK', 'XKX'],
  ['Kuwait', 'KW', 'KWT'],
  ['Kyrgyzstan', 'KG', 'KGZ'],
  ["Lao People's Democratic Republic", 'LA', 'LAO'],
  ['Latvia', 'LV', 'LVA'],
  ['Lebanon', 'LB', 'LBN'],
  ['Lesotho', 'LS', 'LSO'],
  ['Liberia', 'LR', 'LBR'],
  ['Libya', 'LY', 'LBY'],
  ['Liechtenstein', 'LI', 'LIE'],
  ['Lithuania', 'LT', 'LTU'],
  ['Luxembourg', 'LU', 'LUX'],
  ['Macao', 'MO', 'MAC'],
  ['Macedonia', 'MK', 'MKD'],
  ['Madagascar', 'MG', 'MDG'],
  ['Malawi', 'MW', 'MWI'],
  ['Malaysia', 'MY', 'MYS'],
  ['Maldives', 'MV', 'MDV'],
  ['Mali', 'ML', 'MLI'],
  ['Malta', 'MT', 'MLT'],
  ['Marshall Islands', 'MH', 'MHL'],
  ['Martinique', 'MQ', 'MTQ'],
  ['Mauritania', 'MR', 'MRT'],
  ['Mauritius', 'MU', 'MUS'],
  ['Mayotte', 'YT', 'MYT'],
  ['Mexico', 'MX', 'MEX'],
  ['Micronesia [Federated States of]', 'FM', 'FSM'],
  ['Moldova [Republic of]', 'MD', 'MDA'],
  ['Monaco', 'MC', 'MCO'],
  ['Mongolia', 'MN', 'MNG'],
  ['Montenegro', 'ME', 'MNE'],
  ['Montserrat', 'MS', 'MSR'],
  ['Morocco', 'MA', 'MAR'],
  ['Mozambique', 'MZ', 'MOZ'],
  ['Myanmar', 'MM', 'MMR'],
  ['Namibia', 'NA', 'NAM'],
  ['Nauru', 'NR', 'NRU'],
  ['Nepal', 'NP', 'NPL'],
  ['Netherlands', 'NL', 'NLD'],
  ['New Caledonia', 'NC', 'NCL'],
  ['New Zealand', 'NZ', 'NZL'],
  ['Nicaragua', 'NI', 'NIC'],
  ['Niger', 'NE', 'NER'],
  ['Nigeria', 'NG', 'NGA'],
  ['Niue', 'NU', 'NIU'],
  ['Norfolk Island', 'NF', 'NFK'],
  ['Northern Mariana Islands', 'MP', 'MNP'],
  ['Norway', 'NO', 'NOR'],
  ['Oman', 'OM', 'OMN'],
  ['Pakistan', 'PK', 'PAK'],
  ['Palau', 'PW', 'PLW'],
  ['Palestine, State of', 'PS', 'PSE'],
  ['Panama', 'PA', 'PAN'],
  ['Papua New Guinea', 'PG', 'PNG'],
  ['Paraguay', 'PY', 'PRY'],
  ['Peru', 'PE', 'PER'],
  ['Philippines', 'PH', 'PHL'],
  ['Pitcairn', 'PN', 'PCN'],
  ['Poland', 'PL', 'POL'],
  ['Portugal', 'PT', 'PRT'],
  ['Puerto Rico', 'PR', 'PRI'],
  ['Qatar', 'QA', 'QAT'],
  ['Réunion', 'RE', 'REU'],
  ['Romania', 'RO', 'ROU'],
  ['Russia', 'RU', 'RUS'],
  ['Rwanda', 'RW', 'RWA'],
  ['Saint Barthélemy', 'BL', 'BLM'],
  ['Saint Helena, Ascension and Tristan da Cunha', 'SH', 'SHN'],
  ['Saint Kitts and Nevis', 'KN', 'KNA'],
  ['Saint Lucia', 'LC', 'LCA'],
  ['Saint Martin [French part]', 'MF', 'MAF'],
  ['Saint Pierre and Miquelon', 'PM', 'SPM'],
  ['Saint Vincent and the Grenadines', 'VC', 'VCT'],
  ['Samoa', 'WS', 'WSM'],
  ['San Marino', 'SM', 'SMR'],
  ['Sao Tome and Principe', 'ST', 'STP'],
  ['Saudi Arabia', 'SA', 'SAU'],
  ['Senegal', 'SN', 'SEN'],
  ['Serbia', 'RS', 'SRB'],
  ['Seychelles', 'SC', 'SYC'],
  ['Sierra Leone', 'SL', 'SLE'],
  ['Singapore', 'SG', 'SGP'],
  ['Sint Maarten [Dutch part]', 'SX', 'SXM'],
  ['Slovakia', 'SK', 'SVK'],
  ['Slovenia', 'SI', 'SVN'],
  ['Solomon Islands', 'SB', 'SLB'],
  ['Somalia', 'SO', 'SOM'],
  ['South Africa', 'ZA', 'ZAF'],
  ['South Georgia and the South Sandwich Islands', 'GS', 'SGS'],
  ['South Sudan', 'SS', 'SSD'],
  ['Spain', 'ES', 'ESP'],
  ['Sri Lanka', 'LK', 'LKA'],
  ['Sudan', 'SD', 'SDN'],
  ['Suriname', 'SR', 'SUR'],
  ['Svalbard and Jan Mayen', 'SJ', 'SJM'],
  ['Sweden', 'SE', 'SWE'],
  ['Switzerland', 'CH', 'CHE'],
  ['Syria', 'SY', 'SYR'],
  ['Taiwan', 'TW', 'TWN'],
  ['Tajikistan', 'TJ', 'TJK'],
  ['Tanzania, United Republic of', 'TZ', 'TZA'],
  ['Thailand', 'TH', 'THA'],
  ['Timor-Leste', 'TL', 'TLS'],
  ['Togo', 'TG', 'TGO'],
  ['Tokelau', 'TK', 'TKL'],
  ['Tonga', 'TO', 'TON'],
  ['Trinidad and Tobago', 'TT', 'TTO'],
  ['Tunisia', 'TN', 'TUN'],
  ['Turkey', 'TR', 'TUR'],
  ['Turkmenistan', 'TM', 'TKM'],
  ['Turks and Caicos Islands', 'TC', 'TCA'],
  ['Tuvalu', 'TV', 'TUV'],
  ['Uganda', 'UG', 'UGA'],
  ['Ukraine', 'UA', 'UKR'],
  ['United Arab Emirates', 'AE', 'ARE'],
  ['United Kingdom', 'GB', 'GBR'],
  ['United States of America', 'US', 'USA'],
  ['United States Minor Outlying Islands', 'UM', 'UMI'],
  ['Uruguay', 'UY', 'URY'],
  ['Uzbekistan', 'UZ', 'UZB'],
  ['Vanuatu', 'VU', 'VUT'],
  ['Venezuela [Bolivarian Republic of]', 'VE', 'VEN'],
  ['Vietnam', 'VN', 'VNM'],
  ['Virgin Islands [British]', 'VG', 'VGB'],
  ['Virgin Islands [U.S.]', 'VI', 'VIR'],
  ['Wallis and Futuna', 'WF', 'WLF'],
  ['Western Sahara', 'EH', 'ESH'],
  ['Yemen', 'YE', 'YEM'],
  ['Zambia', 'ZM', 'ZMB'],
  ['Zimbabwe', 'ZW', 'ZWE'],
];

export default countriesList;
