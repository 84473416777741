const ports = [
  ['ALVOA', 'Vlores'],
  ['ALSHG', 'Shengjin'],
  ['ALDRZ', 'Durres'],
  ['DZBJA', 'Bejaia'],
  ['DZORN', 'Oran'],
  ['DZGHZ', 'Ghazaouet'],
  ['DZCOL', 'Collo'],
  ['DZDEL', 'Dellys'],
  ['DZSKI', 'Skikda'],
  ['DZTEN', 'Tenes'],
  ['DZAAE', 'Annaba'],
  ['DZALG', 'Alger'],
  ['DZBSF', 'Beni Saf'],
  ['DZAZW', 'Arzew'],
  ['DZMOS', 'Mostaganem'],
  ['ASPPG', 'Pago Pago Harbor'],
  ['AODAL', 'Dalia Terminal'],
  ['AOGPO', 'Greater Plutonio Terminal'],
  ['AOMSZ', 'Namibe'],
  ['AONDD', 'Sumbe'],
  ['AOMAL', 'Malongo Oil Terminal'],
  ['AOLAD', 'Luanda'],
  ['AOPBN', 'Porto Amboim'],
  ['AOTAK', 'Takula Terminal'],
  ['AOCAB', 'Cabinda'],
  ['AOFLT', 'Futila Terminal'],
  ['AOLOB', 'Lobito'],
  ['AOKOT', 'Kuito Oil Field'],
  ['AOPAT', 'Palanca Terminal'],
  ['AQMCM', 'Mcmurdo Station'],
  ['AQPFS', 'Port Foster'],
  ['AGSJO', 'St Johns'],
  ['ARQQN', 'Quequen'],
  ['ARCRD', 'Comodoro Rivadavia'],
  ['ARSSN', 'San Sebastian Bay'],
  ['ARSJL', 'Puerto San Julian'],
  ['ARBHI', 'Bahia Blanca'],
  ['ARPRS', 'Puerto Rosales'],
  ['ARLPG', 'La Plata'],
  ['ARRZA', 'Puerto Santa Cruz'],
  ['ARBUE', 'Buenos Aires'],
  ['ARZAE', 'Zarate'],
  ['ARRGL', 'Puerto Gallegos'],
  ['ARPUD', 'Puerto Deseado'],
  ['ARSPD', 'San Pedro'],
  ['ARPMY', 'Puerto Madryn'],
  ['ARPBG', 'Puerto Belgrano'],
  ['ARIBY', 'Puerto Ibicuy'],
  ['ARINW', 'Puerto Ingeniero White'],
  ['ARMDQ', 'Mar Del Plata'],
  ['ARPNL', 'Puerto Nacional'],
  ['ARSNS', 'San Nicolas'],
  ['ARBSB', 'San Blas'],
  ['ARCMP', 'Campana'],
  ['ARUSH', 'Ushuaia'],
  ['AWORJ', 'Paarden Baai - (Oranjestad)'],
  ['AWSNL', 'Sint Nicolaas Baai'],
  ['AUMIB', 'Milner Bay'],
  ['AUCNS', 'Cairns'],
  ['AUJEB', 'Jervis Bay'],
  ['AUTHE', 'Thevenard'],
  ['AUPTL', 'Port Alma'],
  ['AUPTD', 'Port Dalrymple'],
  ['AUFRE', 'Fremantle'],
  ['AUGOV', 'Gove'],
  ['AUWEP', 'Western Port'],
  ['AUTIS', 'Thursday Island'],
  ['AUPGI', 'Port Giles'],
  ['AUPAE', 'Port Adelaide'],
  ['AUPTJ', 'Portland'],
  ['AUPLA', 'Port Latta'],
  ['AUDRW', 'Darwin'],
  ['AULUC', 'Lucinda'],
  ['AUBWT', 'Burnie'],
  ['AUEPR', 'Esperance'],
  ['AUSYD', 'Sydney'],
  ['AUCQP', 'Cape Flattery Harbor'],
  ['AUMKY', 'Mackay'],
  ['AULST', 'Launceston'],
  ['AUBYP', 'Beauty Point'],
  ['AUWHL', 'Welshpool'],
  ['AUGEX', 'Geelong'],
  ['AUQBW', 'Batemans Bay'],
  ['AUBNE', 'Brisbane'],
  ['AUUSL', 'Useless Loop'],
  ['AUKRB', 'Karumba'],
  ['AUMEL', 'Melbourne'],
  ['AUQDN', 'Eden'],
  ['AUCCU', 'Cape Cuvier'],
  ['AUALH', 'Albany'],
  ['AUGLT', 'Gladstone'],
  ['AUWYA', 'Whyalla'],
  ['AUBDB', 'Bundaberg'],
  ['AUTSV', 'Townsville'],
  ['AUBME', 'Broome'],
  ['AUNTL', 'Newcastle'],
  ['AUHPT', 'Hay Point'],
  ['AUPPI', 'Port Pirie'],
  ['AUMOU', 'Mourilyan Harbour'],
  ['AUDAM', 'Dampier'],
  ['AUPHU', 'Port Huon'],
  ['AUWYN', 'Wyndham'],
  ['AUPWL', 'Port Walcott'],
  ['AUGET', 'Geraldton'],
  ['AUWAL', 'Wallaroo'],
  ['AUPBY', 'Port Bonython'],
  ['AUHBA', 'Hobart'],
  ['AUPKL', 'Port Kembla'],
  ['AUWEI', 'Weipa'],
  ['AUDPO', 'Devonport'],
  ['AUBUY', 'Bunbury'],
  ['AUPLO', 'Port Lincoln'],
  ['AUARD', 'Ardrossan'],
  ['AUONS', 'Onslow'],
  ['BHMIN', 'Mina Salman'],
  ['BHKBS', 'Khalifa Bin Salman'],
  ['BHSIT', 'Sitrah'],
  ['BHAMH', 'Al Manamah'],
  ['BDMGL', 'Mongla'],
  ['BDCGP', 'Chittagong'],
  ['BBBGI', 'Bridgetown'],
  ['BEANR', 'Antwerpen'],
  ['BENIE', 'Nieuwpoort'],
  ['BEBRU', 'Bruxelles'],
  ['BEOST', 'Oostende'],
  ['BEZEE', 'Zeebrugge'],
  ['BEGNE', 'Ghent'],
  ['BZBZE', 'Belize City'],
  ['BJCOO', 'Cotonou'],
  ['BMBDA', 'Hamilton'],
  ['BMFPT', 'Ireland Island'],
  ['BMSGE', 'St George'],
  ['BRPOA', 'Porto Alegre'],
  ['BRSTM', 'Santarem'],
  ['BRNAT', 'Natal'],
  ['BRIOS', 'Port Of Ilheus'],
  ['BRSTN', 'Porto Santana'],
  ['BRAJU', 'Port De Aracaju'],
  ['BRTRM', 'Tramandai'],
  ['BRBEL', 'Belem'],
  ['BRVIX', 'Vitoria'],
  ['BRREC', 'Recife'],
  ['BRTUT', 'Tutoia'],
  ['BRSUA', 'Porto De Suape'],
  ['BRMCZ', 'Porto De Maceio'],
  ['BRVDC', 'Vila Do Conde'],
  ['BRSSZ', 'Santos'],
  ['BRFNO', 'Porto Do Forno'],
  ['BRSSO', 'Sao Sebastiao'],
  ['BRADR', 'Angra Dos Reis'],
  ['BRCMA', 'Cameta'],
  ['BRRIG', 'Rio Grande'],
  ['BRGUA', 'Guamare Oil Terminal'],
  ['BRNTR', 'Niteroi'],
  ['BRPNG', 'Paranagua'],
  ['BRSSA', 'Port De Salvador'],
  ['BRIBB', 'Imbituba'],
  ['BRTUB', 'Tubarao'],
  ['BRMAO', 'Manaus'],
  ['BRSFS', 'Sao Francisco'],
  ['BRIQI', 'Itaqui'],
  ['BRRIO', 'Rio De Janeiro'],
  ['BRCMC', 'Camocim'],
  ['BRITJ', 'Itajai'],
  ['IODGA', 'Diego Garcia'],
  ['VGRAD', 'Road Harbor'],
  ['BNLUM', 'Lumut'],
  ['BNBWN', 'Bandar Seri Begawan'],
  ['BNMUA', 'Muara Harbor'],
  ['BGVAR', 'Varna'],
  ['BGBOJ', 'Burgas'],
  ['MMMER', 'Mergui'],
  ['MMRGN', 'Rangoon'],
  ['MMBSX', 'Bassein'],
  ['CVRAI', 'Porto Da Praia'],
  ['CVGRA', 'Porto Grande'],
  ['KHKOS', 'Kampong Saom'],
  ['CMMOU', 'Moudi Marine Terminal'],
  ['CMDLA', 'Douala'],
  ['CMKOL', 'Kole Oil Terminal'],
  ['CMKBI', 'Kome Kribi 1 Marine Terminal'],
  ['CMEBT', 'Ebome Marine Terminal'],
  ['CAKIN', 'Kingston'],
  ['CAPNT', 'Pangnirtung'],
  ['CAPTH', 'Penetanguishene'],
  ['CAWND', 'Windsor'],
  ['CAGEO', 'Georgetown'],
  ['CAQNS', 'Queenston'],
  ['CAGNN', 'Rockport'],
  ['CANWE', 'New Westminster'],
  ['CATHS', 'Thessalon'],
  ['CACOX', 'Comox Harbor'],
  ['CAGPE', 'Gaspe'],
  ['CACHI', 'Chicoutimi (Port Saguenay)'],
  ['CAHAM', 'Hamilton'],
  ['CAPTO', 'Pictou'],
  ['CABAM', 'Bamberton'],
  ['CABEC', 'Port De Becancour'],
  ['CAYBX', 'Blanc Sablon'],
  ['CADIG', 'Digby'],
  ['CAESQ', 'Esquimalt Harbor'],
  ['CAHSP', 'Havre St Pierre'],
  ['CAPRS', 'Parry Sound'],
  ['CACBK', 'Corner Brook'],
  ['CASUM', 'Summerside'],
  ['CAUCL', 'Ucluelet'],
  ['CACAT', 'Catalina Harbor'],
  ['CANNE', 'Nanoose Harbor'],
  ['CAOWS', 'Owen Sound'],
  ['CAGGS', 'Ganges'],
  ['CASSM', 'Sault Ste Marie'],
  ['CACLE', 'Clarenville'],
  ['CAHMC', 'Harmac'],
  ['CACCB', 'Cowichan Bay'],
  ['CAPSI', 'Port Simpson'],
  ['CASQA', 'Squamish'],
  ['CABHA', 'Beauharnois'],
  ['CA345', 'Goulais Bay'],
  ['CAPHA', 'Port Hastings'],
  ['CAPCR', 'Port Credit'],
  ['CATOR', 'Toronto'],
  ['CACSN', 'Clarkson'],
  ['CASNI', 'Sarnia'],
  ['CAFRR', 'Fraser Mills'],
  ['CADCN', 'Duncan Bay'],
  ['CANOL', 'Niagara'],
  ['CAVAN', 'Vancouver'],
  ['CAKHO', 'Key Harbour'],
  ['CAVIC', 'Victoria Harbor'],
  ['CASYD', 'Sydney'],
  ['CAENE', 'Rondeau'],
  ['CAMSS', 'Masset Harbor'],
  ['CAHBY', 'Heron Bay'],
  ['CAPAC', 'Port Alice'],
  ['CABST', 'Port Bayside Nb'],
  ['CABCO', 'Baie Comeau'],
  ['CAQCH', 'Queen Charlotte'],
  ['CAHAL', 'Halifax'],
  ['CACRR', 'Courtright'],
  ['CAPMA', 'Port Mcneill'],
  ['CAMIH', 'Michipicoten'],
  ['CASC4', 'St Catherine'],
  ['CAPAB', 'Port Alberni'],
  ['CATHU', 'Thunder Bay'],
  ['CANWP', 'Argentia'],
  ['CAPRR', 'Prince Rupert'],
  ['CAPDI', 'Pond Inlet'],
  ['CAPBQ', 'Port Aux Basques'],
  ['CACAU', 'Coal Harbor'],
  ['CAPTC', 'Port Coquitlam'],
  ['CAYKT', 'Klemtu'],
  ['CASMT', 'Stormont'],
  ['CAYVV', 'Wiarton'],
  ['CAOTT', 'Ottawa'],
  ['CAMDB', 'Meldrum Bay'],
  ['CAMID', 'Midland'],
  ['CALIC', 'Little Current'],
  ['CACHA', 'Charlottetown'],
  ['CAMNE', 'Matane'],
  ['CAKTM', 'Kitimat'],
  ['CANSY', 'North Sydney'],
  ['CACAQ', 'Caraquet'],
  ['CACHM', 'Chemainus'],
  ['CAPCO', 'Port Colborne'],
  ['CAPWE', 'Port Weller'],
  ['CACRO', 'Crofton'],
  ['CAPST', 'Port Stanley'],
  ['CARIM', 'Rimouski'],
  ['CASOM', 'Sombra'],
  ['CACBC', 'Come By Chance Harbor'],
  ['CAPHY', 'Port Hardy'],
  ['CAOSH', 'Oshawa'],
  ['CAYAJ', 'Lyall Harbor'],
  ['CAMFD', 'Meaford'],
  ['CAPHW', 'Port Hawkesbury'],
  ['CAQUE', 'Quebec'],
  ['CAPEW', 'Point Edward'],
  ['CAMAS', 'Masson'],
  ['CAYZE', 'Gore Bay'],
  ['CALMN', 'Leamington'],
  ['CADHS', 'Dalhousie'],
  ['CAJOH', 'Lower Lakes Terminal'],
  ['CAGOH', 'Goderich'],
  ['CAAMH', 'Amherstburg'],
  ['CASOR', 'Sorel'],
  ['CAAAL', 'Alert Bay'],
  ['CABBN', 'Bernard Harbor'],
  ['CANNO', 'Nanaimo'],
  ['CASJB', 'St John'],
  ['CARDL', 'Riviere Du Loup'],
  ['CASTW', 'Stewart'],
  ['CASVN', 'Steveston'],
  ['CASDY', 'Sidney'],
  ['CAMTR', 'Montreal'],
  ['CALOU', 'Louisburg'],
  ['CATRR', 'Trois Rivieres'],
  ['CAMIB', 'Mission City'],
  ['CABLC', 'Bella Coola'],
  ['CACOP', 'Coppermine'],
  ['CAPTA', 'Tahsis'],
  ['CACBG', 'Cobourg'],
  ['CAPCS', 'Port Clements'],
  ['CABKN', 'Liverpool (Brooklyn)'],
  ['CAGBR', 'Goldsboro'],
  ['CALBA', 'La Baie (Port Alfred)'],
  ['CAGNE', 'Gananoque'],
  ['CAPNO', 'Pointe Noire'],
  ['CASEI', 'Sept Iles'],
  ['BQORA', 'Oranjestad'],
  ['KYCYB', 'Cayman Brac'],
  ['KYGEC', 'Georgetown'],
  ['CLMJS', 'Mejillones'],
  ['CLCNR', 'Puerto Chanaral'],
  ['CLARI', 'Rada De Arica'],
  ['CLWPU', 'Puerto Williams'],
  ['CLCAA', 'Puerto Calderilla'],
  ['CLVAP', 'Bahia De Valparaiso'],
  ['CLLTA', 'Lota'],
  ['CLTAL', 'Talcahuano'],
  ['CLCNL', 'Coronel'],
  ['CLLQN', 'Lirquen'],
  ['CLSVE', 'Bahia San Vicente'],
  ['CLPCH', 'Puerto Chacabuco'],
  ['CLPMC', 'Puerto Montt'],
  ['CLCAL', 'Caleta Patillos'],
  ['CLHSO', 'Huasco'],
  ['CLTTC', 'Taltal'],
  ['CLTOQ', 'Tocopilla'],
  ['CLCQQ', 'Coquimbo'],
  ['CLPEO', 'Penco'],
  ['CLCRR', 'Puerto De Corral'],
  ['CLQTV', 'Bahia Quintero (Ventanas)'],
  ['CLSAI', 'Puerto San Antonio'],
  ['CLCLD', 'Puerto Caldera'],
  ['CLGYC', 'Bahia Herradura Guayacan'],
  ['CLWCA', 'Puerto Castro'],
  ['CLANF', 'Antofagasta'],
  ['CLQMC', 'Puerto Quemchi'],
  ['CLPNT', 'Puerto Natales'],
  ['CLIQQ', 'Iquique'],
  ['CLZAL', 'Bahia De Valdivia'],
  ['CLPUQ', 'Rada Punta Arenas'],
  ['CNSGH', 'Shanghai'],
  ['CNZOS', 'Zhoushan'],
  ['CNSHK', 'Shekou'],
  ['CNNTG', 'Nantong'],
  ['CNZNG', 'Zhanjiang'],
  ['CNHUN', 'Huangpuxingang'],
  ['CNHIG', 'Haikou'],
  ['CNHUI', 'Huizhou'],
  ['CNYIK', 'Yingkou'],
  ['CNHNK', 'Hankow'],
  ['CNLYG', 'Lianyungang'],
  ['CNWAX', 'Wanxian'],
  ['CNXMG', 'Xiamen'],
  ['CNHUA', 'Huangpu'],
  ['CNNJI', 'Nanjing'],
  ['CNQIN', 'Qingdao Gang'],
  ['CNYTN', 'Yantian'],
  ['CNLKU', 'Longkou Gang'],
  ['CNQZL', 'Quanzhou'],
  ['CNRZH', 'Rizhao'],
  ['CNGAO', 'Gaogang'],
  ['CNSDG', 'Shui Dong'],
  ['CNXYG', 'Xiuyu'],
  ['CNZPU', 'Zhapu'],
  ['CNZZU', 'Zhangzhou'],
  ['CNDAL', 'Dalian'],
  ['CNBYQ', 'Bayuquan'],
  ['CNGGZ', 'Guangzhou'],
  ['CNHME', 'Haimen'],
  ['CNYZH', 'Yangzhou'],
  ['CNQZH', 'Qinzhou'],
  ['CNCOZ', 'Chaozhou'],
  ['CNBIH', 'Beihai'],
  ['CNLSN', 'Lanshan'],
  ['CNFZH', 'Fuzhou'],
  ['CNCGS', 'Changshu'],
  ['CNYTG', 'Yantai'],
  ['CNZHE', 'Zhenjiang'],
  ['CNZJG', 'Zhangjiangang'],
  ['CNYPG', 'Yangpu'],
  ['CNBSP', 'Basuo'],
  ['CNJNZ', 'Jinzhou Wan'],
  ['CNFAN', 'Fang-Cheng'],
  ['CNTXG', 'Tianjin Xin Gang'],
  ['CNTAC', 'Taicang'],
  ['CNNBO', 'Ningbo'],
  ['CNLSH', 'Lushun'],
  ['CNWEG', 'Weihai'],
  ['CNSHP', 'Qinhuangdao'],
  ['CNDSN', 'Dongshan'],
  ['CNWZO', 'Wenzhou'],
  ['COPOC', 'Pozos Colorados'],
  ['COTCO', 'Tumaco'],
  ['COCVE', 'Covenas'],
  ['COTRB', 'Turbo'],
  ['COPBO', 'Puerto Bolivar'],
  ['COMAM', 'Mamonal'],
  ['COCOV', 'Covenas Offshore Term.'],
  ['COBAQ', 'Barranquilla'],
  ['COCTG', 'Cartagena'],
  ['COPPR', 'Puerto Prodeco'],
  ['COSMR', 'Santa Marta'],
  ['COEBQ', 'El Bosque'],
  ['KMYVA', 'Moroni'],
  ['KMDZA', 'Dzaoudzi'],
  ['KMMUT', 'Moutsamoudu'],
  ['KMFOU', 'Fomboni'],
  ['CGDJE', 'Djeno Terminal'],
  ['CGNKO', "N'kossa Terminal"],
  ['CGPNR', 'Pointe Noire'],
  ['CGYOM', 'Yombo Terminal'],
  ['CDBNW', 'Banana'],
  ['CDMAT', 'Matadi'],
  ['CDBOA', 'Boma'],
  ['CKAVA', 'Avatiu'],
  ['CRLIO', 'Puerto Limon'],
  ['CRPTC', 'Puerto Caldera'],
  ['CRPAS', 'Puntarenas'],
  ['CRGLF', 'Golfito'],
  ['CRXQP', 'Puerto Quepos'],
  ['CRPMN', 'Puerto Moin'],
  ['CIABJ', 'Abidjan'],
  ['CISPY', 'San Pedro'],
  ['CIPBT', 'Port Bouet'],
  ['CIBAO', 'Baobab Marine Terminal'],
  ['CIESP', 'Espoir Marine Terminal'],
  ['HRSPU', 'Split'],
  ['HRBAK', 'Bakar'],
  ['HRKOR', 'Korcula'],
  ['HRPLE', 'Ploce'],
  ['HRROV', 'Rovinj'],
  ['HRDBV', 'Dubrovnik'],
  ['HRZAD', 'Zadar'],
  ['HRSEN', 'Senj'],
  ['HRUMG', 'Umag'],
  ['HROMI', 'Omisalj'],
  ['HRSIB', 'Sibenik'],
  ['HRPUY', 'Pula'],
  ['HRDUR', 'Dugi Rat'],
  ['HRRAS', 'Zaliv Rasa'],
  ['HRRJK', 'Rijeka Luka'],
  ['CUCEI', 'Ceiba Hueca'],
  ['CUGYB', 'Guayabal'],
  ['CUMEL', 'Media Luna'],
  ['CUCAR', 'Cardenas'],
  ['CUGER', 'Nueva Gerona'],
  ['CUMZO', 'Manzanillo'],
  ['CUGUB', 'Puerto Guantanamo'],
  ['CUTDZ', 'Tunas De Zaza'],
  ['CUHAV', 'Bahai De La Habana'],
  ['CUSDT', 'Bahia De Sagua De Tanamo'],
  ['CUJUC', 'Jucaro'],
  ['CUCAS', 'Casilda'],
  ['CUMAR', 'Mariel'],
  ['CUMNT', 'Manati'],
  ['CUCFG', 'Cienfuegos'],
  ['CUNVT', 'Nuevitas Bay'],
  ['CUICR', 'Nicaro'],
  ['CUSCU', 'Santiago De Cuba'],
  ['CWSMB', 'Sint Michelsbaai'],
  ['CWWIL', 'Willemstad'],
  ['CYLCA', 'Larnaca'],
  ['CYLMS', 'Limassol'],
  ['CYKYR', 'Kyrenia'],
  ['CYFMG', 'Famagusta'],
  ['CYKAR', 'Xeros'],
  ['CYDHK', 'Dhekelia'],
  ['DKSGD', 'Sonderborg'],
  ['DKHBO', 'Hobro'],
  ['DKNVD', 'Naestved'],
  ['DKEBJ', 'Esbjerg'],
  ['DKVEJ', 'Vejle'],
  ['DKSSV', 'Studstrup'],
  ['DKRAN', 'Randers'],
  ['DKNEX', 'Nekso'],
  ['DKTUB', 'Tuborg'],
  ['DKSIT', 'Stigsnaesvaerket'],
  ['DKTED', 'Thisted'],
  ['DKKAL', 'Kalundborg'],
  ['DKFDH', 'Frederikshavn'],
  ['DKODE', 'Odense'],
  ['DKKBY', 'Kyndbyvaerkets Havn'],
  ['DKNBG', 'Nyborg'],
  ['DKGFH', 'Gulfhaven'],
  ['DKAAB', 'Abenra'],
  ['DKKRR', 'Korsor'],
  ['DKASN', 'Assens'],
  ['DKMID', 'Middelfart'],
  ['DKENS', 'Ensted'],
  ['DKRKB', 'Rudkobing'],
  ['DKKOL', 'Kolding'],
  ['DKFRC', 'Fredericia'],
  ['DKHAD', 'Haderslev'],
  ['DKSKA', 'Skagen Havn'],
  ['DKAAR', 'Arhus'],
  ['DKSKB', 'Skaerbaek'],
  ['DKRNN', 'Ronne'],
  ['DKBDX', 'Bandholm'],
  ['DKNYF', 'Nykobing (Falster)'],
  ['DKFAA', 'Faborg'],
  ['DKHAS', 'Hals'],
  ['DKAUB', 'Augustenborg'],
  ['DKHSL', 'Hasle'],
  ['DKROF', 'Rodby Havn'],
  ['DKMRR', 'Mariager'],
  ['DKHOR', 'Horsens'],
  ['DKGRE', 'Grenaa Havn'],
  ['DKKOG', 'Koge'],
  ['DKFDV', 'Frederiksvaerk'],
  ['DKHLS', 'Helsingor'],
  ['DKSVE', 'Svendborg'],
  ['DJJIB', 'Djibouti'],
  ['DMPOR', 'Portsmouth'],
  ['DMRSU', 'Roseau'],
  ['DOSPM', 'San Pedro De Macoris'],
  ['DOSAL', 'Pepillo Salcedo'],
  ['DOLCS', 'Las Calderas'],
  ['DOPVA', 'Puerto Viejo De Azua'],
  ['DOBCC', 'Andres (Andres Lng Terminal)'],
  ['DOSDQ', 'Santo Domingo'],
  ['DOBRX', 'Santa Cruz De Barahona'],
  ['DOLRM', 'La Romana'],
  ['DOSNZ', 'Sanchez'],
  ['DOPOP', 'Puerto Plata'],
  ['DOINA', 'Puerto De Haina'],
  ['ECESM', 'Esmeraldas'],
  ['ECMEC', 'Manta'],
  ['ECPBO', 'Puerto Bolivar'],
  ['ECGYE', 'Guayaquil'],
  ['ECPEV', 'Puerto Maritimo De Guayaquil'],
  ['ECLLD', 'La Libertad'],
  ['EGHRG', 'El Ghardaqa'],
  ['EGSUZ', 'As Suways'],
  ['EGISM', 'El Ismailiya'],
  ['EGAIS', 'North Ain Sukhna Port'],
  ['EGSGA', 'Bur Safaga'],
  ['EGAIS', 'Ain Sukhna Terminal'],
  ['EGRAG', 'Ras Gharib'],
  ['EGDAM', 'Damietta'],
  ['EGRAB', 'Ras Badran Oil Terminal'],
  ['EGSSH', 'Sharm El Sheikh'],
  ['EGAQU', 'Al Qusayr'],
  ['EGMAH', 'El Hamra Oil Terminal'],
  ['EGZTB', 'Zeit Bay Terminal'],
  ['EGRSH', 'Ras Shukhier'],
  ['EGPSD', 'Bur Said (Port Said)'],
  ['EGRSU', 'Ras Sudr'],
  ['EGSOK', 'Sokhna Port Gas Tanker Terminal'],
  ['EGSKT', 'Sidi Kerir (Kurayr)'],
  ['EGADA', 'El-Adabiya'],
  ['EGWAF', 'Wadi Feiran'],
  ['EGTOR', 'El Tur Harbor'],
  ['EGAZA', 'Ras Abu Zanimah'],
  ['EGALY', 'Al Iskandariyh (Alexandria)'],
  ['EGMMU', 'Mersa Matruh'],
  ['SVAQJ', 'Acajutla'],
  ['SVAQJ', 'Acajutla Offshore Terminal'],
  ['SVLUN', 'La Union'],
  ['GQBSG', 'Bata'],
  ['GCCOG', 'Cogo'],
  ['GQSSG', 'Malabo'],
  ['GQSER', 'Serpentina Terminal'],
  ['GQLUB', 'Luba'],
  ['GQPET', 'Punta Europa Terminal'],
  ['ERASA', 'Assab'],
  ['ERMSW', 'Mitsiwa Harbor'],
  ['EEOSM', 'Osmussaar'],
  ['EEPLS', 'Paldiski Lounasadam'],
  ['EEKND', 'Kunda'],
  ['EETLL', 'Vanasadam - Port of Tallinn'],
  ['EEMUG', 'Muuga-Port of Tallin'],
  ['FOKVI', 'Klaksvik'],
  ['FOTOR', 'Torshavn'],
  ['FOVAG', 'Vagur'],
  ['FOTVO', 'Tvoroyri'],
  ['FOSRV', 'Sorvagur'],
  ['FORVK', 'Runavik'],
  ['FOFUG', 'Fuglafjordur'],
  ['FOVES', 'Vestmanna'],
  ['FMPNI', 'Pohnpei Harbor'],
  ['FJSVU', 'Savusavu Bay'],
  ['FJLTK', 'Lautoka Harbor'],
  ['FJSUV', 'Suva Harbor'],
  ['FIHMN', 'Hamina'],
  ['FITOK', 'Tolkkinen'],
  ['FIINK', 'Inkoo'],
  ['FIMTY', 'Mantyluoto'],
  ['FIKRS', 'Kristinestad'],
  ['FIKTK', 'Kotka'],
  ['FIMHQ', 'Mariehamn'],
  ['FIVEI', 'Veitsiluoto'],
  ['FIVAA', 'Vaasa'],
  ['FIOUL', 'Oulu'],
  ['FIJUS', 'Jussaro'],
  ['FIRAA', 'Raahe'],
  ['FILOV', 'Loviisa'],
  ['FIPRS', 'Jakobstad'],
  ['FIHKO', 'Hanko'],
  ['FIPRV', 'Porvoo'],
  ['FIUKI', 'Uusikaupunki'],
  ['FIKAS', 'Kaskinen'],
  ['FIKAN', 'Kantlax'],
  ['FIKEM', 'Kemi'],
  ['FIPOR', 'Pori'],
  ['FITKU', 'Turku'],
  ['FIRAU', 'Rauma'],
  ['FIPAR', 'Pargas'],
  ['FIHEL', 'Helsinki'],
  ['FITOR', 'Tornio'],
  ['FITHK', 'Tahkoluoto'],
  ['FINLI', 'Naantali'],
  ['FRTLN', 'Toulon'],
  ['FRDOL', 'Deauville'],
  ['FROUI', 'Ouistreham'],
  ['FRAJA', "Port D' Ajaccio"],
  ['FRSNR', 'St Nazaire'],
  ['FRBOD', 'Bordeaux'],
  ['FRSRL', 'Saint-Raphael'],
  ['FRCER', 'Rade De Cherbourg'],
  ['FRCLY', 'Calvi'],
  ['FRMRS', 'Marseille'],
  ['FRVSS', 'Villefranche'],
  ['FRAMS', 'Ambes'],
  ['FRILR', "L'ile Rousse"],
  ['FRFEC', 'Fecamp'],
  ['FRSML', 'Saint-Malo'],
  ['FRPVO', 'Porto Vecchio'],
  ['FRLPE', 'La Pallice'],
  ['FRCOC', 'Concarneau'],
  ['FRROS', 'Port De Roscoff-Bloscon'],
  ['FRPAP', 'Pauillac'],
  ['FRDON', 'Donges'],
  ['FRSET', 'Sete'],
  ['FRLEH', 'Port Of Le Havre'],
  ['FRGRV', 'Gravelines'],
  ['FRLSO', "Les Sables D' Olonne"],
  ['FRDKK', 'Dunkerque Port Est'],
  ['FRBOL', 'Boulogne-Sur-Mer'],
  ['FRURO', 'Port Of Rouen'],
  ['FRSTP', 'Saint-Tropez'],
  ['FRLVE', 'Le Verdon'],
  ['FRCFR', 'Port De Caen'],
  ['FRBON', 'Bonifacio'],
  ['FRGFR', 'Granville'],
  ['FRDKK', 'Dunkerque Port Ouest'],
  ['FRPSL', 'Port Saint Louis Du Rhone'],
  ['FRPAI', 'Paimpol'],
  ['FRLLG', 'Le Legue'],
  ['FRLTR', 'Le Treport'],
  ['FRCQF', 'Calais'],
  ['FRBYE', 'Blaye'],
  ['FRFOS', 'Fos'],
  ['FRPDB', 'Port-de-Bouc'],
  ['FRANT', 'Antibes'],
  ['FRNOU', 'Port-La-Nouvelle'],
  ['FRBAY', 'Bayonne'],
  ['FRPOX', 'Pontrieux'],
  ['FRBES', 'Rade De Brest'],
  ['FRNTE', 'Nantes'],
  ['FRNCE', 'Nice'],
  ['FRDPE', 'Dieppe'],
  ['FRMXN', 'Morlaix'],
  ['FRHON', 'Honfleur'],
  ['FRBIA', 'Bastia'],
  ['FRLRT', 'Lorient'],
  ['FRCEQ', 'Cannes'],
  ['FRPOV', 'Port-Vendres'],
  ['FRPRP', 'Port De Propriano'],
  ['FRTRE', 'Treguier'],
  ['GFDDC', 'Degrad Des Cannes'],
  ['PFUTU', 'Uturoa'],
  ['PFPPT', 'Papeete'],
  ['PFTAI', 'Baie Taiohae'],
  ['PFAUQ', 'Atuona'],
  ['PFVAI', 'Vaitape'],
  ['GAMBY', "M'bya Oil Terminal"],
  ['GAGAX', 'Gamba Oil Terminal'],
  ['GAOWE', 'Port Owendo'],
  ['GAETA', 'Etame Terminal'],
  ['GACLZ', 'Cap Lopez'],
  ['GALBV', 'Libreville'],
  ['GAOGU', 'Oguendjo Terminal'],
  ['GAPOG', 'Port Gentil'],
  ['GESPS', 'Supsa Marine Terminal'],
  ['GEPTI', 'Poti'],
  ['GEABK', 'Sokhumi'],
  ['GEBUS', 'Batumi'],
  ['DEBKE', 'Brake'],
  ['DEKEL', 'Kiel'],
  ['DETRV', 'Lubeck-Travemunde'],
  ['DELEE', 'Leer'],
  ['DENHA', 'Nordenham'],
  ['DELBC', 'Lubeck'],
  ['DEBRB', 'Brunsbuttel Canal Terminals'],
  ['DEHUS', 'Husum'],
  ['DEHHF', 'Heiligenhafen'],
  ['DEBRB', 'Brunsbuttel Elbahafen'],
  ['DEECK', 'Eckernforde'],
  ['DEBRV', 'Bremerhaven'],
  ['DEEME', 'Emden'],
  ['DESTL', 'Stralsund'],
  ['DEBUZ', 'Butzfleth'],
  ['DEFLF', 'Flensburg'],
  ['DEITZ', 'Itzehoe'],
  ['DEWVN', 'Wilhelmshaven'],
  ['DEPAP', 'Papenburg'],
  ['DESAS', 'Sassnitz'],
  ['DERSK', 'Rostock'],
  ['DENEH', 'Neuhaus'],
  ['DEGLU', 'Gluckstadt'],
  ['DEKAP', 'Kappeln'],
  ['DEWOL', 'Wolgast'],
  ['DEBRE', 'Bremen'],
  ['DEWIS', 'Wismar'],
  ['GHSPD', 'Saltpond'],
  ['GHTEM', 'Tema'],
  ['GHTKD', 'Takoradi'],
  ['GRMLO', 'Milos'],
  ['GRKAR', 'Neon Karlovas'],
  ['GRSMI', 'Samos'],
  ['GRNAV', 'Pilos'],
  ['GRJKH', 'Khios'],
  ['GRLAV', 'Lavrio'],
  ['GRVOL', 'Volos'],
  ['GRGPA', 'Patrai'],
  ['GRPLM', 'Plomarion'],
  ['GRMJT', 'Mitilini'],
  ['GRAST', 'Astakos'],
  ['GRGYT', 'Yithion'],
  ['GRJSH', 'Sitia'],
  ['GRFLS', 'Elevsis'],
  ['GRSKG', 'Thessaloniki'],
  ['GRARM', 'Argostolion'],
  ['GRPKK', 'Lakki'],
  ['GRKYM', 'Kymassi'],
  ['GRHER', 'Iraklion'],
  ['GRQKG', 'Dhiavlos Steno'],
  ['GRSTI', 'Stratoni'],
  ['GRCFU', 'Kerkira'],
  ['GRKMI', 'Kalimnos'],
  ['GRITA', 'Itea'],
  ['GRKGS', 'Limin Kos'],
  ['GRACL', 'Achladi'],
  ['GRJNX', 'Nisos Naxos'],
  ['GRPIR', 'Piraievs'],
  ['GRPVK', 'Preveza'],
  ['GRYER', 'Yerakini'],
  ['GRLVR', 'Ormos Aliveriou'],
  ['GRJMK', 'Mikonos'],
  ['GRJSY', 'Limin Sirou'],
  ['GRMGR', 'Megara Oil Terminal'],
  ['GRKLX', 'Kalamata'],
  ['GRVAT', 'Ormos Mikro Vathi'],
  ['GRPYT', 'Pithagorion'],
  ['GRSDH', 'Soudha'],
  ['GRANI', 'Ayios Nikolaos'],
  ['GRGIE', 'Aigio'],
  ['GRASS', 'Aspropirgos'],
  ['GRAND', 'Andros'],
  ['GRKAK', 'Katakolon'],
  ['GRTSI', 'Tsingeli'],
  ['GRMEL', 'Mesolongion'],
  ['GRAXD', 'Alexandroupoli'],
  ['GRNPK', 'Navplio'],
  ['GRPER', 'Perama'],
  ['GRKVA', 'Kavala'],
  ['GRRET', 'Rethimnon'],
  ['GRRHO', 'Rodhos'],
  ['GLJEG', 'Aasiaat'],
  ['GLJSU', 'Maniitsoq'],
  ['GLJAV', 'Illulissat (Jakobshavn)'],
  ['GLJGO', 'Qeqertarsuaq'],
  ['GLGOH', 'Nuuk'],
  ['GLJNN', 'Nanortalik Havn'],
  ['GLJNS', 'Narsaq'],
  ['GLJCH', 'Qasigiannguit-Christianshab'],
  ['GLJGR', 'Gronnedal (Kangilinnguit)'],
  ['GLTHU', 'Pituffik (Thule Air Base)'],
  ['GLUAK', 'Narsarssuaq'],
  ['GLJHS', 'Sisimiut'],
  ['GLJFR', 'Paamuit (Frederikshab)'],
  ['GDSTG', "St George's"],
  ['GPPTP', 'Pointe A Pitre'],
  ['GPBBR', 'Basse Terre'],
  ['GPPTL', 'Port Louis'],
  ['BLGUS', 'Gustavia'],
  ['GUAPR', 'Apra Harbor'],
  ['GTPRQ', 'Puerto Quetzal'],
  ['GTPBR', 'Puerto Barrios'],
  ['GGSPT', 'Saint Peter Port'],
  ['GGACI', 'Alderney Harbour'],
  ['GGSTS', 'Saint Sampson'],
  ['GNBTY', 'Benti'],
  ['GNCKY', 'Conakry'],
  ['GNKMC', 'Kamsar'],
  ['GWCAC', 'Rio Cacheu'],
  ['HTCAP', 'Cap Haitien'],
  ['HTMIR', 'Miragoane'],
  ['HTPAP', 'Port Au Prince'],
  ['HTJAK', 'Jacmel'],
  ['HTPEG', 'Petit Goave'],
  ['HNPCA', 'Puerto Castilla'],
  ['HNRTB', 'Coxen Hole'],
  ['HNPCR', 'Puerto Cortes'],
  ['HNTJI', 'Trujillo'],
  ['HNLCE', 'La Ceiba'],
  ['HNSLO', 'Puerto De Hencan'],
  ['HNTEA', 'Tela'],
  ['HKHKG', 'Hong Kong'],
  ['ISBIL', 'Bildudalur'],
  ['ISFAS', 'Budir'],
  ['ISAKU', 'Akureyri'],
  ['ISVES', 'Vestmannaeyjar'],
  ['ISSIG', 'Siglufjorhurd'],
  ['ISGRT', 'Grundartangi'],
  ['ISREY', 'Reykjavik'],
  ['ISTEY', 'Thingeyri'],
  ['ISDAL', 'Dalvik'],
  ['ISHAF', 'Hafnarfjordur'],
  ['ISNES', 'Neskaupstadur'],
  ['ISISA', 'Isafjordur'],
  ['ISHUS', 'Husavik'],
  ['ISKEV', 'Keflavik'],
  ['ISKJF', 'Skerjafjordur'],
  ['ISESK', 'Eskifjordhur'],
  ['ISSTR', 'Straumsvik'],
  ['ISSEY', 'Seydhisfjordhur'],
  ['INKRW', 'Karwar'],
  ['INMAA', 'Chennai (Madras)'],
  ['INBEY', 'Beypore'],
  ['INBOM', 'Mumbai (Bombay)'],
  ['INPNY', 'Pondicherry'],
  ['INMRM', 'Marmagao'],
  ['INKAK', 'Kakinada Bay'],
  ['INKRK', 'Karaikal Port'],
  ['INBLK', 'Belekeri'],
  ['INMDV', 'Mandvi'],
  ['INBED', 'Bedi'],
  ['INNPT', 'Nagappattinam'],
  ['INVVA', 'Veraval'],
  ['INPBD', 'Porbandar'],
  ['INTUT', 'Tuticorin'],
  ['INBHU', 'Bhavnagar'],
  ['INKOC', 'Kochi (Cochin)'],
  ['INMDA', 'Magdalla'],
  ['INPAN', 'Panaji'],
  ['INNML', 'New Mangalore'],
  ['INPPT', 'Paradip'],
  ['INQUI', 'Quilon (Kollam)'],
  ['INAZK', 'Azhikal (Azhikkal)'],
  ['INRTC', 'Ratnagiri'],
  ['INPAV', 'Pipavav Bandar'],
  ['INIXY', 'Kandla'],
  ['INCCU', 'Calcutta'],
  ['INVAD', 'Vadinar Terminal'],
  ['INSKK', 'Sikka'],
  ['INDAH', 'Dahej'],
  ['INHAL', 'Haldia Port'],
  ['INOKH', 'Okha'],
  ['INCCJ', 'Calicut (Kozhikode)'],
  ['INNSA', 'Jawaharlal Nehru Port (Nhava Shiva)'],
  ['INIXZ', 'Port Blair'],
  ['INNAV', 'Navlakhi'],
  ['INCDL', 'Cuddalore'],
  ['INTRV', 'Trivandrum'],
  ['INHZA', 'Hazira'],
  ['INMUN', 'Mundra'],
  ['IDNAM', 'Namlea'],
  ['IDSTN', 'Stagen'],
  ['IDBLW', 'Belawan'],
  ['IDKUM', 'Kumai'],
  ['IDKOE', 'Kupang'],
  ['IDTBN', 'Tuban'],
  ['IDKBU', 'Kota Baru'],
  ['IDBEN', 'Tanjung Benete'],
  ['IDPSS', 'Pulau Sambu'],
  ['IDBIT', 'Bitung'],
  ['IDWBA', 'Wahai'],
  ['IDPLM', 'Palembang'],
  ['IDMUO', 'Muntok'],
  ['IDWGP', 'Waingapu'],
  ['IDTAL', 'Tanah Merah'],
  ['IDCEB', 'Celukan Bawang'],
  ['IDSEQ', 'Sungaipakning'],
  ['IDBPN', 'Balikpapan'],
  ['IDPEX', 'Pekalongan'],
  ['IDBTH', 'Gunung Batu Besar'],
  ['IDAMP', 'Ampenan'],
  ['IDGRE', 'Gresik'],
  ['IDBYQ', 'Tanjung Arang  (Bunyu)'],
  ['IDFKQ', 'Fakfak'],
  ['IDNPL', 'North Pulau Laut Coal Terminal'],
  ['IDDUM', 'Dumai'],
  ['IDJBT', 'Jabung Batanghari Marine Terminal'],
  ['IDBXT', 'Bontang Lng Terminal'],
  ['IDTBL', 'Toboali'],
  ['IDBUW', 'Baubau'],
  ['IDMKW', 'Manokwari Road'],
  ['IDBUA', 'Bula'],
  ['IDPRN', 'Panarukan'],
  ['IDWID', 'Widuri Marine Terminal'],
  ['IDBKS', 'Bengkulu'],
  ['IDCBN', 'Cirebon'],
  ['IDDJJ', 'Jayapura'],
  ['IDGTO', 'Gorontalo'],
  ['IDUDG', 'Udang Oilfield'],
  ['IDTEG', 'Tegal'],
  ['IDLSW', 'Lhokseumawe'],
  ['IDKAS', 'Kasim Terminal'],
  ['IDPSJ', 'Poso'],
  ['IDPOL', 'Poleng Oil Field'],
  ['IDSWT', 'Salawati'],
  ['IDENE', 'Ende'],
  ['IDBOA', 'Benoa'],
  ['IDBNT', 'Belanak Field Terminal'],
  ['IDSZH', 'Senipah Oil Terminal'],
  ['IDMKQ', 'Merauke'],
  ['IDBET', 'Belida Marine Terminal'],
  ['IDSXK', 'Saumlaki'],
  ['IDBLL', 'Blanglancang'],
  ['IDKDI', 'Kendari'],
  ['IDSRI', 'Samarinda'],
  ['IDLUW', 'Luwuk'],
  ['IDSRG', 'Semarang'],
  ['IDPNK', 'Pontianak'],
  ['IDSLG', 'Sibolga'],
  ['IDKTJ', 'Kuala Tanjung'],
  ['IDTJQ', 'Tanjungpandan'],
  ['IDKNL', 'Kolonodale'],
  ['IDCXP', 'Cilacap'],
  ['IDLAH', 'Labuha'],
  ['IDSKP', 'Sekupang'],
  ['IDPAP', 'Parepare'],
  ['IDTBR', 'Teluk Bayur'],
  ['IDPUM', 'Pomalaa'],
  ['IDMOF', 'Maumere'],
  ['IDTTE', 'Ternate'],
  ['IDBKI', 'Bengkalis'],
  ['IDAJN', 'Ardjuna Oil Field'],
  ['IDBLI', 'Blinyu'],
  ['IDUPG', 'Ujung Pandang'],
  ['IDLLA', 'Lawi Lawi Oil Terminal'],
  ['IDBTN', 'Banten'],
  ['IDMDC', 'Manado'],
  ['IDLKA', 'Larantuka'],
  ['IDAMQ', 'Ambon'],
  ['IDPRO', 'Probolinggo'],
  ['IDRAQ', 'Raha Roadstead'],
  ['IDSUB', 'Surabaya'],
  ['IDSAT', 'Tanjung Santan'],
  ['IDREM', 'Rembang'],
  ['IDTJB', 'Tanjung Balai Karimun'],
  ['IDAMA', 'Amamapare'],
  ['IDBDJ', 'Banjarmasin'],
  ['IDOLO', 'Uleelheue'],
  ['IDLAT', 'Lalang Marine Terminal'],
  ['IDANR', 'Anyer Lor'],
  ['IDSBG', 'Sabang'],
  ['IDTAN', 'Tanjunguban'],
  ['IDMRK', 'Merak Mas Terminal'],
  ['IDKIJ', 'Kijang'],
  ['IDPNJ', 'Panjang'],
  ['IRABD', 'Abadan'],
  ['IRKHO', 'Khorramshahr'],
  ['IRZBR', 'Chah Bahar'],
  ['IRHOR', 'Jazireh-Ye Hormoz'],
  ['IRBUZ', 'Bushehr'],
  ['IRBKM', 'Bandar Khomeyni'],
  ['IRJAK', 'Jask'],
  ['IRBND', 'Bandar Abbas'],
  ['IRCYT', 'Sirus Oil Terminal'],
  ['IRKHK', 'Kharg Island Oil Terminal'],
  ['IRBSR', 'Bandar-E Shahid Reajie'],
  ['IRLVP', 'Jazireh-Ye Lavan Oil Terminal'],
  ['IRSXI', 'Jazireh-Ye Sirri'],
  ['IQKHA', 'Khawr Al Amaya'],
  ['IQKAZ', 'Khawr Al Zubair Lng Terminal'],
  ['IQKAZ', 'Khawr Al Zubair'],
  ['IQUQR', 'Umm Qasr'],
  ['IETAR', 'Tarbert'],
  ['IEROE', 'Rosslare'],
  ['IERAT', 'Rathmullan'],
  ['IESLI', 'Sligo'],
  ['IECTB', 'Castletown Bearhaven'],
  ['IEWES', 'Westport'],
  ['IEKBS', 'Killybegs'],
  ['IEMTL', 'Dublin'],
  ['IEFOV', 'Foynes'],
  ['IECOB', 'Cobh'],
  ['IELMK', 'Limerick'],
  ['IEGRN', 'Greenore'],
  ['IEGWY', 'Galway'],
  ['IEBTH', 'Bantry'],
  ['IEKLR', 'Kilrush'],
  ['IEORK', 'Cork'],
  ['IEWAT', 'Waterford'],
  ['IMDGS', 'Douglas'],
  ['ILHFA', 'Haifa'],
  ['ILHAD', 'Hadera'],
  ['ILASH', 'Ashdod'],
  ['ILAKL', 'Ashqelon'],
  ['ILETH', 'Elat'],
  ['ITPFX', 'Sarroch Oil Terminal'],
  ['ITMAZ', 'Mazara Del Vallo'],
  ['ITPSR', 'Pescara'],
  ['ITPTO', 'Porto Torres'],
  ['ITMSN', 'Messina'],
  ['ITPOZ', 'Pozzuoli'],
  ['ITGIT', 'Gioia Tauro'],
  ['ITPZL', 'Pozzallo'],
  ['ITAOI', 'Ancona'],
  ['ITPFE', 'Portoferraio'],
  ['ITAUG', 'Augusta'],
  ['ITNAP', 'Napoli'],
  ['ITVDA', 'Vada'],
  ['ITPGA', 'Porto Garibaldi'],
  ['ITBLT', 'Porto Di Barletta'],
  ['ITTRS', 'Trieste'],
  ['ITQOS', 'Porto Di Oristano'],
  ['ITPPF', 'Porto Ponte Romano'],
  ['ITPVE', 'Porto Vesme'],
  ['ITGAL', 'Gallipoli'],
  ['ITSPA', 'Santa Panagia'],
  ['ITPSS', 'Porto Santo Stefano'],
  ['ITLIP', 'Lipari'],
  ['ITOTO', 'Otranto'],
  ['ITBDS', 'Brindisi'],
  ['ITMLZ', 'Milazzo'],
  ['ITCTA', 'Catania'],
  ['ITCAG', 'Cagliari'],
  ['ITPMO', 'Porto Di Palermo'],
  ['ITATX', 'Arbatax'],
  ['ITREG', 'Reggio Di Calabria'],
  ['ITSPE', 'La Spezia'],
  ['ITMRA', 'Marsala'],
  ['ITFCO', 'Fiumicino'],
  ['ITIMP', 'Imperia'],
  ['ITLIC', 'Licata'],
  ['ITBAI', 'Baia'],
  ['ITCLF', 'Carloforte'],
  ['ITBRI', 'Bari'],
  ['ITSIR', 'Siracusa'],
  ['ITMDC', 'Marina Di Carrara'],
  ['ITGEA', 'Gela'],
  ['ITTPS', 'Trapani'],
  ['ITMDA', 'Maddalena'],
  ['ITPEM', 'Porto Empedocle'],
  ['ITSAL', 'Salerno'],
  ['ITCVV', 'Civitavecchia'],
  ['ITPNG', 'Nogaro'],
  ['ITCAS', 'Castellammare Di Stabia'],
  ['ITTOA', 'Torre Annunziata'],
  ['ITVSO', 'Vasto'],
  ['ITPIO', 'Portovecchio Di Piombino'],
  ['ITTAR', 'Taranto'],
  ['ITLIV', 'Livorno'],
  ['ITSVN', 'Savona'],
  ['ITLMP', 'Lampedusa'],
  ['ITANZ', 'Anzio - Nettuno'],
  ['ITCRV', 'Crotone'],
  ['ITBLN', 'Bagnoli'],
  ['ITTRI', 'Termini Imerese'],
  ['ITSRE', 'San Remo'],
  ['ITMNF', 'Monfalcone'],
  ['ITGAE', 'Gaeta'],
  ['ITMFR', 'Manfredonia'],
  ['ITGOA', 'Genova'],
  ['ITMNP', 'Monopoli'],
  ['ITOLB', 'Olbia'],
  ['ITVCE', 'Porto Di Lido-Venezia'],
  ['ITUGG', 'Muggia'],
  ['ITVDL', 'Rada Di Vado'],
  ['JMPOT', 'Port Antonio'],
  ['JMKIN', 'Kingston'],
  ['JMSLM', 'Savannah La Mar'],
  ['JMRIB', 'Rio Bueno'],
  ['JMFMH', 'Falmouth'],
  ['JMPKS', 'Port Kaiser'],
  ['JMROP', 'Rocky Point'],
  ['JMMBJ', 'Montego Bay'],
  ['JMOCJ', 'Ocho Rios'],
  ['JMLUC', 'Lucea'],
  ['JMPEV', 'Port Esquivel'],
  ['JPTOB', 'Toba'],
  ['JPSAE', 'Saiki Ko'],
  ['JPWAK', 'Wakayama-Shimotsu Ko'],
  ['JPISG', 'Ishigaki'],
  ['JPKCZ', 'Kochi Ko'],
  ['JPTKM', 'Takuma'],
  ['JPOND', 'Onoda'],
  ['JPHHR', 'Higashi-Harima'],
  ['JPUCR', 'Uchiura'],
  ['JPSHS', 'Shimonoseki'],
  ['JPSAK', 'Sakai Ko'],
  ['JPOSA', 'Osaka'],
  ['JPKUD', 'Kudamatsu'],
  ['JPOFO', 'Ofunato'],
  ['JPONG', 'Onagawa'],
  ['JPRYO', 'Ryotu Ko'],
  ['JPKIJ', 'Niigata Ko'],
  ['JPYOS', 'Yokosuka Ko'],
  ['JPNAH', 'Naha Ko'],
  ['JPOTR', 'Otaru Ko'],
  ['JPONA', 'Onahama Ko'],
  ['JPNAO', 'Naoetsu Ko'],
  ['JPMTR', 'Muturezima Ko'],
  ['JPUNO', 'Uno Ko'],
  ['JPIMI', 'Imari'],
  ['JPOMZ', 'Omaezaki Ko'],
  ['JPMII', 'Miike Ko'],
  ['JPAOJ', 'Aomori Ko'],
  ['JPKNN', 'Kin Wan'],
  ['JPKUH', 'Kushiro Ko'],
  ['JPABA', 'Abashiri Ko'],
  ['JPAXT', 'Akita-Funakawa Ko'],
  ['JPISM', 'Ishinomaki Ko'],
  ['JPMYK', 'Miyako'],
  ['JPNNO', 'Nanao Ko'],
  ['JPSUZ', 'Susaki Ko'],
  ['JPMTS', 'Matsu-Shima'],
  ['JPKKW', 'Kanokawa Ko'],
  ['JPKOM', 'Komatsushima'],
  ['JPAMA', 'Amagasaki'],
  ['JPKNU', 'Kinuura Ko'],
  ['JPHAN', 'Hannan Ko'],
  ['JPTBN', 'Tachibana'],
  ['JPKNZ', 'Kanazawa'],
  ['JPHAG', 'Hagi Ko'],
  ['JPYKK', 'Yokkaichi'],
  ['JPKGA', 'Kakogawa'],
  ['JPSSB', 'Sasebo'],
  ['JPTMK', 'Tomakomai Ko'],
  ['JPFTX', 'Fushiki-Toyama'],
  ['JPNEM', 'Nemuro Ko'],
  ['JPHKD', 'Hakodate Ko'],
  ['JPRMI', 'Rumoi Ko'],
  ['JPOIT', 'Oita Ko'],
  ['JPSKT', 'Sakata Ko'],
  ['JPNAK', 'Nakagusuku'],
  ['JPUWA', 'Uwajima Ko'],
  ['JPKND', 'Kanda'],
  ['JPMYJ', 'Matsuyama'],
  ['JPHIM', 'Himeji'],
  ['JPMOR', 'Mori'],
  ['JPTYO', 'Tokyo Ko'],
  ['JPSAG', 'Saganoseki Ko'],
  ['JPKII', 'Kiire'],
  ['JPAIO', 'Aioi'],
  ['JPMIS', 'Misumi Ko'],
  ['JPSBS', 'Shibushi Wan'],
  ['JPKSN', 'Kesennuma Ko'],
  ['JPFKJ', 'Fukui Ko'],
  ['JPYAT', 'Yatsushiro Ko'],
  ['JPKOK', 'Kokura Ko'],
  ['JPYUA', 'Yura'],
  ['JPGAM', 'Gamagori Ko'],
  ['JPKZU', 'Kisarazu Ko'],
  ['JPMAI', 'Maizuru Ko'],
  ['JPONX', 'Onomichi-Itozaki'],
  ['JPTKY', 'Tokuyama'],
  ['JPFYM', 'Fukuyama'],
  ['JPTRG', 'Tsuruga Ko'],
  ['JPTMI', 'Tsukumi Ko'],
  ['JPKOJ', 'Kagoshima Ko'],
  ['JPHNK', 'Hanasaki Ko'],
  ['JPHIJ', 'Hiroshima'],
  ['JPKIK', 'Kikuma Ko'],
  ['JPSMZ', 'Shimizu Ko'],
  ['JPMSA', 'Matsusaka'],
  ['JPTTY', 'Tateyama Ko'],
  ['JPMIY', 'Miyazu'],
  ['JPMUR', 'Muroran Ko'],
  ['JPOWA', 'Owase Ko'],
  ['JPSBK', 'Sakai-Senboku'],
  ['JPFNB', 'Funabashi'],
  ['JPMKW', 'Mikawa'],
  ['JPUBJ', 'Ube Ko'],
  ['JPSKD', 'Sakaide Ko'],
  ['JPHRR', 'Hirara Ko'],
  ['JPYWH', 'Yawatahama'],
  ['JPKAR', 'Karatsu'],
  ['JPKRE', 'Kure'],
  ['JPUKB', 'Kobe'],
  ['JPURG', 'Uraga Ko'],
  ['JPKIS', 'Kamaishi Ko'],
  ['JPCHB', 'Chiba Ko'],
  ['JPHAB', 'Habu Ko'],
  ['JPNGO', 'Nagoya Ko'],
  ['JPTAK', 'Takamatsu'],
  ['JPNGS', 'Nagasaki'],
  ['JPYAN', 'Yanai'],
  ['JPIHA', 'Niihama'],
  ['JPMKX', 'Mishima-Kawanoe Ko'],
  ['JPBPU', 'Beppu'],
  ['JPIZH', 'Izuhara'],
  ['JPKWZ', 'Kashiwazaki'],
  ['JPTOK', 'Tokachi'],
  ['JPAOK', 'Aokata'],
  ['JPHKT', 'Hakata'],
  ['JPFKJ', 'Fukui'],
  ['JPIWK', 'Iwakuni Ko'],
  ['JPHMD', 'Hamada Ko'],
  ['JPYOK', 'Yokohama Ko'],
  ['JPHHE', 'Hachinohe Ko'],
  ['JPWKJ', 'Wakkanai'],
  ['JBHBI', 'Hibikinada'],
  ['JPHSM', 'Hososhima Ko'],
  ['JPESI', 'Esasi Ko'],
  ['JPKWS', 'Kawasaki Ko'],
  ['JPWAM', 'Wakamatsu Ko'],
  ['JPMOJ', 'Moji Ko'],
  ['JPMIN', 'Minamata Ko'],
  ['JPTAE', 'Tanabe Ko'],
  ['JPKSM', 'Kashima Ko'],
  ['JPHMK', 'Himekawa'],
  ['JPTND', 'Tonda'],
  ['JPHIB', 'Hibi Ko'],
  ['JPSGM', 'Sendai-Shiogama'],
  ['JPMIZ', 'Mizushima Ko'],
  ['JPIMB', 'Imabari Ko'],
  ['JESTH', 'Saint Helier Harbour'],
  ['JOAQJ', 'Al Aqabah'],
  ['KEMBA', 'Mombasa'],
  ['KEMYD', 'Malindi'],
  ['KEKLF', 'Kilifi'],
  ['KICIS', 'Canton Island'],
  ['KITRW', 'Tarawa Atoll'],
  ['KWKWI', 'Al Kuwayt'],
  ['KWMEA', 'Mina Al Ahmadi'],
  ['KWSAA', 'Mina Ash Shuaybah'],
  ['KWMIB', 'Mina Abd Allah'],
  ['KWMIS', 'Mina Az Zawr'],
  ['LVLPX', 'Liepaja'],
  ['LVVNT', 'Ventspils'],
  ['LVRIX', 'Riga'],
  ['LVRIX', 'Salacgriva'],
  ['LBSAY', 'Sayda'],
  ['LBSDN', 'Sidon/zahrani Terminal'],
  ['LBBEY', 'Bayrut'],
  ['LRUCN', 'Buchanan'],
  ['LRCPA', 'Cape Palmas'],
  ['LRMLW', 'Monrovia'],
  ['LRGRE', 'Greenville'],
  ['LYTIP', 'Mina Tarabulus (Tripoli)'],
  ['LYMBR', 'Al Burayqah'],
  ['LYRLA', 'Ras   Lanuf'],
  ['LYESI', 'As Sidr'],
  ['LYZUE', 'Ez Zueitina'],
  ['LYMIS', 'Misratah'],
  ['LYZAW', 'Az Zawiya'],
  ['LYDRX', 'Darnah'],
  ['LYKHM', 'Khoms'],
  ['LYTOB', 'Mersa Tobruq'],
  ['LYBEN', 'Banghazi'],
  ['LTKLJ', 'Klaipeda'],
  ['LTBOT', 'Butinge Oil Terminal'],
  ['MGWVK', 'Manakara'],
  ['MGWMN', 'Maroantsetra'],
  ['MGTOA', 'Toamasina'],
  ['MGMJN', 'Mahajanga'],
  ['MGDIE', 'Antsiranana'],
  ['MGTLE', 'Toliara'],
  ['MGTLN', 'Tolanaro'],
  ['MGMXT', 'Maintirano'],
  ['MGMOQ', 'Morondava'],
  ['MGMNJ', 'Mananjary'],
  ['MYKUD', 'Kudat'],
  ['MYLUM', 'Lumut'],
  ['MYBTU', 'Bintulu Port'],
  ['MYJHB', 'Johor'],
  ['MYPDI', 'Port Dickson'],
  ['MYSAR', 'Sarikei'],
  ['MYPEN', 'Pulau Pinang'],
  ['MYLGK', 'Port Langkawi'],
  ['MYLDU', 'Lahad Datu'],
  ['MYTMP', 'Tg. Mani'],
  ['MYSDK', 'Pelabuhan Sandakan'],
  ['MYTPP', 'Tanjung Pelepas'],
  ['MYBKI', 'Kota Kinabalu'],
  ['MYPKG', 'Port Klang'],
  ['MYKCH', 'Kuching'],
  ['MYKUA', 'Kuantan New Port'],
  ['MYKEM', 'Kemaman Harbor'],
  ['MYSUP', 'Pelabuhan Sungai Udang'],
  ['MYMYY', 'Miri'],
  ['MYLUT', 'Lutong'],
  ['MYTGG', 'Kuala Trengganu'],
  ['MYTWU', 'Tawa'],
  ['MYSBW', 'Sibu'],
  ['MVMCP', 'Male'],
  ['MTMLA', 'Valletta Harbors'],
  ['MTMAR', 'Marsaxlokk'],
  ['MHKWA', 'Kwajalein'],
  ['MHMAJ', 'Majuro Atoll'],
  ['MHENT', 'Enitwetak Island'],
  ['MQFDF', 'Fort De France'],
  ['MRNKC', 'Nouakchott'],
  ['MRNDB', 'Nouadhibou'],
  ['MUPLU', 'Port Louis'],
  ['MUPMA', 'Port Mathurin'],
  ['MXSRL', 'Santa Rosalia'],
  ['MXACA', 'Acapulco'],
  ['MXATM', 'Altamira'],
  ['MXVER', 'Veracruz'],
  ['MXPGO', 'Progreso'],
  ['MXPVR', 'Puerto Vallarta'],
  ['MXSCX', 'Salina Cruz'],
  ['MXLZC', 'Lazaro Cardenas'],
  ['MXCOA', 'Coatzacoalcos'],
  ['MXLAP', 'La Paz'],
  ['MXFRN', 'Frontera'],
  ['MXCZM', 'San Miguel De Cozumel'],
  ['MXSMI', 'Isla San Marcos'],
  ['MXGYM', 'Guaymas'],
  ['MXTAM', 'Tampico'],
  ['MXDBT', 'Dos Bocas Terminal'],
  ['MXZLO', 'Manzanillo'],
  ['MXMZT', 'Mazatlan'],
  ['MXPIC', 'Pichilingue'],
  ['MXPMD', 'Puerto Madero'],
  ['MXPMS', 'Puerto Morro Redondo'],
  ['MXRST', 'Rosarito'],
  ['MXCAT', 'Cayo Arcas Terminal'],
  ['MXTUX', 'Tuxpan'],
  ['MXNAN', 'Nanchital'],
  ['MXTPB', 'Topolobampo'],
  ['MXCME', 'Ciudad Del Carmen'],
  ['MCMON', 'Monaco'],
  ['MERSN', 'Risan'],
  ['MEKOT', 'Kotor'],
  ['MEBAR', 'Bar'],
  ['METIV', 'Tivat'],
  ['MANNA', 'Kenitra'],
  ['MAJFL', 'El Jorf Lasfar'],
  ['MAMOH', 'Mohammedia'],
  ['MAAHU', 'Port D Al Hoceima'],
  ['MATNG', 'Tanger'],
  ['MAELJ', 'El Jadida'],
  ['MANDR', 'Port Nador'],
  ['MACAS', 'Casablanca'],
  ['MAESS', 'Essaouira'],
  ['MASFI', 'Safi'],
  ['MZBEW', 'Beira'],
  ['MZPBE', 'Porto Belo'],
  ['MZMNC', 'Nacala'],
  ['MZUEL', 'Quelimane'],
  ['MZPOL', 'Pemba'],
  ['MZMPM', 'Maputo'],
  ['MZPEB', 'Pebane'],
  ['MZINH', 'Inhambane'],
  ['MZMZQ', 'Mocambique'],
  ['MZIBO', 'Ibo'],
  ['NALUD', 'Luderitz Bay'],
  ['NAWVB', 'Walvis Bay'],
  ['NLVLA', 'Vlaardingen'],
  ['NLMSL', 'Maassluis'],
  ['NLHAN', 'Hansweert'],
  ['NLIJM', 'Ijmuiden'],
  ['NLDHR', 'Den Helder'],
  ['NLTNZ', 'Terneuzen'],
  ['NLRTM', 'Rotterdam'],
  ['NLHVH', 'Hoek Van Holland'],
  ['NLSCE', 'Scheveningen'],
  ['NLEUR', 'Europoort'],
  ['NLSCI', 'Schiedam'],
  ['NLDOR', 'Dordrecht'],
  ['NLEEM', 'Eemshaven'],
  ['NLAMS', 'Amsterdam'],
  ['NLZAA', 'Zaandam'],
  ['NLVLI', 'Vlissingen'],
  ['NLDZL', 'Delfzijl'],
  ['NCBUG', 'Baie Ugue'],
  ['NCNOU', 'Noumea'],
  ['NCKOU', 'Baie De Kouaoua'],
  ['NZWSZ', 'Westport'],
  ['NZGIS', 'Gisborne'],
  ['NZWHR', 'Whangaroa'],
  ['NZNSN', 'Nelson'],
  ['NZBLU', 'Bluff Harbor'],
  ['NZWLG', 'Wellington'],
  ['NZTIU', 'Timaru'],
  ['NZGMN', 'Greymouth'],
  ['NZWAG', 'Wanganui'],
  ['NZOPX', 'Opua'],
  ['NZNPL', 'New Plymouth'],
  ['NZNPE', 'Napier'],
  ['NZTRG', 'Tauranga'],
  ['NZWRE', 'Whangarei'],
  ['NZPCN', 'Picton'],
  ['NZORR', 'Otago Harbor'],
  ['NZLYT', 'Port Lyttelton'],
  ['NZAKL', 'Auckland'],
  ['NZTKH', 'Tarakohe'],
  ['NIELB', 'El Bluff'],
  ['NIPSN', 'Puerto Sandino'],
  ['NICIO', 'Corinto'],
  ['NIBEF', 'Bluefields'],
  ['NGOKR', 'Okrika'],
  ['NGONN', 'Onne'],
  ['NGTIN', 'Tin Can Island'],
  ['NGBUR', 'Burutu'],
  ['NGCBQ', 'Calabar'],
  ['NGSPL', 'Sapele'],
  ['NGPEN', 'Pennington Oil Terminal'],
  ['NGBON', 'Bonny'],
  ['NGOKN', 'Okono Terminal'],
  ['NGODU', 'Odudu Terminal'],
  ['NGOKW', 'Okwori Terminal'],
  ['NGFOR', 'Forcados Oil Terminal'],
  ['NGPHC', 'Port Harcourt'],
  ['NGFOR', 'Forcados'],
  ['NGLOS', 'Lagos'],
  ['NGABM', 'Agbami Oil Terminal'],
  ['NGANA', 'Antan Oil Terminal'],
  ['NGESC', 'Escravos Oil Terminal'],
  ['NGQIB', 'Qua Iboe Oil Terminal'],
  ['NGAKP', 'Akpo Oil Terminal'],
  ['NGUKP', 'Ukpokiti Marine Terminal'],
  ['NGBRA', 'Brass Oil Terminal'],
  ['NGBON', 'Bonny Offshore Terminal'],
  ['NGWAR', 'Warri'],
  ['NGKOK', 'Koko'],
  ['NUALO', 'Alofi'],
  ['KPCHO', "Ch'ongjin"],
  ['KPWON', 'Wonsan'],
  ['KPHAE', 'Haeju Hang'],
  ['KPNAM', 'Nampo'],
  ['KPSON', "Kimch'aek"],
  ['KPSGN', "Kyomip'o"],
  ['KPRJN', 'Najin'],
  ['MPROP', 'Rota'],
  ['MPTIQ', 'Tinian'],
  ['NOPOR', 'Porsgrunn'],
  ['NOMAN', 'Mandal'],
  ['NOGUS', 'Mo i Rana'],
  ['NOSVV', 'Svelvik'],
  ['NOVAK', 'Vaksdal'],
  ['NONVK', 'Narvik'],
  ['NOKAR', 'Karsto'],
  ['NOHUS', 'Husnes'],
  ['NOOKF', 'Oksfjord'],
  ['NOSJV', 'Sjovegan'],
  ['NOBNN', 'Bronnoysund'],
  ['NOHVG', 'Honningsvag'],
  ['NOMOS', 'Mosterhamn'],
  ['NOTAE', 'Tananger'],
  ['NOODD', 'Odda'],
  ['NOGBD', 'Gibostad'],
  ['NOKVS', 'Kvalsund'],
  ['NOHRS', 'Haroysund'],
  ['NONHS', 'Norheimsund'],
  ['NOLGS', 'Langstein'],
  ['NOBVK', 'Brevik'],
  ['NOLRI', 'Leirvik'],
  ['NODRM', 'Drammen'],
  ['NOBVG', 'Berlevag'],
  ['NOKIR', 'Kirkehamn'],
  ['NOHFT', 'Hammerfest'],
  ['NOLAR', 'Larvik'],
  ['NOMLM', 'Malm'],
  ['NOTRY', 'Tranoy'],
  ['NOHOL', 'Holmestrand'],
  ['NORVK', 'Rorvik'],
  ['NOSAS', 'Sandnes'],
  ['NOLID', 'Lilandsgrunnen'],
  ['NOFFD', 'Flekkefjorden'],
  ['NOSSJ', 'Sandnessjoen'],
  ['NOKJF', 'Kjollefjorden'],
  ['NOLOD', 'Lodingen'],
  ['NOLEV', 'Levanger'],
  ['NOTOF', 'Tofte'],
  ['NOFNE', 'Finnsnes'],
  ['NOROG', 'Rognan'],
  ['NOTRD', 'Trondheim'],
  ['NOMLT', 'Moldtustranda'],
  ['NOMJF', 'Mosjoen'],
  ['NOHAU', 'Haugesund'],
  ['NOSVG', 'Stavanger'],
  ['NOKMO', 'Kambo'],
  ['NOALF', 'Alta'],
  ['NOKSU', 'Kristiansund'],
  ['NORIS', 'Risor'],
  ['NOSBU', 'Salsbruket'],
  ['NOSKN', 'Stokkmarknes'],
  ['NOMAY', 'Maloy'],
  ['NOREK', 'Rekefjord'],
  ['NOOSY', 'Namsos'],
  ['NOVAL', 'Valloy'],
  ['NOHAL', 'Halden'],
  ['NOFRK', 'Fredrikstad'],
  ['NOTOS', 'Tromso'],
  ['NOORS', 'Orstav'],
  ['NOSRO', 'Skrova Havn'],
  ['NOREI', 'Reine'],
  ['NOHRD', 'Harstad'],
  ['NOSTE', 'Steinkjer'],
  ['NOARE', 'Arendal'],
  ['NOFAU', 'Fauske'],
  ['NOUVK', 'Ulvik'],
  ['NOKRA', 'Kragero'],
  ['NOSUZ', 'Stamsund'],
  ['NOAND', 'Andalsnes'],
  ['NOMOL', 'Molde'],
  ['NOOSL', 'Oslo'],
  ['NONZG', 'Brattholmen'],
  ['NOSUN', 'Sunndalsora'],
  ['NOHOK', 'Hommelvik'],
  ['NOMRV', 'Muruvik'],
  ['NOARD', 'Ardalstangen'],
  ['NODRK', 'Drobak'],
  ['NOTRF', 'Sandefjorden'],
  ['NOKKN', 'Kirkenes'],
  ['NOTON', 'Tonsberg'],
  ['NOBOO', 'Bodo'],
  ['NOSVJ', 'Svolvaer'],
  ['NOSLG', 'Slagenstangen'],
  ['NOBLL', 'Litle Ballangen'],
  ['NOSAU', 'Sauda'],
  ['NOHOR', 'Horten'],
  ['NOMSS', 'Moss'],
  ['NOSKE', 'Skien'],
  ['NOTVE', 'Tvedestrand'],
  ['NOKOP', 'Kopervik'],
  ['NODRG', 'Drag'],
  ['NOMON', 'Mongstad'],
  ['NOVAO', 'Vardo'],
  ['NOKRS', 'Kristiansand'],
  ['NOAES', 'Alesund'],
  ['NOBJF', 'Batsfjorden'],
  ['NOFAX', 'Fagerstrand'],
  ['NOBGO', 'Bergen'],
  ['OMQAL', 'Qalhat Lng Terminal'],
  ['OMSOH', 'Port Of Sohar'],
  ['OMSTQ', 'Mina Qabus'],
  ['OMSLL', 'Mina Raysut'],
  ['OMMFH', 'Mina Al Fahl'],
  ['PKKHI', 'Karachi'],
  ['PKBQM', 'Muhamamad Bin Qasim'],
  ['PKGWD', 'Gwadar'],
  ['PACHG', 'Chiriqui Grande'],
  ['PAPED', 'Pedregal'],
  ['PABOC', 'Bocas Del Toro'],
  ['PABLB', 'Balboa'],
  ['PAPAM', 'Almirante'],
  ['PACTB', 'Puerto Cristobal'],
  ['PAMNP', 'Bahia De Las Minas'],
  ['PACHA', 'Charco Azul'],
  ['PAAML', 'Puerto Armuelles'],
  ['PGLOR', 'Lorengau'],
  ['PGSQT', 'Samarai'],
  ['PGMAG', 'Madang'],
  ['PGPOM', 'Port Moresby'],
  ['PGLAE', 'Lae'],
  ['PGWWK', 'Wewak Harbor'],
  ['PGVAI', 'Vanimo'],
  ['PGRAB', 'Rabaul'],
  ['PGROR', 'Oro Bay'],
  ['PGKUL', 'Kumul'],
  ['PGKIE', 'Kieta'],
  ['PGGUR', 'Alotoa'],
  ['PELOB', 'Caleta Lobitos'],
  ['PECHM', 'Puerto De Chimbote'],
  ['PEPMT', 'Pimental'],
  ['PELPP', 'La Pampilla Oil Terminal'],
  ['PEPLO', 'Punta Lobitos (Bahia De Huarmey)'],
  ['PESNX', 'Bahia San Nicolas'],
  ['PECAB', 'Puerto Cabo Blanco'],
  ['PECHY', 'Chancay'],
  ['PESUP', 'Puerto Supe'],
  ['PEMRI', 'Bahia De Matarani'],
  ['PECLL', 'Puerto Del Callao'],
  ['PEPUB', 'Puerto Bayovar'],
  ['PEILQ', 'Puerto Ilo'],
  ['PEHCO', 'Puerto De Huacho'],
  ['PESVY', 'Salaverry'],
  ['PEPAI', 'Paita'],
  ['PHHNT', 'Hinatuan'],
  ['PHPLC', 'Polloc (Cotabato)'],
  ['PHMSC', 'Masinloc'],
  ['PHBSO', 'Basco'],
  ['PHORC', 'Ormoc'],
  ['PHMBT', 'Masbate'],
  ['PHBUG', 'Bugo'],
  ['PHDVO', 'Davao'],
  ['PHMGG', 'Mangagoy'],
  ['PHNAS', 'Nasipit Port'],
  ['PHJNZ', 'Jimenez'],
  ['PHILO', 'Iloilo'],
  ['PHIGN', 'Iligan'],
  ['PHBGV', 'Port Borongan'],
  ['PHROM', 'Port Romblon'],
  ['PHVRC', 'Virac'],
  ['PHPPS', 'Puerto Princesa'],
  ['PHOZC', 'Port Ozamis'],
  ['PHTBC', 'Tabaco'],
  ['PHDGT', 'Dumaguete'],
  ['PHPAG', 'Pagadian'],
  ['PHAPR', 'Aparri'],
  ['PHISL', 'Isabel'],
  ['PHCLV', 'Claveria'],
  ['PHSSV', 'Siasi'],
  ['PHBYB', 'Baybay'],
  ['PHBTG', 'Batangas City'],
  ['PHPHD', 'Port Holland'],
  ['PHMVS', 'Mariveles'],
  ['PHVNV', 'Villanueva'],
  ['PHSFE', 'San Fernando Harbor'],
  ['PHTAG', 'Tagbilaran'],
  ['PHCEB', 'Cebu'],
  ['PHCLP', 'Calapan'],
  ['PHCGY', 'Cagayan De Oro'],
  ['PHJPM', 'Jose Panganiban'],
  ['PHGES', 'General Santos'],
  ['PHMXI', 'Mati'],
  ['PHLZB', 'Lazi'],
  ['PHSCA', 'San Carlos'],
  ['PHGUN', 'Guiuan'],
  ['PHLGP', 'Legazpi Port'],
  ['PHMAS', 'Masao'],
  ['PHSUG', 'Surigao City'],
  ['PHSNG', 'Toledo'],
  ['PHCTS', 'Catbalogan'],
  ['PHGIN', 'Gingoog'],
  ['PHHON', 'Hondagua'],
  ['PHBAI', 'Bais'],
  ['PHNUG', 'Nasugbu'],
  ['PHTAC', 'Tacloban'],
  ['PHPPN', 'Palompon'],
  ['PHSFS', 'Subic Bay'],
  ['PHBCT', 'Basilian City (Isabela)'],
  ['PHPUL', 'Pulupandan'],
  ['PHMNL', 'Manila'],
  ['PHMAA', 'Maasin'],
  ['PHZAM', 'Zamboanga'],
  ['PHJOL', 'Jolo'],
  ['PLUST', 'Ustka'],
  ['PLNWA', 'Nowy Port'],
  ['PLDAR', 'Darlowo'],
  ['PLKOL', 'Kolobrzeg'],
  ['PLGDN', 'Gdansk'],
  ['PLSZZ', 'Szczecin'],
  ['PLSWI', 'Swinoujscie'],
  ['PLGDY', 'Gdynia'],
  ['PLPLC', 'Police'],
  ['PTVDP', 'Vila Do Porto'],
  ['PTSET', 'Setubal'],
  ['PTLIS', 'Lisboa'],
  ['PTSIE', 'Sines'],
  ['PTDOU', 'Oporto'],
  ['PTFDF', 'Figueira Da Foz'],
  ['PTAVE', 'Aveiro'],
  ['PTLEI', 'Porto De Leixoes'],
  ['PTVNC', 'Vila Real De S Antonio'],
  ['PTPDL', 'Ponta Delgada'],
  ['PTLDP', 'Lajes'],
  ['PTADH', 'Angra Do Heroismo'],
  ['PTFAO', 'Faro'],
  ['PTFNC', 'Funchal'],
  ['PTHOR', 'Horta'],
  ['PTVDC', 'Viana Do Castelo'],
  ['PTPRV', 'Praia De Vitoria'],
  ['PRARE', 'Arecibo'],
  ['PRSJU', 'San Juan'],
  ['PRPYA', 'Puerto Yabucoa'],
  ['PRGUX', 'Playa De Guanica'],
  ['PRARR', 'Arroyo'],
  ['PRHUC', 'Playa De Humacao'],
  ['PRPSE', 'Playa De Ponce'],
  ['PRGUY', 'Playa De Guayanilla'],
  ['PRBQN', 'Aguadilla'],
  ['PRLAM', 'Las Mareas'],
  ['QAASN', 'Al Shaheen Terminal'],
  ['QARLF', 'Ras Laffan'],
  ['QARYN', 'Al Rayyan Terminal'],
  ['QAHAL', 'Jazirat Halul'],
  ['QADOH', 'Doha'],
  ['QAUMS', 'Umm Said'],
  ['RERUN', 'Saint-Denis'],
  ['REZSE', 'Saint-Pierre'],
  ['ROMAG', 'Mangalia'],
  ['ROBRA', 'Braila'],
  ['ROMID', 'Midia'],
  ['ROCND', 'Constanta'],
  ['ROGAL', 'Galati'],
  ['ROSUL', 'Sulina'],
  ['RUKAN', 'Kandalaksha'],
  ['RUMAG', 'Bukhta Nagayeva (Magadan)'],
  ['RUOKA', 'Okha'],
  ['RUDUD', 'Dudinka'],
  ['RUTUA', 'Tuapse'],
  ['RUVVO', 'Vladivostok'],
  ['RUPKC', 'Petropavlovsk'],
  ['RUIAA', 'Igarka'],
  ['RUIKS', 'Tiksi'],
  ['RUDKA', 'De Kastri'],
  ['RUVIT', 'Vitino'],
  ['RUMMK', 'Murmansk'],
  ['RUPVS', 'Provideniya'],
  ['RUKHO', 'Kholmsk'],
  ['RUVYG', 'Vyborg'],
  ['RUSAK', 'Aleksandrovsk -Sakhalinskiy'],
  ['RULES', 'Lesogorsk'],
  ['RUVNN', 'Bukhta Vanino'],
  ['RUGRH', 'Ostrovnoy Gremikha'],
  ['RUAAQ', 'Anapa'],
  ['RUOHO', 'Okhotsk Iy Reyd'],
  ['RUPSE', "Pos'yet"],
  ['RUPRI', 'Primorsk'],
  ['RUBLT', 'Baltiysk'],
  ['RUBER', 'Port Beringovsky'],
  ['RUNEV', 'Nevelsk'],
  ['RUPWE', 'Pevek'],
  ['RUMVO', 'Moskal Vo'],
  ['RUKOR', 'Korsakov'],
  ['RUKGD', 'Kaliningrad'],
  ['RUZAR', 'Zarubino'],
  ['RUVYP', 'Vostochnyy'],
  ['RUSHA', 'Shakhtersk'],
  ['RUKDT', 'Kronshtadt'],
  ['RUAER', 'Sochi'],
  ['RUOKT', 'Mys Rogatyy Oktyabrskiy'],
  ['RUSKA', 'Slavyanka'],
  ['RUARH', "Arkhangels'k"],
  ['RUGDZ', 'Gelendzhik'],
  ['RUVYS', 'Gavan Vysotsk'],
  ['RUUGL', 'Uglegorsk'],
  ['RUSEW', 'Severodvinsk'],
  ['RUSOG', 'Sovetskaya Gavan'],
  ['RUNIA', 'Nikolayevsk Na Amur'],
  ['RULED', 'Sankt-Peterburg'],
  ['RUNJK', 'Nakhodka'],
  ['RULAZ', 'Lazarev'],
  ['RUULU', 'Reka Luga'],
  ['RUNVS', 'Novorossiysk'],
  ['RUDKS', 'Port Dikson'],
  ['RUPRN', 'Poronaysk'],
  ['SHASI', 'Georgetown'],
  ['SHSHN', 'Jamestown'],
  ['KNBAS', 'Basseterre'],
  ['KNCHA', 'Charlestown'],
  ['LCCAS', 'Castries'],
  ['LCVIF', 'Vieux Fort'],
  ['PMFSP', 'Port De St Pierre'],
  ['VCKTN', 'Kingstown'],
  ['WSAPW', 'Apia'],
  ['STSAA', 'Santo Antonio'],
  ['SAJED', 'Jiddah'],
  ['SADMM', 'Dammam'],
  ['SARAR', 'Ras Al Khafji'],
  ['SARAB', 'Rabigh'],
  ['SARTA', 'Ras  Tannurah'],
  ['SAJUT', 'Ju Aymah Oil Terminal'],
  ['SARAM', 'Ras Al Mishab'],
  ['SAGIZ', 'Jizan'],
  ['SAJBI', 'Al Jubayl'],
  ['SNMBA', 'M Bao Oil Terminal'],
  ['SNXLS', 'St Louis'],
  ['SNDKR', 'Dakar'],
  ['SCPOV', 'Victoria'],
  ['SLPEP', 'Pepel'],
  ['SLBTE', 'Bonthe'],
  ['SLFNA', 'Freetown'],
  ['SGKEP', 'Keppel - (East Singapore)'],
  ['SGSEB', 'Pulau Sebarok'],
  ['SGJUR', 'Jurong Island'],
  ['SGPUB', 'Pulau Bukom'],
  ['SXPHI', 'Philipsburg'],
  ['SIKOP', 'Koper'],
  ['SIPIR', 'Piran'],
  ['SBGZO', 'Gizo Harbor'],
  ['SBTLG', 'Tulaghi'],
  ['SBXYA', 'Yandina'],
  ['SBNOR', 'Port Noro'],
  ['SBHIR', 'Honiara'],
  ['SOBSA', 'Boosaaso'],
  ['SOMGQ', 'Muqdisho'],
  ['SOKMU', 'Kismaayo'],
  ['SOMER', 'Marka'],
  ['SOBBO', 'Berbera'],
  ['ZADUR', 'Durban'],
  ['ZASMN', 'Simonstown'],
  ['ZARCB', 'Richards Bay'],
  ['ZAPLZ', 'Port Elizabeth'],
  ['ZAELS', 'East London'],
  ['ZASDB', 'Saldanha Bay'],
  ['ZAMZY', 'Mossel Bay'],
  ['ZACPT', 'Cape Town'],
  ['KRINC', 'Inchon'],
  ['KRPUS', 'Busan'],
  ['KRCHF', 'Chinae'],
  ['KRPTK', 'Pyeongtaek Hang'],
  ['KRTGA', 'Tonghae'],
  ['KRKPO', 'Pohang'],
  ['KRKAN', 'Gwangyang Hang'],
  ['KRMUK', 'Mukho'],
  ['KRTSN', 'Daesan Hang'],
  ['KRSHO', "Sokch'o Hang"],
  ['KRMAS', 'Masan'],
  ['KRYOS', 'Yosu'],
  ['KRKUV', 'Kunsan'],
  ['KRUSN', 'Ulsan'],
  ['KRMOK', 'Mokpo'],
  ['ESTAR', 'Tarragona'],
  ['ESVLC', 'Valencia'],
  ['ESALD', 'Puerto De Alcudia'],
  ['ESGAN', 'Puerto De Gandia'],
  ['ESAVS', 'Aviles'],
  ['ESGCS', 'El Grao'],
  ['ESROT', 'Rota'],
  ['ESSAG', 'Sagunto'],
  ['ESLCG', 'La Coruna'],
  ['ESGAR', 'Puerto De Garrucha'],
  ['ESPMI', 'Palma De Mallorca'],
  ['ESBRX', 'Burriana'],
  ['ESFRO', 'Ferrol'],
  ['ESGIJ', 'Gijon'],
  ['ESROS', 'Rosas'],
  ['ESVLG', 'Villanueva Y Geltru'],
  ['ESMPG', 'Marin'],
  ['ESSDR', 'Santander'],
  ['ESALC', 'Alicante'],
  ['ESSCT', 'Santa Cruz De Tenerife'],
  ['ESTOR', 'Torrevieja'],
  ['ESMOT', 'Motril'],
  ['ESSCI', 'San Ciprian'],
  ['ESCAR', 'Cartagena'],
  ['ESCEU', 'Ceuta'],
  ['ESLEI', 'Almeria'],
  ['ESMAH', 'Mahon'],
  ['ESRIB', 'Ribadeo'],
  ['ESSFU', 'San Feliu De Guixols'],
  ['ESIBZ', 'Ibiza'],
  ['ESBRM', 'Bermeo'],
  ['ESALG', 'Algeciras'],
  ['ESACE', 'Arrecife'],
  ['ESCAD', 'Cadiz'],
  ['ESAGP', 'Malaga'],
  ['ESLPA', 'Las Palmas'],
  ['ESBCN', 'Barcelona'],
  ['ESFUE', 'Puerto Del Rosario'],
  ['ESPAL', 'Palamos'],
  ['ESCRS', 'Puerto De Carboneras'],
  ['ESBIO', 'Puerto De Bilbao'],
  ['ESAGU', 'Aguilas'],
  ['ESPAS', 'Puerto De Pasajes'],
  ['ESSPC', 'Santa Cruz De La Palma'],
  ['ESADR', 'Adra'],
  ['ESVGO', 'Vigo'],
  ['ESHUV', 'Huelva'],
  ['LKGAL', 'Galle Harbor'],
  ['LKKNK', 'Kankesanturai'],
  ['LKTRR', 'Trincomalee Harbor'],
  ['LKCMB', 'Colombo'],
  ['SDPZU', 'Port Sudan'],
  ['SRPRM', 'Paranam'],
  ['SJLYR', 'Longyearbyen'],
  ['SJBAR', 'Barentsburg'],
  ['SJNYA', 'Ny Alesund'],
  ['SEKAN', 'Karlshamn'],
  ['SELYS', 'Lysekil'],
  ['SESMD', 'Stromstad'],
  ['SEUTA', 'Utansjo'],
  ['SEMMA', 'Malmo'],
  ['SESDL', 'Sundsvall'],
  ['SEBRO', 'Brofjorden'],
  ['SEOER', 'Ornskoldsvik'],
  ['SEFJA', 'Fjallbacka'],
  ['SEDOM', 'Domsjo'],
  ['SEKXV', 'Karlsborg'],
  ['SEVER', 'Verkeback'],
  ['SENRK', 'Norrkoping'],
  ['SELLA', 'Lulea'],
  ['SEFIG', 'Figeholm'],
  ['SESOO', 'Soderhamn'],
  ['SEYST', 'Ystad'],
  ['SEKLR', 'Kalmar'],
  ['SEVST', 'Vasteras'],
  ['SEHEL', 'Helsingborg'],
  ['SESTK', 'Stockvik'],
  ['SEGUS', 'Gustavsvik'],
  ['SEKAA', 'Karlskrona'],
  ['SEHUN', 'Hunnebostrand'],
  ['SEHND', 'Harnosand'],
  ['SEHUS', 'Husum'],
  ['SEMSD', 'Marstrand'],
  ['SEHAN', 'Hargshamn'],
  ['SEVIV', 'Vivstavarv'],
  ['SEVAL', 'Vallvik'],
  ['SEPIT', 'Pitea'],
  ['SEHAD', 'Halmstad'],
  ['SEBLD', 'Bovallstrand'],
  ['SEVAJ', 'Vaja'],
  ['SEKMH', 'Kopmanholmen'],
  ['SEVBY', 'Visby'],
  ['SESUS', 'Storugns'],
  ['SEKRF', 'Kramfors'],
  ['SEHUV', 'Hudiksvall'],
  ['SEVAG', 'Varberg'],
  ['SEGOT', 'Goteborg'],
  ['SESTA', 'Stocka'],
  ['SELAA', 'Landskrona'],
  ['SEJAT', 'Stora Jatterson'],
  ['SETRG', 'Trelleborg'],
  ['SEUDD', 'Uddevalla'],
  ['SEGUB', 'Gustavsberg'],
  ['SEDEG', 'Degerhamn'],
  ['SESTE', 'Stenungsund'],
  ['SETOE', 'Torehamn'],
  ['SESOE', 'Sodertalje'],
  ['SEOXE', 'Oxelosund'],
  ['SEBOL', 'Bollstabruk'],
  ['SESSR', 'Skutskar'],
  ['SEVVK', 'Vastervik'],
  ['SELIM', 'Limhamn'],
  ['SESAE', 'Sandarne'],
  ['SESIM', 'Simrishamn'],
  ['SEGRH', 'Grisslehamn'],
  ['SEGVX', 'Gavle'],
  ['SERNB', 'Ronneby'],
  ['SEUME', 'Umea Hamn'],
  ['SENYN', 'Nynashamn'],
  ['SEOSK', 'Oskarshamn'],
  ['SEBEA', 'Bergkvara'],
  ['SEGRE', 'Grebbestad'],
  ['SESOL', 'Solvesborg'],
  ['SENOT', 'Norrsundet'],
  ['SEKAS', 'Karskar'],
  ['SESOR', 'Soraker'],
  ['SEOBB', 'Obbola'],
  ['SESKE', 'Skelleftehamn'],
  ['SESTO', 'Stockholm'],
  ['SEKLI', 'Klintehamn'],
  ['SEIGG', 'Iggesund'],
  ['SYTTS', 'Tartus'],
  ['SYBAN', 'Baniyas'],
  ['SYALD', 'Al Ladhiqiyah'],
  ['TWTXG', 'Tai-Chung Kang'],
  ['TWMLI', 'Mailiao'],
  ['TWMZG', 'Peng-Hu Kang'],
  ['TWHUN', 'Hua-Lien Kang'],
  ['TWTPE', 'Tan-Shui'],
  ['TWKEL', 'Chi-Lung'],
  ['TWKHH', 'Kao-Hsiung'],
  ['TZZNZ', 'Zanzibar'],
  ['TZKIK', 'Kilwa Kivinje'],
  ['TZTGT', 'Tanga'],
  ['TZMIK', 'Mikindani'],
  ['TZDAR', 'Dar Es Salaam'],
  ['THSRI', 'Si Racha Terminal'],
  ['THSGK', 'Songkhla Harbor'],
  ['THLCH', 'Laem Chabang'],
  ['THBSP', 'Bang Saphan'],
  ['THKSI', 'Ko Si Chang Terminal'],
  ['THERA', 'Erawan Terminal'],
  ['THKHA', 'Khanom'],
  ['THPKT', 'Phuket'],
  ['THTTE', 'Tantawan Marine Terminal'],
  ['THBEN', 'Benchamas Terminal'],
  ['THSAT', 'Sattahip'],
  ['THBKK', 'Bangkok'],
  ['THPTN', 'Pattani'],
  ['THKRV', 'Krabi'],
  ['THSRI', 'Si Racha'],
  ['BSSRP', 'South Riding Point'],
  ['BSNAS', 'Nassau'],
  ['BSCBT', 'Cockburn Town'],
  ['BSFPO', 'Freeport'],
  ['BSMAT', 'Matthew Town'],
  ['BSCLP', 'Clifton Pier'],
  ['GMBJL', 'Banjul'],
  ['TLDIL', 'Dili'],
  ['TGKPE', 'Kpeme'],
  ['TGLFW', 'Lome'],
  ['TOPAN', 'Pangai'],
  ['TONEI', 'Neiafu'],
  ['TOTBU', 'Nuku Alofa'],
  ['TTPTF', 'Point Fortin'],
  ['TTCHA', 'Chaguaramas'],
  ['TTSCA', 'Scarborough'],
  ['TTPTP', 'Pointe A Pierre'],
  ['TTLAB', 'Brighton'],
  ['TTTEM', 'Anse Tembladora'],
  ['TTPOS', 'Port Of Spain'],
  ['TNGAE', 'Gabes'],
  ['TNBIZ', 'Banzart'],
  ['TNSFA', 'Mersa Sfax'],
  ['TNASH', 'Ashtart Oil Terminal'],
  ['TNTUN', 'Tunis'],
  ['TNLGN', 'La Goulette'],
  ['TNLSK', 'Skhira'],
  ['TNSUS', 'Sousse'],
  ['TNDID', 'Didon Terminal'],
  ['TRYAR', 'Yarimca'],
  ['TRGEM', 'Gemlik'],
  ['TRSIC', 'Sinop'],
  ['TRIZT', 'Izmit'],
  ['TRGIR', 'Giresun'],
  ['TRNEM', 'Nemrut Limani Bay'],
  ['TRTGT', 'Toros Gubre'],
  ['TRTEK', 'Tekirdag'],
  ['TRZON', 'Zonguldak'],
  ['TRGOK', 'Golcuk Burnu'],
  ['TRERK', 'Erdek'],
  ['TRITY', 'Istinye'],
  ['TRUZE', 'Antalya Offshore Terminal'],
  ['TRIST', 'Istanbul'],
  ['TRGEB', 'Gebze'],
  ['TRGEL', 'Gelibolu'],
  ['TRKRB', 'Karabiga'],
  ['TRMUD', 'Mudanya'],
  ['TRALI', 'Aliaga'],
  ['TRSSX', 'Samsun'],
  ['TRTAS', 'Tasucu'],
  ['TRFET', 'Fethiye'],
  ['TRIZM', 'Izmir'],
  ['TRALA', 'Alanya'],
  ['TRHOP', 'Hopa'],
  ['TRDIK', 'Dikili'],
  ['TRAYT', 'Antalya'],
  ['TRAYV', 'Ayvalik'],
  ['TRYAK', 'Yakacik'],
  ['TRERE', 'Eregli'],
  ['TRECE', 'Eceabat'],
  ['TRMRM', 'Marmaris'],
  ['TRHAY', 'Haydarpasa'],
  ['TRKUS', 'Kusadasi'],
  ['TRBXN', 'Bodrum'],
  ['TRBDM', 'Bandirma'],
  ['TRCKZ', 'Canakkale'],
  ['TRORD', 'Ordu'],
  ['TRBOT', 'Botas Dortyol Oil Terminal'],
  ['TRDRC', 'Derince Burnu'],
  ['TRTZX', 'Trabzon'],
  ['TRGUL', 'Gulluk'],
  ['TRRIZ', 'Rize'],
  ['TCGDT', 'Grand Turk'],
  ['TVFUN', 'Funafuti Atoll'],
  ['VIPAX', 'Port Alucroix'],
  ['VIFRD', 'Frederiksted'],
  ['UAZKA', 'Yevpatoriya'],
  ['UAKEH', 'Kerch'],
  ['UAYZE', 'Yuzhnyy'],
  ['UARNI', 'Reni'],
  ['UANLV', 'Mykolayiv'],
  ['UAYAL', 'Yalta'],
  ['UAFEO', 'Feodosiya'],
  ['UAODS', 'Odesa'],
  ['UASVP', 'Sevastopol'],
  ['UAILK', 'Illichivsk'],
  ['UAOCT', 'Port Oktyabrsk'],
  ['UAERD', 'Berdyansk'],
  ['UAKHE', 'Kherson'],
  ['UABGD', 'Belgorod-Dnestrovsky'],
  ['UAUDY', 'Ust-Dunaisk'],
  ['UAIZM', 'Izmayil'],
  ['UAILK', 'Chornomors Ke'],
  ['AEDXB', 'Dubayy'],
  ['AEFAT', 'Fateh Oil Terminal'],
  ['AEJEA', 'Mina Jabal Ali'],
  ['AEMBS', 'Mubarraz Oil Terminal'],
  ['AEAUH', 'Abu Zaby'],
  ['AEKLF', 'Khawr Fakkan'],
  ['AEMSA', 'Mina Saqr'],
  ['AEAJM', 'Ajman'],
  ['AEJED', 'Jabal Az Zannah/ruways'],
  ['AEJAZ', 'Al Jazeera Port'],
  ['AEHAM', 'Al Hamriyah Lpg Terminal'],
  ['AEHTL', 'Hulaylah Oil Terminal'],
  ['AEQIW', 'Umm Al Qaywayn'],
  ['AEDAS', 'Jazirat Das'],
  ['GBSIL', 'Silloth'],
  ['GBGRK', 'Greenock'],
  ['GBFAL', 'Falmouth Harbour'],
  ['GBFAW', 'Fawley Marine Terminal'],
  ['GBWIC', 'Wick'],
  ['GBNPT', 'Newport'],
  ['GBWKO', 'Warkworth Harbour'],
  ['GBLDY', 'Londonderry'],
  ['GBSWY', 'Scalloway'],
  ['GBPLN', 'Port Ellen'],
  ['GBPAR', 'Par'],
  ['GBBID', 'Bideford'],
  ['GBLMA', 'Lochmaddy'],
  ['GBLAN', 'Lancaster'],
  ['GBCNU', 'Craignure'],
  ['GBBEL', 'Belfast'],
  ['GBGLW', 'Glasgow'],
  ['GBLON', 'London'],
  ['GBSWA', 'Swansea'],
  ['GBPLY', 'Plymouth'],
  ['GBMCD', 'Macduff'],
  ['GBTOB', 'Tobermory'],
  ['GBNHV', 'Newhaven Harbour'],
  ['GBDUN', 'Dundee'],
  ['GBSOU', 'Southampton'],
  ['GBLAH', 'Lamlash Harbor'],
  ['GBSHS', 'Sheerness'],
  ['GBGRN', 'Granton'],
  ['GBSSS', 'Sharpness'],
  ['GBCDF', 'Cardiff'],
  ['GBSUN', 'Sunderland'],
  ['GBBUH', 'Burghead'],
  ['GBTHP', 'Thamesport'],
  ['GBWTB', 'Whitby'],
  ['GBULL', 'Ullapool'],
  ['GBBUC', 'Buckie Harbor'],
  ['GBCBT', 'Campbeltown'],
  ['GBCRN', 'Cromarty'],
  ['GBPTB', 'Port Talbot'],
  ['GBNEA', 'Neath'],
  ['GBLBD', 'Lochboisdale'],
  ['GBHLY', 'Holyhead'],
  ['GBKLK', 'Kilkeel'],
  ['GBFWM', 'Fort William'],
  ['GBHPT', 'Hound Point Terminal'],
  ['GBOBA', 'Oban'],
  ['GBLGS', 'Largs'],
  ['GBINV', 'Inverness'],
  ['GBFOL', 'Folkestone Harbour'],
  ['GBPAD', 'Padstow'],
  ['GBBAD', 'Barry'],
  ['GBHST', 'Hunterston'],
  ['GBNYL', 'Newlyn'],
  ['GBRAY', 'Rothesay Harbor'],
  ['GBKWL', 'Kirkwall'],
  ['GBKLR', 'Kilroot'],
  ['GBROY', 'Rosyth'],
  ['GBAGL', 'Ardglass'],
  ['GBGAR', 'Gairloch'],
  ['GBPRH', 'Portland Harbour'],
  ['GBPME', 'Portsmouth Harbour'],
  ['GBHTP', 'Hartlepool'],
  ['GBLSS', 'Lossiemouth'],
  ['GBBTL', 'Burntisland'],
  ['GBFIS', 'Fishguard'],
  ['GBPIE', 'Pierowall'],
  ['GBPOO', 'Poole Harbour'],
  ['GBSCF', 'Scapa Bay'],
  ['GBSTO', 'Stornoway Harbor'],
  ['GBARD', 'Ardrossan'],
  ['GBCHF', 'Charlestown'],
  ['GBIPS', 'Ipswich'],
  ['GBHRW', 'Harwich'],
  ['GBMLF', 'Milford Haven'],
  ['GBLAR', 'Larne'],
  ['GBSNS', 'Stromness'],
  ['GBDTM', 'Dartmouth Harbour'],
  ['GBBRT', 'Bridlington'],
  ['GBAVO', 'Avonmouth'],
  ['GBLYS', 'Lyness'],
  ['GBWEY', 'Weymouth Harbour'],
  ['GBIVG', 'Invergordon'],
  ['GBBWK', 'Berwick-Upon-Tweed'],
  ['GBBRW', 'Bridgwater'],
  ['GBBRX', 'Brixham Harbour'],
  ['GBWPT', 'Warren Point'],
  ['GBBAN', 'Bangor'],
  ['GBCLR', 'Coleraine'],
  ['GBMTH', 'Methil'],
  ['GBLOW', 'Lowestoft'],
  ['GBTYM', 'Tynemouth'],
  ['GBTOR', 'Torquay Harbour'],
  ['GBWAT', 'Watchet Harbor'],
  ['GBSHO', 'Shoreham Harbour'],
  ['GBLER', 'Lerwick'],
  ['GBBIF', 'Barrow In Furness'],
  ['GBKYL', 'Kyle Of Lochalsh'],
  ['GBPEN', 'Penzance'],
  ['GBKYN', 'Kyleakin'],
  ['GBCOR', 'Corpach'],
  ['GBABD', 'Aberdeen'],
  ['GBCTM', 'Chatham Docks'],
  ['GBYMO', 'Yarmouth Harbour'],
  ['GBCBA', 'Castle Bay'],
  ['GBTIL', 'Tilbury'],
  ['GBUIG', 'Uig'],
  ['GBTNM', 'Teignmouth Harbour'],
  ['GBAYR', 'Ayr'],
  ['GBCOW', 'Cowes Harbour'],
  ['GBMNC', 'Manchester'],
  ['GBBRP', 'Bridport Harbour'],
  ['GBSCR', 'Scrabster Harbor'],
  ['GBLEI', 'Leith'],
  ['GBMON', 'Montrose'],
  ['GBAUL', 'Aultbea'],
  ['GBGTY', 'Great Yarmouth'],
  ['GBFLE', 'Fleetwood'],
  ['GBTAR', 'Tarbert'],
  ['GBFNT', 'Finnart Oil Terminal'],
  ['GBBLY', 'Blyth'],
  ['GBASG', 'Ardrishaig'],
  ['GBGSA', 'Glensanda'],
  ['GBDVR', 'Dover Harbor'],
  ['GBLOL', 'Lochaline'],
  ['GBSEA', 'Seaham'],
  ['GBFXT', 'Felixstowe'],
  ['GBBOS', 'Boston'],
  ['GBMLP', 'Millport'],
  ['GBGVS', 'Gravesend'],
  ['GBDBR', 'Dunbar'],
  ['GBHYM', 'Heysham'],
  ['GBPHD', 'Peterhead'],
  ['GBGIS', 'Garlston'],
  ['GBIOG', 'Isle Of Grain'],
  ['GBFOY', 'Fowey Harbour'],
  ['GBLIV', 'Liverpool'],
  ['GBSCA', 'Scarborough'],
  ['GBPRT', 'Portree Harbor'],
  ['GBFRB', 'Fraserburgh Harbor'],
  ['GBBDF', 'Broadford Bay'],
  ['GBHAY', 'Hayle'],
  ['GBTEE', 'Teesport'],
  ['GBWOR', 'Workington'],
  ['GBBRS', 'Bristol'],
  ['GBCHE', 'Mostyn'],
  ['USSIT', 'Sitka'],
  ['USIS3', 'City Island'],
  ['USZIP', 'Elizabethport'],
  ['USALG', 'Algonac'],
  ['USCHT', 'Chester'],
  ['USCSX', 'Charlevoix'],
  ['USANP', 'Annapolis'],
  ['USUAC', 'Bristol'],
  ['USBRO', 'Brownsville'],
  ['USSAN', 'San Diego'],
  ['USFNR', 'Funter'],
  ['USCOB', 'Coos Bay'],
  ['USILG', 'Wilmington'],
  ['USSEM', 'Salem Nj'],
  ['USGPT', 'Gulfport'],
  ['USSAV', 'Savannah'],
  ['USACG', 'Catskill'],
  ['USSVA', 'Savoonga'],
  ['USPDX', 'Portland'],
  ['USVAN', 'Vancouver'],
  ['USCLE', 'Cleveland'],
  ['USPRJ', 'Port Royal'],
  ['USPB5', 'Pismo Beach'],
  ['USBRW', 'Barrow'],
  ['US8BY', 'Bayway'],
  ['USSBM', 'Sheboygan'],
  ['USBFI', 'Bayfield'],
  ['USPET', 'Petoskey'],
  ['USSRU', 'Santa Cruz'],
  ['USOGS', 'Ogdensburg'],
  ['USWRG', 'Wrangell'],
  ['USBPT', 'Beaumont'],
  ['USSWD', 'Seward'],
  ['USTHB', 'Two Harbors'],
  ['USUGN', 'Waukegan'],
  ['USSUE', 'Sturgeon Bay'],
  ['USCLY', 'Clayton'],
  ['USPTL', 'Port Armstrong'],
  ['USNSS', 'Sabine Pass'],
  ['USPAY', 'Perth Amboy'],
  ['USMRH', 'Morehead City'],
  ['USDET', 'Detroit'],
  ['USDZT', 'Mount Desert'],
  ['USEGW', 'Edgewater'],
  ['USSSM', 'Sault Ste Marie'],
  ['USPSL', 'Port San Luis'],
  ['USTVC', 'Traverse City'],
  ['USMOY', 'Marinette'],
  ['USGYY', 'Gary'],
  ['USCDV', 'Cordova'],
  ['USRKD', 'Rockland'],
  ['USOLM', 'Olympia'],
  ['USTIW', 'Tacoma'],
  ['USHB4', 'Harbor Beach'],
  ['USFPO', 'Freeport'],
  ['USKIB', 'Ivanof Bay'],
  ['USNYC', 'New York City'],
  ['USJNU', 'Juneau'],
  ['USSGM', 'Saginaw'],
  ['USKIS', 'Kiska'],
  ['USKUA', 'Kaunakakai'],
  ['USAPN', 'Alpena'],
  ['USPTD', 'Port Alexander'],
  ['USMKI', 'Mukilteo'],
  ['USPU2', 'Put In Bay'],
  ['USBDR', 'Bridgeport'],
  ['USANZ', 'Antioch'],
  ['USRCE', 'Roche  Harbor'],
  ['USILM', 'Wilmington'],
  ['USECH', 'Indiana Harbor'],
  ['USWLR', 'Loring'],
  ['USNTD', 'Port Hueneme'],
  ['USESC', 'Escanaba'],
  ['USSOL', 'Solomon'],
  ['USKWF', 'Waterfall'],
  ['USCHS', 'Charleston'],
  ['USCAS', 'Castine'],
  ['USFRD', 'Friday Harbor'],
  ['USPHL', 'Philadelphia'],
  ['USGLS', 'Galveston'],
  ['USEWR', 'Newark'],
  ['USBOS', 'Boston'],
  ['USYKW', 'Yorktown'],
  ['USHNH', 'Hoonah'],
  ['USEL3', 'Ellwood'],
  ['USXNG', 'Trenton'],
  ['USBAL', 'Baltimore'],
  ['USPWM', 'Portland'],
  ['USBAY', 'Bayonne'],
  ['USMKG', 'Muskegon'],
  ['USNNS', 'Newport News'],
  ['USHOU', 'Houston'],
  ['USFPR', 'Fort Pierce'],
  ['USDVV', 'Davisville Depot'],
  ['USHNL', 'Honolulu'],
  ['USTPA', 'Tampa'],
  ['USQAG', 'Algoma'],
  ['USMOB', 'Mobile'],
  ['USLBA', 'Alabaster'],
  ['USNBD', 'New Bedford'],
  ['USWJQ', 'Weehawken'],
  ['USBLI', 'Bellingham'],
  ['USPFN', 'Panama City'],
  ['USKUY', 'Uyak'],
  ['USMAH', 'Marcus Hook'],
  ['USVYH', 'Vineyard Haven'],
  ['USMIA', 'Miami'],
  ['USPWT', 'Bremerton'],
  ['USLOR', 'Lorain'],
  ['USWHO', 'Woods Hole'],
  ['US9NV', 'North Haven'],
  ['USBEL', 'Belfast'],
  ['USOME', 'Nome'],
  ['USKVC', 'King Cove'],
  ['USOL2', 'Oleum'],
  ['USYIG', 'Port Ingleside'],
  ['USEKA', 'Eureka'],
  ['USPON', 'Port Neches'],
  ['USPO2', 'Port Orford'],
  ['US9MP', 'Machiasport'],
  ['USMQT', 'Marquette'],
  ['USANC', 'Anchorage'],
  ['USBUH', 'Burlington'],
  ['USMGS', 'Munising'],
  ['USPSG', 'Petersburg'],
  ['USFMH', 'Falmouth'],
  ['USPAU', 'Paulsboro'],
  ['USEXI', 'Excursion Inlet'],
  ['USOYH', 'Boothbay Harbor'],
  ['USERI', 'Erie'],
  ['USMSY', 'New Orleans'],
  ['US9VV', 'Vinalhaven'],
  ['USMI8', 'Mare Island'],
  ['USHUB', 'Huron'],
  ['USLPW', 'Little Port Walter'],
  ['USLGB', 'Long Beach'],
  ['USJEC', 'Jersey City'],
  ['USSAB', 'Sabine'],
  ['USVNT', 'Ventura'],
  ['USCSF', 'Carteret'],
  ['USSAK', 'Point San Pablo'],
  ['USPTM', 'Portsmouth'],
  ['USLAX', 'Los Angeles'],
  ['USMCD', 'Mackinac Island'],
  ['USNCO', 'Quonset Point'],
  ['USSUL', 'Port Sulphur'],
  ['USGDE', 'Gladstone'],
  ['USPOU', 'Poughkeepsie'],
  ['USITO', 'Hilo'],
  ['USTXT', 'Texas City'],
  ['USLDM', 'Ludington'],
  ['USNNK', 'Naknek'],
  ['USGRB', 'Green Bay'],
  ['USJAX', 'Jacksonville'],
  ['USSSI', 'Brunswick'],
  ['USCRP', 'Corpus Christi'],
  ['USSXX', 'St Joseph'],
  ['USRSF', 'Reserve'],
  ['USRI4', 'Port Richmond SI'],
  ['USKWU', 'Kewaunee'],
  ['USLMO', 'Morgan City'],
  ['USDPW', 'Deepwater Point'],
  ['USTWD', 'Port Townsend'],
  ['USPAL', 'Port Allen'],
  ['USFNF', 'Frankfort'],
  ['USPGL', 'Pascagoula'],
  ['USWSB', 'Steamboat Bay'],
  ['USPXO', 'Port Orchard'],
  ['USDAV', 'Davenport'],
  ['USPRD', 'Port Reading'],
  ['USMQI', 'Quincy'],
  ['USPHN', 'Port Huron'],
  ['USOTH', 'North Bend'],
  ['USHYM', 'Chrome'],
  ['USGRL', 'Great Lakes'],
  ['USLB2', 'Lubec'],
  ['USCAY', 'Calumet Harbor'],
  ['USENW', 'Kenosha'],
  ['USTWR', 'Two Rivers'],
  ['USSKY', 'Sandusky'],
  ['USFEB', 'Fernandina Beach'],
  ['USCDY', 'Conneaut'],
  ['USHOM', 'Homer'],
  ['USACC', 'Raymond'],
  ['USACI', 'Machias'],
  ['USSLM', 'Salem'],
  ['USHP4', 'South Harpswell'],
  ['USETW', 'Edgartown'],
  ['USCP4', 'Cherry Point'],
  ['USOSW', 'Oswego'],
  ['USSHH', 'Shishmaref'],
  ['USNRB', 'Mayport'],
  ['USSFO', 'San Francisco'],
  ['USAXK', 'Adak Naval Air Station'],
  ['USBVY', 'Beverly'],
  ['USNAX', "Barber's Point"],
  ['USPVC', 'Provincetown'],
  ['USWH5', 'Winter Harbor'],
  ['USDEX', 'Deer Park'],
  ['USPGH', 'Port Gamble'],
  ['USSOV', 'Seldovia'],
  ['USNLO', 'New London'],
  ['USPAB', 'Palm Beach'],
  ['USHWI', 'Hawk Inlet'],
  ['USYAK', 'Yakutat'],
  ['USTIV', 'Tiverton'],
  ['USFAV', 'Fall River'],
  ['USSGY', 'Skagway'],
  ['USCDE', 'Camden'],
  ['USWZU', 'Washburn'],
  ['USGTL', 'Gretna'],
  ['USENA', 'Kenai'],
  ['USCRM', 'Crockett'],
  ['USHLM', 'Holland'],
  ['USHBK', 'Hoboken'],
  ['USPSJ', 'Port St Joe'],
  ['USBL3', 'Brooklin'],
  ['USADQ', 'Kodiak'],
  ['USORF', 'Norfolk'],
  ['USBCY', 'Bay City'],
  ['USASX', 'Ashland'],
  ['USHNS', 'Haines'],
  ['USVDZ', 'Valdez Marine Terminal'],
  ['USEPM', 'Eastport'],
  ['USMLG', 'Moss Landing'],
  ['USCLM', 'Port Angeles'],
  ['USPIE', 'St Petersburg'],
  ['USZZM', 'South Amboy'],
  ['USPLV', 'Port Lavaca'],
  ['USBCG', 'Boca Grande'],
  ['USKWH', 'Kawaihae'],
  ['USCEC', 'Crescent City'],
  ['USKA7', 'Kailua'],
  ['USISQ', 'Manistique'],
  ['USKLN', 'Larsen Bay'],
  ['USNEB', 'Neah Bay'],
  ['USMTW', 'Manitowoc'],
  ['USPCV', 'Canaveral Harbor'],
  ['USVLO', 'South Vallejo'],
  ['USPAS', 'Pasadena'],
  ['USPIS', 'Port Isabel'],
  ['USS8B', 'South Bristol'],
  ['USNEW', 'Destrehan'],
  ['USHTL', 'Houghton'],
  ['USSWN', 'Sewaren'],
  ['USNIJ', 'Nawiliwili Bay'],
  ['USHPY', 'Baytown'],
  ['USMBL', 'Manistee'],
  ['USPVD', 'Providence'],
  ['USBOY', 'Brooklyn'],
  ['USPWA', 'Port Washington'],
  ['USPEF', 'Port Everglades'],
  ['USLHN', 'Lahaina'],
  ['USSUW', 'Superior'],
  ['USKYK', 'Karluk'],
  ['USOGG', 'Kahului'],
  ['USRP3', 'Rockport'],
  ['USUST', 'St Augustine'],
  ['USHS3', 'Harbor Springs'],
  ['USSRP', 'Searsport'],
  ['USHNM', 'Hana'],
  ['USPTJ', 'Port Jefferson'],
  ['USW8S', 'Southwest Harbor'],
  ['USSRE', 'St Rose'],
  ['USWDC', 'Ward Cove'],
  ['USJPP', 'La Pointe'],
  ['USPML', 'Port Moller'],
  ['USBTH', 'Bath'],
  ['USOMU', 'Solomons Island'],
  ['USUNK', 'Unalakleet'],
  ['USMH2', 'Marblehead'],
  ['USSEA', 'Seattle'],
  ['USGLC', 'Gloucester'],
  ['USMNM', 'Menominee'],
  ['USWIE', 'Winterport'],
  ['USACJ', 'South Bend'],
  ['USKGT', 'Kingston'],
  ['USAQQ', 'Camden'],
  ['USKQA', 'Akutan Harbor'],
  ['USWYD', 'Wyandotte'],
  ['USDRG', 'Deering'],
  ['USDLH', 'Duluth'],
  ['USXMK', 'Southport'],
  ['USASF', 'Ashtabula'],
  ['USLCH', 'Lake Charles'],
  ['USORG', 'Orange'],
  ['USOTS', 'Anacortes'],
  ['USLO9', 'Loop Terminal'],
  ['USPSM', 'Portsmouth'],
  ['USYON', 'Yonkers'],
  ['USPAE', 'Everett'],
  ['USNPT', 'Newport'],
  ['USCYM', 'Chatham'],
  ['USTTN', 'Trenton'],
  ['USPOA', 'Port Arthur'],
  ['USKOZ', 'Ouzinkie'],
  ['USSBA', 'Santa Barbara'],
  ['USFSP', 'Friendship'],
  ['USOTZ', 'Kotzebue'],
  ['USSWF', 'Newburgh'],
  ['USONG', 'Ontonagon'],
  ['USRER', 'Rensselaer'],
  ['USBTR', 'Baton Rouge'],
  ['USCHI', 'Chicago'],
  ['USALB', 'Albany'],
  ['USTWY', 'Tawas City'],
  ['USDOJ', 'Leonardo'],
  ['USSCK', 'Stockton'],
  ['USBUF', 'Buffalo'],
  ['USNOC', 'Norwich'],
  ['USTOL', 'Toledo'],
  ['USGGE', 'Georgetown'],
  ['UYMVD', 'Montevideo'],
  ['UYFZB', 'Fray Bentos'],
  ['UYCYR', 'Colonia'],
  ['UYJIT', 'Jose Ignacio'],
  ['VULUG', 'Luganville'],
  ['VUVLI', 'Port Vila'],
  ['VECUP', 'Carupano'],
  ['VEPCN', 'Punta Cardon'],
  ['VECLM', 'Catia La Mar'],
  ['VELSP', 'Las Piedras'],
  ['VEPCZ', 'Puerto La Cruz'],
  ['VEPRG', 'Bahia De Pertigalete'],
  ['VECBL', 'Ciudad Bolivar'],
  ['VEMAR', 'Maracaibo'],
  ['VELSV', 'La Salina'],
  ['VEETV', 'El Tabiazo'],
  ['VEGUI', 'Guiria'],
  ['VEJOT', 'Jose Terminal'],
  ['VESLV', 'San Lorenzo'],
  ['VEBOR', 'Puerto Borburata'],
  ['VECUM', 'Cumana (Puerto Sucre)'],
  ['VEPHO', 'Puerto De Hierro'],
  ['VEMIV', 'Puerto Miranda'],
  ['VELAG', 'La Guaira'],
  ['VECAR', 'Caripito'],
  ['VEEGU', 'El Guamache'],
  ['VEGUT', 'Guanta'],
  ['VEPBL', 'Puerto Cabello'],
  ['VEAMY', 'Amuay (Bahia De Amuay)'],
  ['VECMR', 'Puerto Cumarebo'],
  ['VEELP', 'El Palito'],
  ['VECHV', 'Puerto Chichiriviche'],
  ['VNSGN', 'Thanh Ho Chi Minh'],
  ['VNTHO', 'Thanh Hoa'],
  ['VNHPH', 'Hai Phong'],
  ['VNUIH', 'Qui Nhon'],
  ['VNNGT', 'Nghe Tinh'],
  ['VNBNO', 'Vinh Cam Ranh'],
  ['VNNHA', 'Nha Trang'],
  ['VNPQC', 'Duong Dong'],
  ['VNHON', 'Hon Gai'],
  ['VNCPH', 'Cam Pha'],
  ['VNVUT', 'Vung Tau'],
  ['VNDAD', 'Da Nang'],
  ['WFMAU', 'Mata-Utu'],
  ['MAEUN', 'Laayoune'],
  ['EHVIC', 'Ad Dakhla'],
  ['YEBLH', 'Balhaf'],
  ['YESAL', 'Salif'],
  ['YERAI', 'Ras Isa Marine Terminal'],
  ['YEMOK', 'Al Mukha'],
  ['YEHOD', 'Al Ahmadi'],
  ['YEZAM', 'Al Mukalla'],
  ['YEADE', 'Aden'],
  ['YENIS', 'Nishtun'],
];

export default ports;
