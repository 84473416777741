import { Button, Table } from '@lune-fe/lune-ui-lib'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined'
import moment from 'moment'

import { formatToCurrency } from 'utils'
import { DapiInvoice } from 'views/Settings/Billing/Billing'

function InvoiceTableRow({ invoice }: { invoice: DapiInvoice }) {
    return (
        <Table.TableBody>
            <Table.TableRow>
                <Table.TableCell colSize={7}>
                    <ReceiptLongOutlinedIcon sx={{ mr: 3 }} />
                    <span>{moment(invoice.createdAt).format('MMM D, YYYY')}</span>
                </Table.TableCell>
                <Table.TableCell colSize={2} align="right">
                    {invoice.paymentStatus === 'succeeded' ? 'Paid' : 'Not Paid'}
                </Table.TableCell>
                <Table.TableCell colSize={2} alignEnd align="right">
                    {formatToCurrency(
                        (invoice.totalAmountCents / 100).toString(),
                        invoice.currency,
                    )}
                </Table.TableCell>
                <Table.TableCell shrink align="right">
                    <a
                        href={invoice.url ?? undefined}
                        target="_blank"
                        rel="download noreferrer"
                        download="invoice.pdf"
                    >
                        <Button
                            disabled={!invoice.url}
                            /* This will happen very rarely, but needs to be accounted for.
                               PDFs are generated after invoices are created so it is possible that for a moment or two
                               an invoice exists without a url.
                            */
                            variant="text"
                            iconButton
                            leftIcon={<FileDownloadOutlinedIcon />}
                        />
                    </a>
                </Table.TableCell>
            </Table.TableRow>
        </Table.TableBody>
    )
}

const InvoicesList = ({ invoices }: { invoices: DapiInvoice[] }) => {
    return (
        <Table.TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <Table.TableHead>
                    <Table.TableRow>
                        <Table.TableCell colSize={7}>Date</Table.TableCell>
                        <Table.TableCell colSize={2} align="right">
                            Status
                        </Table.TableCell>
                        <Table.TableCell colSize={2} alignEnd align="right">
                            Invoice total
                        </Table.TableCell>
                    </Table.TableRow>
                </Table.TableHead>

                {invoices.map((invoice, index) => (
                    <InvoiceTableRow key={`index-${index}`} invoice={invoice} />
                ))}
            </Table>
        </Table.TableContainer>
    )
}

export default InvoicesList
