import { LuneTheme, Text } from '@lune-fe/lune-ui-lib'
import { Box } from '@mui/material'
import { FC, useEffect, useState } from 'react'

import useBundles from 'hooks/useBundles'
import { formatToCurrency } from 'utils'
import { Allocation } from 'views/BuyOffsets/BuyOffsetsTypes'
import { allocationToRecord, calculateAveragePrice } from 'views/BuyOffsets/BuyOffsetsUtils'

const OrderTotal: FC<{
    total?: string
    allocation?: Allocation[]
}> = ({ total, allocation }) => {
    const { palette } = LuneTheme
    const { bundles } = useBundles()
    const [average, setAverage] = useState<string>('0')

    useEffect(() => {
        if (allocation?.length) {
            const toRecord = allocationToRecord(allocation)
            setAverage(calculateAveragePrice(bundles, toRecord).toString())
        }
    }, [allocation, bundles])

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'flex-end',
                flexDirection: 'column',
            }}
        >
            <Text variant={'body2'} data-testId={'order-total'} sx={{ fontWeight: '700' }}>
                Total {formatToCurrency(total || '0', bundles[0]?.currency)}
            </Text>
            {average && (
                <Text
                    variant={'body3'}
                    data-testId={'order-average'}
                    sx={{
                        color: palette.Grey600,
                        display: 'block',
                        mt: 1 / 2,
                    }}
                >
                    Avg. {formatToCurrency(average, bundles[0]?.currency)} / tCO₂
                </Text>
            )}
        </Box>
    )
}

export default OrderTotal
