import React from 'react';

import currenciesList from './currenciesList';
import Select from './Select';

const CurrencyPicker = ({
  value,
  onChange,
  disabled,
  error,
  placeholder = 'Currency',
  ...rest
}: {
  value: string;
  onChange: (value: string | undefined) => void;
  error?: string;
  placeholder?: string;
  disabled?: boolean;
}): JSX.Element => {
  return (
    <Select
      error={error}
      label={placeholder}
      disabled={disabled}
      items={currenciesList}
      onChange={onChange}
      value={value}
      sx={{ width: 1 }}
      {...rest}
    />
  );
};

export default CurrencyPicker;
