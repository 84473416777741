import {
    BannerMessage,
    Button,
    DragAndDropFileUpload,
    LoadingWrapper,
    LuneTheme,
    MainLayoutContainer,
    Modal,
    Table,
    Text,
    Tooltip,
} from '@lune-fe/lune-ui-lib'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { Box, Divider, Stack } from '@mui/material'
import CSVTemplate from 'assets/logistics_sheet_template_v1.csv'
import { AxiosError } from 'axios'
import fileDownload from 'js-file-download'
import { flatten } from 'lodash'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { FileRejection } from 'react-dropzone'
import { useNavigate, useParams } from 'react-router-dom'

import { notify } from 'bugsnag'
import { downloadLogisticsSheet, uploadLogisticsSheet } from 'endpoints/dapi'
import useLogisticsSheets from 'hooks/useLogisticsSheets'

const FIRST_COL_SIZE = 4
const MAX_SIZE_BYTES = 2097152

const formatDate = (date: string): string => {
    return moment(date).format('ll')
}

const LogisticsSheets = () => {
    const { spacing } = LuneTheme
    const navigate = useNavigate()
    const [isUploading, setUploading] = useState(false)
    const [modal, setModal] = useState<{ subject: string[]; description?: string } | undefined>(
        undefined,
    )
    const ref = React.useRef<HTMLDivElement>(null)

    const { loading, paginatedLogisticsSheets, hasNextPage, fetchNextPage, refetch } =
        useLogisticsSheets()
    const { logisticsSheetId } = useParams<{ logisticsSheetId?: string }>()

    const downloadSheet = async (id: string) => {
        try {
            const data = await downloadLogisticsSheet(id)
            fileDownload(data, `${id}.csv`, 'text/csv')
        } catch (err) {
            if (err instanceof AxiosError && err.status === 404) {
                // swallow, users can change the page url to trigger this error
                return
            }
            throw err
        }
    }

    const logisticsSheets = useMemo(
        () => flatten((paginatedLogisticsSheets?.pages ?? []).map(({ data }) => data)),
        [paginatedLogisticsSheets],
    )

    useEffect(() => {
        if (!logisticsSheetId) {
            return
        }

        downloadSheet(logisticsSheetId)
    }, [logisticsSheetId])

    const onUploadSheet = (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
        if (rejectedFiles.length) {
            setModal({
                subject: ['Upload failed', 'CSV files only, up to 2MB'],
            })

            return
        }

        const formData = new FormData()
        const file = acceptedFiles[0]
        formData.append('sheet', file)
        setUploading(true)
        uploadLogisticsSheet(formData)
            .then(() => {
                setModal({
                    subject: [
                        `Upload complete.`,
                        `Processing your file, we’ll email you when ready.`,
                    ],
                    description: 'Should take a few minutes',
                })

                refetch()
            })
            .catch((err) => {
                if (err.response.status === 400) {
                    if (err.response.data.errors[0].error_code === 'invalid_file_extension') {
                        setModal({
                            subject: ['Upload failed', 'Please upload a CSV file'],
                        })
                        return
                    }
                    if (err.response.data.errors[0].error_code === 'max_file_size_exceeded') {
                        setModal({
                            subject: ['Upload failed', 'Max file size of 2MB exceeded'],
                        })
                        return
                    }
                    if (err.respmnse.data.errors[0].error_code === 'file_format_invalid') {
                        setModal({
                            subject: [
                                'Upload failed',
                                'File format is invalid. Please upload a CSV file.',
                            ],
                        })
                        return
                    }
                }

                setModal({
                    subject: ['Unexpected upload failure'],
                })
                notify(err)
            })
            .finally(() => setUploading(false))
    }

    return (
        <>
            <Modal
                fullWidth
                open={modal !== undefined}
                onClose={() => {}}
                header={
                    <Box>
                        {(modal?.subject ?? []).map((e, i) => (
                            <Text
                                key={i}
                                variant={'h5'}
                                sx={{
                                    textAlign: 'center',
                                }}
                            >
                                {e}
                            </Text>
                        ))}
                    </Box>
                }
                subheader={<Text variant={'body1'}>{modal?.description}</Text>}
                actions={
                    <Button variant={'contained'} onClick={() => setModal(undefined)} wide>
                        OK
                    </Button>
                }
            />
            <MainLayoutContainer
                headerComponent={
                    <Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                mb: 6,
                            }}
                        >
                            <Button
                                variant="text"
                                iconButton
                                leftIcon={<ArrowBackOutlinedIcon />}
                                sx={{
                                    ml: -1.5,
                                }}
                                onClick={() => navigate('/calculate-emissions')}
                            />
                        </Box>
                        <Text variant={'h4'}>Logistics spreadsheet calculations</Text>
                        <Text variant="body3" sx={{ color: `Grey700`, display: 'block' }} mt={1}>
                            Upload your logistics data using our template
                        </Text>
                    </Box>
                }
            >
                <Stack spacing={{ xs: 4, sm: 9, md: 9, lg: 9, xl: 9 }}>
                    <Box sx={{ height: spacing(40) }}>
                        <DragAndDropFileUpload
                            /*
                            // Property 'ref' does not exist on type 'IntrinsicAttributes & { children?: ReactNode; isUploading?: boolean | undefined; } & Pick<HTMLProps<HTMLElement>, PropTypes> & { ...; }'.
                            // @ts-ignore */
                            ref={ref}
                            accept={{ 'text/csv': ['.csv'] }}
                            maxSize={MAX_SIZE_BYTES}
                            isUploading={isUploading}
                            onDrop={onUploadSheet}
                            rejectDescription={`CSV files only, up to 2MB`}
                            description={`Drop your CSV file here`}
                        />
                    </Box>

                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        spacing={1}
                    >
                        <Button
                            leftIcon={<AddOutlinedIcon />}
                            onClick={() => {
                                if (ref.current) {
                                    ref.current.click()
                                }
                            }}
                        >
                            Upload file
                        </Button>
                        <Button
                            href={CSVTemplate}
                            leftIcon={<FileDownloadOutlinedIcon />}
                            variant="outlined"
                            // @ts-ignore
                            download
                        >
                            Download CSV template
                        </Button>
                        <Button
                            /* unfortunately, mui button does not have target in its type definition
                            // @ts-ignore */
                            target="_blank"
                            leftIcon={<AutoStoriesOutlinedIcon />}
                            variant="outlined"
                            href="https://docs.lune.co/guides/logistics-sheets"
                        >
                            Read template guide
                        </Button>
                    </Stack>

                    <BannerMessage
                        message={`Calculations will count towards your tier limit, one per row`}
                    />

                    {logisticsSheets.length > 0 && <Divider />}

                    <LoadingWrapper loading={loading}>
                        {logisticsSheets.length > 0 && (
                            <Table.TableContainer>
                                <Table sx={{ minWidth: 650 }}>
                                    <Table.TableHead>
                                        <Table.TableRow>
                                            <Table.TableCell colSize={FIRST_COL_SIZE}>
                                                Upload date
                                            </Table.TableCell>
                                            <Table.TableCell>Status</Table.TableCell>
                                            <Table.TableCell align="right" alignEnd>
                                                # of calculations
                                            </Table.TableCell>
                                            <Table.TableCell align="right" alignEnd>
                                                # of errors
                                            </Table.TableCell>
                                            <Table.TableCell align="right" alignEnd>
                                                tCO₂
                                            </Table.TableCell>
                                            <Table.TableCell
                                                align="right"
                                                alignEnd
                                            ></Table.TableCell>
                                        </Table.TableRow>
                                    </Table.TableHead>
                                    <Table.TableBody>
                                        {logisticsSheets.map((sheet, i) => (
                                            <Table.TableRow key={i}>
                                                <Table.TableCell colSize={FIRST_COL_SIZE}>
                                                    <DescriptionOutlinedIcon sx={{ mr: 3 }} />
                                                    <span>{formatDate(sheet.createdAt)}</span>
                                                </Table.TableCell>
                                                <Table.TableCell>
                                                    {sheet.processedAt ? 'Completed' : 'Processing'}
                                                </Table.TableCell>
                                                <Table.TableCell align="right" alignEnd>
                                                    {sheet.processedAt
                                                        ? sheet.numCalculations
                                                        : '-'}
                                                </Table.TableCell>
                                                <Table.TableCell align="right" alignEnd>
                                                    {sheet.processedAt ? sheet.numErrors : '-'}
                                                </Table.TableCell>
                                                <Table.TableCell align="right" alignEnd>
                                                    {sheet.processedAt ? sheet.quantity : '-'}
                                                </Table.TableCell>
                                                <Table.TableCell align="right" alignEnd>
                                                    {sheet.processedAt ? (
                                                        <Button
                                                            variant="text"
                                                            iconButton
                                                            rightIcon={<FileDownloadOutlinedIcon />}
                                                            onClick={() => downloadSheet(sheet.id)}
                                                        />
                                                    ) : (
                                                        <Tooltip
                                                            placement="top"
                                                            title="File is being processed"
                                                        >
                                                            <Button
                                                                variant="text"
                                                                disabled
                                                                iconButton
                                                                rightIcon={
                                                                    <FileDownloadOutlinedIcon />
                                                                }
                                                            />
                                                        </Tooltip>
                                                    )}
                                                </Table.TableCell>
                                            </Table.TableRow>
                                        ))}
                                    </Table.TableBody>
                                </Table>
                            </Table.TableContainer>
                        )}
                    </LoadingWrapper>

                    {hasNextPage && (
                        <Button
                            leftIcon={<ArrowDownwardIcon />}
                            variant="outlined"
                            onClick={() => fetchNextPage()}
                        >
                            Load more
                        </Button>
                    )}
                </Stack>
            </MainLayoutContainer>
        </>
    )
}

export default LogisticsSheets
