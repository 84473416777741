import { OrderByEstimate, OrderByQuantity, OrderByValue } from '@lune-climate/lune'
import { ListItemLayout, LuneTheme, Table, Text } from '@lune-fe/lune-ui-lib'
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined'
import Avatar from '@mui/material/Avatar'
import { sortBy } from 'lodash'
import { useMemo } from 'react'

import useBundles from 'hooks/useBundles'
import { formatNumbers } from 'utils'

interface OrderProject {
    name: string
    id: string
    logo?: string
    quantity: string
    bundleName: string
    status: string
}

const CreditTable = ({
    title,
    orderProjects,
}: {
    title: string
    orderProjects: OrderProject[]
}) => {
    const { palette } = LuneTheme
    return (
        <>
            <Text variant={'h6'} sx={{ mb: 4 }}>
                {title}
            </Text>
            <Table.TableContainer sx={{ mb: 9 }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <Table.TableHead>
                        <Table.TableRow>
                            <Table.TableCell colSize={8}>Project</Table.TableCell>
                            <Table.TableCell colSize={4} alignEnd align="right">
                                Volume
                            </Table.TableCell>
                        </Table.TableRow>
                    </Table.TableHead>
                    <Table.TableBody>
                        {orderProjects.map((project) => (
                            <Table.TableRow key={project.id}>
                                <Table.TableCell colSize={8}>
                                    <ListItemLayout
                                        image={
                                            project.logo ? (
                                                <Avatar
                                                    alt={project.name}
                                                    src={project.logo}
                                                    variant={`rounded`}
                                                    sx={{
                                                        width: `48px`,
                                                        height: `48px`,
                                                        borderRadius: `8px`,
                                                    }}
                                                />
                                            ) : (
                                                <PinDropOutlinedIcon
                                                    sx={{
                                                        width: '48px',
                                                        height: '48px',
                                                        color: palette.Grey900,
                                                    }}
                                                />
                                            )
                                        }
                                        title={project.name}
                                        subTitle={project.bundleName}
                                    />
                                </Table.TableCell>
                                <Table.TableCell colSize={4} alignEnd align="right">
                                    <Text variant={'body2'}>
                                        {formatNumbers(project.quantity)} tCO₂
                                    </Text>
                                </Table.TableCell>
                            </Table.TableRow>
                        ))}
                    </Table.TableBody>
                </Table>
            </Table.TableContainer>
        </>
    )
}

const RetirementSummaryTable = ({
    order,
}: {
    order: OrderByQuantity | OrderByValue | OrderByEstimate
}) => {
    const { bundles } = useBundles()
    const orderBundles = useMemo(() => {
        return bundles.filter((b) =>
            order.bundles?.some((orderBundle) => orderBundle.bundleId === b.id),
        )
    }, [order, bundles])

    const orderProjects: OrderProject[] = useMemo(() => {
        const projects =
            order.projects?.map((project) => {
                const bundle = orderBundles.find((bundle) =>
                    bundle.projects.some((p) => p.id === project.projectId),
                )

                return {
                    name: project.projectName,
                    id: project.projectId,
                    logo: project.projectLogo ?? undefined,
                    quantity: project.quantity,
                    bundleName: bundle?.name || '',
                    status: project.status,
                }
            }) || []
        return sortBy(projects, (project: OrderProject) => project.name)
    }, [order.projects, orderBundles])

    const retiredProjects = orderProjects.filter(({ status }) => status === 'retired')
    const allocatedProjects = orderProjects.filter(({ status }) => status === 'allocated')

    return (
        <>
            {retiredProjects.length > 0 && (
                <CreditTable title="Retired Credits" orderProjects={retiredProjects} />
            )}
            {allocatedProjects.length > 0 && (
                <CreditTable title="Allocated Credits" orderProjects={allocatedProjects} />
            )}
        </>
    )
}

export default RetirementSummaryTable
