import { ProjectPerimeter } from '@lune-climate/lune'
import { LuneTheme } from '@lune-fe/lune-ui-lib'
import Box from '@mui/material/Box'
import { Map as ReactGlMap } from 'mapbox-gl'
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react'
import ReactMapGL from 'react-map-gl'

import { MapProps } from 'components/Map/Map'
import { coordinatesToMapboxPosition } from 'components/Map/utils'
import MapLegend from 'views/Projects/Project/MapWithDatasets/MapLegend'
import MapYearlyLegend from 'views/Projects/Project/MapWithDatasets/MapYearlyLegend'
import {
    Coordinate,
    Datasets,
    DatasetsListWithInfo,
    DatasetsMapLegend,
    drawDataset,
    drawPerimeter,
} from 'views/Projects/Project/MapWithDatasets/utils'

export interface MapWithDatasetProps extends MapProps {
    selectedDataset: Datasets
    perimeterData: ProjectPerimeter
    approximatePerimeter: boolean
    SWPoint: Coordinate
    NEPoint: Coordinate
}

const Map: FC<MapWithDatasetProps> = ({
    zoom,
    mapboxAccessToken,
    initialPosition,
    selectedDataset,
    perimeterData,
    SWPoint,
    NEPoint,
    approximatePerimeter,
}) => {
    const [mapLoaded, setMapLoaded] = useState<boolean>(false)
    const { palette } = LuneTheme
    const [mapElement, setMapElement] = useState<ReactGlMap>()
    const [selectedYear, setSelectedYear] = useState<number>()
    const [focusedToPerimeter, setFocusedToPerimeter] = useState<boolean>(false)

    const addPerimeter = useCallback(() => {
        const perimeter = perimeterData.coordinates.map((set) => {
            return set.map((c) => coordinatesToMapboxPosition(c))
        })
        if (mapElement && mapLoaded) {
            drawPerimeter(mapElement, perimeter, approximatePerimeter)

            if (!focusedToPerimeter) {
                mapElement.fitBounds(
                    [
                        [SWPoint.lon, SWPoint.lat],
                        [NEPoint.lon, NEPoint.lat],
                    ],
                    {
                        offset: [0, 0],
                        padding: 100,
                    },
                )
                setFocusedToPerimeter(true)
            }
        }
    }, [
        NEPoint,
        SWPoint,
        mapElement,
        mapLoaded,
        perimeterData.coordinates,
        approximatePerimeter,
        focusedToPerimeter,
    ])

    const addDataset = useCallback(
        (year?: number) => {
            if (mapElement && mapLoaded) {
                const dataset = DatasetsListWithInfo.find((d) => d.dataset === selectedDataset)
                if (dataset) {
                    drawDataset(mapElement, dataset, year)
                }
            }
        },
        [mapElement, mapLoaded, selectedDataset],
    )

    useEffect(() => {
        addPerimeter()
        addDataset()
    }, [perimeterData, mapLoaded, selectedDataset, addPerimeter, addDataset])

    useEffect(() => {
        if (selectedYear) {
            addDataset(selectedYear)
        }
    }, [selectedYear, addDataset])

    // Using useMemo to prevent unnecessary map re-renderings
    const mapComponent = useMemo(() => {
        return (
            <ReactMapGL
                projection={{ name: 'mercator' }}
                onLoad={(map) => {
                    setMapElement(map.target)
                    setMapLoaded(map.target.loaded())
                }}
                style={{
                    height: '100%',
                    width: '100%',
                    opacity: mapLoaded ? 1 : 0,
                    transition: 'opacity .3s cubic-bezier(0.25, 0.1, 0.25, 1)',
                }}
                mapboxAccessToken={mapboxAccessToken}
                mapStyle={'mapbox://sprites/mapbox/satellite-streets-v11'}
                maxZoom={12}
                minZoom={3}
                initialViewState={{
                    ...(initialPosition ?? {}),
                }}
            >
                {selectedDataset === Datasets.FOREST_LOSS ? (
                    <MapYearlyLegend
                        startYear={2001}
                        endYear={2020}
                        setYear={(y) => setSelectedYear(y)}
                    />
                ) : (
                    <MapLegend legend={DatasetsMapLegend.get(selectedDataset)!.legend} />
                )}
            </ReactMapGL>
        )
        // Disabling exhaustive-deps linting rule to strictly control when we want to rerender the map
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapLoaded, mapboxAccessToken, zoom, initialPosition, selectedYear])

    return (
        <Box
            sx={{
                height: '100%',
                width: '100%',
                overflow: 'hidden',
                position: 'relative',
                '.mapboxgl-canvas': {
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 'absolute',
                    width: '100%',
                    height: '100%',
                },
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    background: !mapLoaded ? palette.Grey300 : 'transparent',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    opacity: !mapLoaded ? 1 : 0,
                    transition: 'all .3s ease-in',
                }}
            />
            {mapComponent}
        </Box>
    )
}

export default memo(Map, (prev, next) => {
    return prev.selectedDataset === next.selectedDataset
})
