import Box from '@mui/material/Box';
import React, { FC } from 'react';

import ChickenFeetBackground from '../images/ChickenFeetBackground.png';

const NoResultsPlaceholder: FC<{
  message: React.ReactNode;
}> = ({ message }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(${ChickenFeetBackground})`,
        height: `100%`,
        width: '100%',
        backgroundSize: `cover`,
        backgroundPosition: `center`,
        borderRadius: '32px',
      }}
    >
      {message}
    </Box>
  );
};

export default NoResultsPlaceholder;
