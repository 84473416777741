import Fade from '@mui/material/Fade';
import MUIListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Popper from '@mui/material/Popper';
import { useTheme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import React, { FC, ReactNode } from 'react';

export interface Props {
  image: ReactNode;
  title: React.ReactNode;
  onHoverContent?: ReactNode;
  subTitle?: React.ReactNode;
  sx?: SxProps;
  compact?: boolean;
  dataTestId?: string;
}

const ListItemLayout: FC<Props> = ({ image, title, subTitle, onHoverContent, sx, compact, dataTestId }) => {
  const { spacing } = useTheme();
  const avatarRef = React.useRef(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const onEnter = () => {
    if (onHoverContent) {
      setAnchorEl(avatarRef.current as any);
    }
  };

  const onLeave = () => {
    if (onHoverContent) {
      setAnchorEl(null);
    }
  };

  return (
    <MUIListItem disableGutters sx={sx} onMouseLeave={onLeave} data-testid={dataTestId}>
      <ListItemAvatar
        ref={avatarRef}
        sx={{
          mr: 3,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minWidth: 'initial',
          maxWidth: spacing(6),
          maxHeight: spacing(6),
        }}
        onMouseEnter={onEnter}
      >
        {image}
      </ListItemAvatar>
      <ListItemText
        primaryTypographyProps={{
          variant: compact ? `body3` : `body2`,
          lineHeight: 1.5,
          onMouseEnter: onEnter,
        }}
        primary={title}
        secondaryTypographyProps={{
          variant: compact ? `caption` : `body3`,
          lineHeight: 1.5,
          onMouseEnter: onEnter,
        }}
        secondary={subTitle}
      />
      <Popper
        sx={{ zIndex: 9999999 }}
        keepMounted
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement={`left`}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 32],
            },
          },
        ]}
      >
        <Fade in={open}>
          <span>{onHoverContent}</span>
        </Fade>
      </Popper>
    </MUIListItem>
  );
};

export default ListItemLayout;
