const currencies = [
  {
    label: 'British Pound (GBP)',
    value: 'GBP',
  },
  {
    label: 'Euro (EUR)',
    value: 'EUR',
  },
  {
    label: 'US Dollar (USD)',
    value: 'USD',
  },
  {
    label: 'Swedish Krona (SEK)',
    value: 'SEK',
  },
  {
    label: 'Swiss Franc (CHF)',
    value: 'CHF',
  },
  {
    label: 'Polish Zloty (PLN)',
    value: 'PLN',
  },
  {
    label: 'Danish Krone (DKK)',
    value: 'DKK',
  },
  {
    label: 'Norwegian Krone (NOK)',
    value: 'NOK',
  },
  {
    label: 'Bulgarian Lev (BGN)',
    value: 'BGN',
  },
  {
    label: 'Indian Rupee (INR)',
    value: 'INR',
  },
  {
    label: 'Singapore Dollar (SGD)',
    value: 'SGD',
  },
  {
    label: 'United Arab Emirates Dirham (AED)',
    value: 'AED',
  },
  {
    label: 'Australian Dollar (AUD)',
    value: 'AUD',
  },
  {
    label: 'Hungarian Dollar (HUF)',
    value: 'HUF',
  },
  {
    label: 'Icelandic Króna (ISK)',
    value: 'ISK',
  },
  {
    label: 'Czech Koruna (CZK)',
    value: 'CZK',
  },
  {
    label: 'Canadian Dollar (CAD)',
    value: 'CAD',
  },
];

export default currencies;
