import type { SxProps } from '@mui/material';
import MuiButton from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import React, { FC, useMemo } from 'react';

import { LuneTheme } from '../theme';

export interface ButtonProps {
  children?: React.ReactNode;
  variant?: ButtonVariant;
  sx?: SxProps;
  wide?: boolean;
  disabled?: boolean;
  iconButton?: boolean;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  onClick?: (f: React.MouseEvent<HTMLElement>) => void;
  type?: 'button' | 'submit' | 'reset';
  /**
   * You're expected to provide something like
   *
   * ```
   * <Loading light={variant === ButtonVariants.CONTAINED} />
   * ```
   *
   * here, if the button is in the loading state.
   */
  loading?: React.ReactNode;
  loadingLabel?: string;
  light?: boolean;
  href?: string;
  target?: string;
}

type ButtonVariant = 'contained' | 'outlined' | 'text';
const ButtonVariants = {
  CONTAINED: 'contained',
  OUTLINED: 'outlined',
  TEXT: 'text',
};

// eslint-disable-next-line complexity
const Button: FC<ButtonProps> = ({
  iconButton,
  variant,
  wide,
  sx,
  type,
  disabled,
  onClick,
  loading,
  loadingLabel,
  leftIcon,
  rightIcon,
  children,
  light,
  href,
  target,
  ...rest
}) => {
  const palette = LuneTheme.palette;

  const StyledButton = useMemo(
    () =>
      styled(MuiButton)(
        LuneTheme.unstable_sx({
          height: '48px',
          width: iconButton ? '48px' : 'max-content',
          minWidth: iconButton ? 'max-content' : '64px',
          padding: '0 12px',
          borderRadius: '40px',
          boxShadow: 'none',
          whiteSpace: 'nowrap',
          svg: {
            maxWidth: '24px',
            maxHeight: '24px',
          },
        }),
      ),
    [iconButton],
  );

  const containedSX = {
    backgroundColor: light ? `${palette.White}` : `${palette.Grey900}`,
    color: light ? `${palette.Grey900}` : `${palette.White}`,
    '&:hover': {
      backgroundColor: light ? `${palette.White}` : `${palette.Black}`,
      boxShadow: 4,
    },
    '&:active': {
      backgroundColor: light ? `${palette.White}` : `${palette.Black}`,
      boxShadow: 6,
    },
    '&.disabled': {
      backgroundColor: `${palette.Grey200}`,
      color: `${palette.Grey500}`,
    },
  };

  const outlinedSX = {
    backgroundColor: 'transparent',
    borderColor: light ? `${palette.White}` : `${palette.Grey900}`,
    color: light ? `${palette.White}` : `${palette.Grey900}`,
    border: '2px solid !important',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      borderColor: light ? `${palette.White}` : `${palette.Grey900}`,
    },
    '&:active': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      borderColor: light ? `${palette.White}` : `${palette.Grey900}`,
    },
    '&.disabled': {
      color: `${palette.Grey500}`,
      borderColor: `${palette.Grey500}`,
    },
  };

  const textSX = {
    backgroundColor: 'transparent',
    color: light ? `${palette.White}` : `${palette.Grey900}`,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '&:active': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '&.disabled': {
      color: `${palette.Grey500}`,
    },
  };

  const wideSX = {
    minWidth: '200px',
  };

  const localSx = {
    ...(!variant || variant === ButtonVariants.CONTAINED
      ? containedSX
      : variant === ButtonVariants.OUTLINED
        ? outlinedSX
        : textSX),
    ...(wide ? wideSX : ''),
    ...(sx || {}),
  };

  const buttonIconStyle = {
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    width: `20px`,
    height: `20px`,
  };

  return (
    <StyledButton
      type={type}
      onClick={onClick}
      disabled={disabled}
      variant={variant}
      sx={localSx}
      className={disabled ? 'disabled' : ''}
      {...(href ? { href } : {})}
      {...(target ? { target } : {})}
      {...rest}
    >
      {loading ? (
        <>
          {loading}
          {loadingLabel && <span style={{ marginLeft: '8px' }}>{loadingLabel}</span>}
        </>
      ) : (
        <>
          {leftIcon && (
            <span
              style={{
                ...buttonIconStyle,
              }}
            >
              {leftIcon}
            </span>
          )}
          {children && (
            <span
              style={{
                marginLeft: leftIcon ? '10px' : 0,
                marginRight: rightIcon ? '10px' : 0,
              }}
            >
              {children}
            </span>
          )}
          {rightIcon && (
            <span
              style={{
                ...buttonIconStyle,
              }}
            >
              {rightIcon}
            </span>
          )}
        </>
      )}
    </StyledButton>
  );
};

export default Button;
