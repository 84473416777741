import {
    CalculatedRoute,
    ConvertedShipment,
    Distance,
    DistanceCalculationDetails,
    DistanceCalculationMethod,
    EmissionFactorWithGasEmissions,
    GasEmissions,
    LogisticsSiteMethod,
    MassUnit,
    MethodologyDetails as LuneMethodologyDetails,
    NullEnum,
    RoadMethodologyDetails,
    Shipment,
    ShipmentConversionMethod,
    ShippingMethod,
    ShippingRoute,
    ShippingSourceDestination,
    VesselInferenceMethod,
} from '@lune-climate/lune'
import { FC } from 'react'

import MapWithRoute from 'components/Map/MapWithRoute'
import EmissionFactorExplanation from 'views/CalculateEmissions/EmissionsExplanation/EmissionFactorExplanation'
import ExplanationSteps from 'views/CalculateEmissions/EmissionsExplanation/ExplanationSteps'
import { calculateEmissionFactorAmount } from 'views/CalculateEmissions/EmissionsExplanation/ExplanationUtils'
import useExplanationSteps from 'views/CalculateEmissions/EmissionsExplanation/useExplanationSteps'
import { isAirShippingMethod } from 'views/CalculateEmissions/Logistics/LogisticsUtils'

export interface EmissionsExplanationSectionProps {
    emissions: { amount: string; unit: MassUnit }
    /**
     * If `responseRoute` is non-null then this property has to be non-null as well.
     */
    distance: Distance | undefined
    adjustedDistance: Distance | undefined
    load: Shipment
    distanceCalculationMethod: DistanceCalculationMethod | NullEnum
    distanceCalculationDetails: DistanceCalculationDetails | NullEnum
    convertedLoad: ConvertedShipment | NullEnum
    loadConversionMethod: ShipmentConversionMethod | NullEnum
    methodology: string[]
    emissionFactor: EmissionFactorWithGasEmissions
    /**
     * If `responseRoute` is non-null then this property has to be non-null as well.
     */
    requestRoute: ShippingRoute | null
    requestMethod: ShippingMethod | LogisticsSiteMethod
    responseRoute: CalculatedRoute | null
    methodologyDetails: MethodologyDetails | RoadMethodologyDetails | null
    vesselInference: VesselInferenceMethod | null
}

export type MethodologyDetails = {
    flight?: FlightMethodologyDetails
}

export type FlightMethodologyDetails = {
    aircraftName: string
    aircraftConfiguration: LuneMethodologyDetails.aircraft_configuration
    aircraftCapacityTonnes: {
        passenger: number
        cargo: number
    }
    loadFactor: {
        passenger: number
        cargo: number
    }
    flightFuelConsumedTonnes: number
    distanceAdjustmentFactor: number
    emissionShare: number
}

const EmissionsExplanationSection: FC<EmissionsExplanationSectionProps> = (props) => {
    const {
        distance,
        adjustedDistance,
        distanceCalculationMethod,
        load,
        convertedLoad,
        emissionFactor,
        emissions,
        responseRoute,
        requestRoute,
        requestMethod,
    } = props

    const steps = useExplanationSteps(props)
    const showMap =
        (distanceCalculationMethod === 'vessel_tracking' || isAirShippingMethod(requestMethod)) &&
        responseRoute

    const localEmissionFactorAmount = calculateEmissionFactorAmount(
        convertedLoad,
        emissionFactor,
        load,
        adjustedDistance,
        emissions,
    )

    /**
     * To be called in the context where `responseRoute` is defined. If it's defined we know
     * we have to have provided source and destination to the API so let's verify that and,
     * if it's the case, return a type-narrowed version of the `route`.
     *
     * Calling this function when `responseRoute` is null is a programming error and will
     * result in an exception.
     */
    function expectSourceDestinationRoute(route: ShippingRoute | null): ShippingSourceDestination {
        if (responseRoute === null) {
            throw new Error(`This function can only be called when responseRoute is non-null`)
        }
        if (route === null || !('source' in route)) {
            throw new Error(
                `We expect the request route to be defined and to have source and destination`,
            )
        }
        return route
    }

    return (
        <>
            <ExplanationSteps steps={steps} />
            <EmissionFactorExplanation
                {...(emissionFactor.gasEmissions === null
                    ? {
                          emissionFactor: emissionFactor as Omit<
                              EmissionFactorWithGasEmissions,
                              'gasEmissions'
                          > & { gasEmissions: null },
                          amount: localEmissionFactorAmount.toString(),
                      }
                    : {
                          emissionFactor: emissionFactor as Omit<
                              EmissionFactorWithGasEmissions,
                              'gasEmissions'
                          > & { gasEmissions: GasEmissions },
                          amount: null,
                      })}
                label={emissionFactor.name}
                href={`/emission-factors/${emissionFactor.id}`}
            />
            {showMap && (
                <MapWithRoute
                    // SAFETY: The environment variable may not be defined and then
                    // we'll just crash.
                    mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN!}
                    source={expectSourceDestinationRoute(requestRoute).source}
                    destination={expectSourceDestinationRoute(requestRoute).destination}
                    route={[
                        responseRoute.source.coordinates,
                        ...responseRoute.legs.map((l) => l.location.coordinates),
                    ]}
                    // SAFETY: The types don't express this but we know that when we get a route
                    // in response then we also have to get distance. Hence the type assertion.
                    distance={distance!}
                />
            )}
        </>
    )
}

export default EmissionsExplanationSection
