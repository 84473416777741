import { Button, MainLayoutContainer, NoResultsPlaceholder, Text } from '@lune-fe/lune-ui-lib'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import Box from '@mui/material/Box'
import { useSnackbar } from 'notistack'
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { Range } from 'react-date-range'

import FiltersBox from 'components/FiltersBox'
import FiltersWithQueryParamsWrapper, {
    onLoadPropsWithType,
} from 'components/FiltersWithQueryParamsWrapper'
import { getOrders } from 'endpoints/dapi'
import useMixpanel from 'hooks/useMixpanel'
import { PaginatedOrdersWithAccountInfo } from 'models/openDapi'
import { SnackbarMessages } from 'SnackbarMessages'
import { toTitleCase } from 'utils'
import OrdersList from 'views/Orders/OrdersList'

export enum OrderStatus {
    Received = 'received',
    Placed = 'placed',
    Paid = 'paid',
    Retiring = 'retiring',
    Cancelled = 'cancelled',
    Complete = 'complete',
    Failed = 'failed',
}

export const initialOrderStates = [
    OrderStatus.Received,
    OrderStatus.Placed,
    OrderStatus.Paid,
    OrderStatus.Retiring,
    OrderStatus.Cancelled,
    OrderStatus.Complete,
    OrderStatus.Failed,
]

const initialDateRange = {
    startDate: undefined,
    endDate: undefined,
}

const Orders = () => {
    const ORDERS_LIMIT = 10

    const { enqueueSnackbar: snackbar } = useSnackbar()
    const mixpanel = useMixpanel()

    const [loading, setLoading] = useState(true)
    const [paginatedOrders, setPaginatedOrders] = useState<
        PaginatedOrdersWithAccountInfo & { lastId?: string }
    >()
    const [selectedAccountIds, setSelectedAccountIds] = useState<string[]>()
    const [dateRange, setDateRange] = useState<Range>(initialDateRange)
    const [orderStates, setOrderStates] = useState<OrderStatus[]>([])

    const loadOrders = useCallback(
        async (
            { selectedAccountIds, dateRange, types: orderStates }: onLoadPropsWithType<OrderStatus>,
            page?: string,
        ) => {
            try {
                const paginatedResult = await getOrders({
                    limit: ORDERS_LIMIT,
                    after: page,
                    accountId: selectedAccountIds,
                    from: dateRange.startDate?.toISOString(),
                    through: dateRange.endDate?.toISOString(), // add one to the date
                    status: orderStates,
                })

                setPaginatedOrders((prevValue) => ({
                    hasMore: paginatedResult.hasMore,
                    nextCursor: paginatedResult.nextCursor,
                    data: page
                        ? [...(prevValue ? prevValue.data : []), ...paginatedResult.data]
                        : paginatedResult.data,
                }))
                setLoading(false)
            } catch {
                setPaginatedOrders({
                    hasMore: false,
                    nextCursor: null,
                    data: [],
                })
                snackbar(SnackbarMessages.GENERIC_ERROR)
                setLoading(false)
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedAccountIds, dateRange, orderStates, snackbar],
    )

    const onLoadMoreButtonClick = () => {
        mixpanel.track('orders_page_load_more_clicked')
        const page = paginatedOrders?.data.at(-1)?.id
        if (selectedAccountIds) {
            loadOrders(
                {
                    selectedAccountIds,
                    dateRange,
                    types: orderStates,
                },
                page,
            )
        }
    }

    useEffect(() => {
        if (selectedAccountIds) {
            loadOrders({
                selectedAccountIds,
                dateRange,
                types: orderStates,
            })
        }
    }, [loadOrders, dateRange, selectedAccountIds, orderStates])

    return (
        <MainLayoutContainer
            headerComponent={
                <>
                    <Text data-testid={`all-orders-layout-title`} variant={'h4'} sx={{ mb: 4 }}>
                        Orders
                    </Text>
                    {selectedAccountIds && (
                        <FiltersBox<OrderStatus>
                            preselectedDateRange={dateRange}
                            preselectedAccountIds={selectedAccountIds}
                            preselectedTypes={orderStates}
                            setSelectedAccountIds={
                                setSelectedAccountIds as Dispatch<SetStateAction<string[]>>
                            }
                            setDateRange={setDateRange}
                            setSelectedTypes={setOrderStates}
                            dropDownFilterName={'status'}
                            types={initialOrderStates}
                            transformDropDownLabel={(label) => toTitleCase(label)}
                        />
                    )}
                </>
            }
        >
            <FiltersWithQueryParamsWrapper<OrderStatus>
                dropDownFilterName={'status'}
                setDateRange={setDateRange}
                dateRange={dateRange}
                setSelectedAccountIds={setSelectedAccountIds}
                selectedAccountIds={selectedAccountIds}
                setSelectedTypes={setOrderStates}
                selectedTypes={orderStates}
            />
            {!paginatedOrders?.data.length && !loading ? (
                <Box sx={{ height: `554px` }}>
                    <NoResultsPlaceholder
                        message={
                            <Text variant={`h6`} color={`Grey900`}>
                                No orders placed yet
                            </Text>
                        }
                    />
                </Box>
            ) : (
                <>
                    {paginatedOrders && <OrdersList paginatedOrders={paginatedOrders.data} />}
                    {paginatedOrders?.hasMore && (
                        <Button
                            variant={`outlined`}
                            leftIcon={<ArrowDownwardIcon />}
                            onClick={() => onLoadMoreButtonClick()}
                            sx={{ mt: 9 }}
                        >
                            Load more
                        </Button>
                    )}
                </>
            )}
        </MainLayoutContainer>
    )
}

export default Orders
