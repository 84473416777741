import MenuIcon from '@mui/icons-material/Menu';
import type { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import React from 'react';

import { LuneTheme } from '../theme';
import { useIsMobile } from '../utils';

export interface LayoutProps {
  children: React.ReactNode;
  sideBar: JSX.Element;
  isResponsive?: boolean;
  sx?: SxProps;
  menuButtonSx?: SxProps;
  menuButtonContainerSx?: SxProps;
}

const Layout = React.forwardRef(
  ({ children, sideBar, isResponsive = false, sx, menuButtonSx, menuButtonContainerSx }: LayoutProps, ref) => {
    const { palette, spacing } = LuneTheme;
    const { isMobile } = useIsMobile();
    const [showSideBar, setShowSideBar] = React.useState(false);
    const isSideBarVisible = !isResponsive || !isMobile ? true : showSideBar;
    const isMenuButtonVisible = isResponsive && isMobile;

    return (
      <>
        {isMenuButtonVisible && (
          <Box sx={menuButtonContainerSx}>
            <IconButton
              onClick={() => {
                setShowSideBar((prev) => !prev);
              }}
              edge="start"
              aria-label="menu"
              sx={{ color: `${palette.Grey900}`, m: 1, ...menuButtonSx }}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        )}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            height: '100vh',
            overflow: 'hidden',
            ...sx,
          }}
        >
          {isSideBarVisible && (
            <Box
              sx={{
                width: spacing(36),
                height: '100%',
                backgroundColor: palette.White,
                overflow: 'auto',
                overflowX: 'hidden',
                borderRight: '1px solid',
                borderColor: palette.Grey300,
                flex: '1 0 auto',
              }}
            >
              {sideBar}
            </Box>
          )}
          <Box
            ref={ref}
            sx={{
              width: '100%',
              overflow: 'auto',
              position: 'relative',
              overflowY: 'scroll',
            }}
          >
            {children}
          </Box>
        </Box>
      </>
    );
  },
);

Layout.displayName = 'Layout';
export default Layout;
