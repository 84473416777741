import { MassUnit } from '@lune-climate/lune'
import { Text } from '@lune-fe/lune-ui-lib'
import { SxProps } from '@mui/material'
import Box from '@mui/material/Box'
import { FC, useMemo } from 'react'
import CountUp from 'react-countup'

import { getFormattedAmount } from 'views/CalculateEmissions/utils'

const CalculationResults: FC<{
    amount: string
    title?: string
    subtitle?: string
    sx?: SxProps
}> = ({ title, subtitle, amount, sx }) => {
    const formattedAmount: {
        amountAsNumber: number
        amountUnit: MassUnit
    } = useMemo(() => getFormattedAmount(amount), [amount])

    return (
        <Box sx={sx}>
            <Text data-testid="calculation-title" variant={'h6'}>
                {title}
            </Text>
            <Text variant={'h4'} sx={{ mt: 1 }}>
                <CountUp
                    duration={0.5}
                    end={formattedAmount.amountAsNumber}
                    separator={','}
                    decimals={2}
                    decimal="."
                    suffix={` ${formattedAmount.amountUnit}CO₂e`}
                />
            </Text>
            {subtitle && (
                <Text variant={'body3'} sx={{ color: `Grey700`, mt: 1 }}>
                    {subtitle}
                </Text>
            )}
        </Box>
    )
}

export default CalculationResults
