import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { FC, useMemo } from 'react';

import { LuneTheme } from '../theme';

export interface RadioButtonGroupProps {
  defaultValue: any;
  value: any;
  name: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
  items: RadioButtonProps[];
}

export interface RadioButtonProps {
  label: React.ReactNode;
  value: any;
  disabled?: boolean;
}

/**
 * Controlled Radio button group component
 * @param defaultValue - The default/initial value. Use when the component is not controlled.
 * @param value - Value of the selected radio button. The DOM API casts this to a string.
 * @param name - The name used to reference the value of the control
 * @param onChange - Callback fired when a radio button is selected.
 * @param items - Radio buttons containing a value, and a label that can be a string, Text, Textarea, Input, or any other ReactNode
 */
const RadioButtonGroup: FC<RadioButtonGroupProps> = ({ defaultValue, value, name, onChange, items, ...rest }) => {
  const { palette } = LuneTheme;
  const StyledRadioButtonGroup = useMemo(
    () =>
      styled(RadioGroup)(
        LuneTheme.unstable_sx({
          '.MuiFormControlLabel-root': {
            margin: 0,
            padding: '12px 8px',
            alignItems: 'flex-start',
          },
          '.MuiFormControlLabel-label': {
            width: '100%',
          },
        }),
      ),
    [],
  );

  return (
    <StyledRadioButtonGroup defaultValue={defaultValue} value={value} onChange={onChange} {...rest}>
      {items.map((item, index) => (
        <FormControlLabel
          disabled={item.disabled}
          key={`${name}-radio-${index}`}
          value={item.value}
          control={
            <Radio
              sx={{
                color: palette.Grey900,
                p: 0,
                mr: 3,
                '&.Mui-checked': {
                  color: palette.Grey900,
                },
              }}
            />
          }
          label={
            <Typography
              sx={{
                minHeight: '24px',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                color: item.disabled ? palette.Grey500 : palette.Grey900,
              }}
              variant={'body2'}
            >
              {item.label}
            </Typography>
          }
        />
      ))}
    </StyledRadioButtonGroup>
  );
};

export default RadioButtonGroup;
