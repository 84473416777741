import Box from '@mui/material/Box';
import React, { FC, ReactNode, RefObject, useEffect, useMemo, useRef, useState } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';

import useScrollRestoration from '../hooks/useScrollRestoration';

const ScrollableContainer: FC<{
  sessionStoragePositionName?: string;
  containerRef?: React.RefObject<HTMLDivElement>;
  children: ReactNode;
  ignoreElements?: string;
}> = ({ children, sessionStoragePositionName, containerRef, ignoreElements }) => {
  const cardsContainer: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const scrollingContainer: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const { restoreScrollPosition, saveScrollPosition } = useScrollRestoration();

  const [windowWidth, setWindowSize] = useState(0);
  const [cardsContainerWidth, setCardsContainerWidth] = useState(0);
  const [isScrollable, setIsScrollable] = useState(false);

  useEffect(() => {
    function handleResize() {
      if (containerRef) {
        setWindowSize(containerRef.current?.clientWidth || 0);
      } else {
        setWindowSize(document.body.clientWidth || 0);
      }
    }

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const containerWidth = cardsContainer.current?.clientWidth;
    if (containerWidth) {
      setCardsContainerWidth(windowWidth - containerWidth);
    }
  }, [windowWidth]);

  useEffect(() => {
    if (scrollingContainer.current) {
      const sw = scrollingContainer.current.scrollWidth;
      const cw = scrollingContainer.current.clientWidth;
      setIsScrollable(sw > cw);
    }
  }, [windowWidth, scrollingContainer.current?.clientWidth]);

  const onScroll = () => {
    const sl = scrollingContainer.current?.scrollLeft || 0;
    if (sessionStoragePositionName) {
      saveScrollPosition(sl, sessionStoragePositionName);
    }
  };

  useEffect(() => {
    restoreScrollPosition(scrollingContainer, sessionStoragePositionName!);
  }, []);

  const getStyles = useMemo(() => {
    return {
      boxSizing: 'border-box',
      width: `calc(100% + ${cardsContainerWidth}px)`,
      paddingLeft: `${cardsContainerWidth / 2}px`,
      marginLeft: `${cardsContainerWidth / -2}px`,
      display: 'flex',
      flexWrap: 'nowrap',
      alignItems: 'stretch',
      justifyContent: 'flex-start',
      flexDirection: 'row',
      position: 'relative',
      cursor: isScrollable ? 'grab' : 'normal',
      overflowY: 'none !important',
      overflowX: 'auto',
    };
  }, [cardsContainerWidth, isScrollable]);

  return (
    <Box
      ref={cardsContainer}
      sx={{
        '.scroll-container': getStyles,
      }}
    >
      {
        <ScrollContainer
          innerRef={scrollingContainer}
          hideScrollbars={false}
          onScroll={onScroll}
          className="scroll-container"
          horizontal={true}
          ignoreElements={ignoreElements}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              zIndex: 9,
            }}
          >
            {children}
          </Box>
        </ScrollContainer>
      }
    </Box>
  );
};
export default ScrollableContainer;
