import { OrderBase } from '@lune-climate/lune'
import { Table } from '@lune-fe/lune-ui-lib'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined'
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'
import { Big } from 'big.js'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { DapiOrder } from 'endpoints/dapi'
import useMixpanel from 'hooks/useMixpanel'
import { formatNumbers, formatTimestamp, formatToCurrency, toTitleCase } from 'utils'
import { OrderStatus } from 'views/Orders/Orders'

interface IOrderAggregates {
    quantity: Big
}

function orderAggregates(order: DapiOrder): IOrderAggregates | undefined {
    if (order.status === OrderBase.status.PAID || order.status === OrderBase.status.PLACED) {
        return order.bundles?.reduce(
            (aggr: IOrderAggregates, r: { quantity: string }) => ({
                quantity: aggr.quantity.add(Big(r.quantity)),
            }),
            {
                quantity: Big('0'),
            },
        )
    }

    return order.bundles?.reduce(
        (aggr: IOrderAggregates, al: { quantity: string }) => ({
            quantity: aggr.quantity.add(Big(al.quantity)),
        }),
        {
            quantity: Big('0'),
        },
    )
}

function OrderStatusIcon({ status }: { status: OrderStatus }) {
    const sx = { mr: 3 }
    if (status === OrderStatus.Failed) {
        return <ReportProblemOutlinedIcon sx={sx} />
    } else if (status === OrderStatus.Cancelled) {
        return <CloseOutlinedIcon sx={sx} />
    } else {
        return <ReceiptLongOutlinedIcon sx={sx} />
    }
}

function OrderTableRow({ order }: { order: DapiOrder }) {
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const mixpanel = useMixpanel()

    const aggregates = orderAggregates(order)

    return (
        <Table.TableBody>
            <Table.TableRow
                data-testid={order.id}
                onClick={() => {
                    mixpanel.track('orders_page_clicked')
                    navigate(`/orders/${order.id}`, {
                        state: {
                            originSearchParams: searchParams.toString(),
                        },
                    })
                }}
                hover
            >
                <Table.TableCell colSize={5}>
                    <OrderStatusIcon status={order.status as OrderStatus} />
                    {formatTimestamp(order.createdAt)}
                </Table.TableCell>
                <Table.TableCell>{order.accountName}</Table.TableCell>
                <Table.TableCell>{toTitleCase(order.status)}</Table.TableCell>
                <Table.TableCell alignEnd>
                    {aggregates &&
                        (aggregates.quantity.lt(1)
                            ? aggregates.quantity.toString()
                            : formatNumbers(aggregates.quantity.toString()))}
                </Table.TableCell>
                <Table.TableCell alignEnd>
                    {order.totalCost && formatToCurrency(order.totalCost, order.currency)}
                </Table.TableCell>
            </Table.TableRow>
        </Table.TableBody>
    )
}

export default function Orders({ paginatedOrders }: { paginatedOrders: DapiOrder[] }) {
    return (
        <Table.TableContainer>
            <Table>
                <Table.TableHead>
                    <Table.TableRow>
                        <Table.TableCell colSize={5}>Date & time</Table.TableCell>
                        <Table.TableCell>Account</Table.TableCell>
                        <Table.TableCell>Status</Table.TableCell>
                        <Table.TableCell>
                            <div style={{ textAlign: `right`, width: `100%` }}>tCO₂</div>
                        </Table.TableCell>
                        <Table.TableCell>
                            {' '}
                            <div style={{ textAlign: `right`, width: `100%` }}>Total cost</div>
                        </Table.TableCell>
                    </Table.TableRow>
                </Table.TableHead>
                {paginatedOrders.map((order: DapiOrder, i: number) => (
                    <OrderTableRow key={i} order={order} />
                ))}
            </Table>
        </Table.TableContainer>
    )
}
