import { ListItemLayout, LuneTheme, Table, Text } from '@lune-fe/lune-ui-lib'
import Avatar from '@mui/material/Avatar'
import { Big } from 'big.js'
import { sortBy } from 'lodash'
import { useEffect, useState } from 'react'

import useAccountCurrency from 'hooks/useAccountCurrency'
import { formatNumbers } from 'utils'

interface BundlesInfo {
    id: string
    primaryImage: string
    name: string
    quantity: number
    grossUnitPrice: Big
}

const OrderSummaryTable = ({ bundlesInfo }: { bundlesInfo: BundlesInfo[] }) => {
    const {
        palette: { Grey900 },
    } = LuneTheme
    const toCurrency = useAccountCurrency()
    const totalQuantity = bundlesInfo.reduce((acc, curr) => acc + curr.quantity, 0)
    const [sortedBundles, setSortedBundles] = useState<BundlesInfo[]>([])

    useEffect(() => {
        setSortedBundles(sortBy(bundlesInfo, (b: BundlesInfo) => b.name))
    }, [bundlesInfo])
    return (
        <div>
            <Table.TableContainer>
                <Table aria-label="simple table">
                    <Table.TableHead>
                        <Table.TableRow>
                            <Table.TableCell colSize={6}>
                                <Text variant={'button'} sx={{ color: Grey900 }}>
                                    Bundle
                                </Text>
                            </Table.TableCell>
                            <Table.TableCell colSize={2} alignEnd>
                                <Text variant={'button'} sx={{ color: Grey900 }}>
                                    Volume
                                </Text>
                            </Table.TableCell>
                            <Table.TableCell colSize={2} alignEnd align="right">
                                <Text variant={'button'} sx={{ color: Grey900 }}>
                                    % of volume
                                </Text>
                            </Table.TableCell>
                            <Table.TableCell colSize={2} alignEnd align="right">
                                <Text variant={'button'} sx={{ color: Grey900 }}>
                                    Price
                                </Text>
                            </Table.TableCell>
                        </Table.TableRow>
                    </Table.TableHead>
                    <Table.TableBody>
                        {sortedBundles.map(
                            ({ id, primaryImage, name, quantity, grossUnitPrice }) => {
                                const price = Big(quantity || 0)
                                    .mul(grossUnitPrice)
                                    .toNumber()
                                const percentage = formatNumbers(
                                    100 * (quantity / totalQuantity),
                                    2,
                                )
                                return (
                                    <Table.TableRow key={id} data-testid={`bundle-summary-row`}>
                                        <Table.TableCell colSize={6}>
                                            <ListItemLayout
                                                data-testid={`item-${name}`}
                                                image={
                                                    <Avatar
                                                        alt={name}
                                                        src={primaryImage}
                                                        variant={`rounded`}
                                                        sx={{
                                                            width: `48px`,
                                                            height: `48px`,
                                                            borderRadius: `8px`,
                                                        }}
                                                    />
                                                }
                                                title={name}
                                            />
                                        </Table.TableCell>
                                        <Table.TableCell colSize={2} alignEnd>
                                            <Text
                                                variant={`body2`}
                                                sx={{
                                                    color: Grey900,
                                                }}
                                            >
                                                {quantity < 1
                                                    ? Big(Big(quantity).toFixed(6)).toNumber()
                                                    : Big(Big(quantity).toFixed(2)).toNumber()}{' '}
                                                tCO₂
                                            </Text>
                                        </Table.TableCell>
                                        <Table.TableCell colSize={2} alignEnd>
                                            <Text
                                                variant={`body2`}
                                                sx={{
                                                    color: Grey900,
                                                }}
                                            >
                                                {percentage}%
                                            </Text>
                                        </Table.TableCell>
                                        <Table.TableCell colSize={2} alignEnd>
                                            <Text
                                                variant={`body2`}
                                                sx={{
                                                    color: Grey900,
                                                }}
                                            >
                                                {toCurrency?.(price)}
                                            </Text>
                                        </Table.TableCell>
                                    </Table.TableRow>
                                )
                            },
                        )}
                    </Table.TableBody>
                </Table>
            </Table.TableContainer>
        </div>
    )
}

export default OrderSummaryTable
