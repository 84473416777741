import { Table, Tooltip } from '@lune-fe/lune-ui-lib'
import { Big } from 'big.js'
import moment from 'moment'
import { FC, ReactElement } from 'react'

import ConditionalTooltip from 'components/ConditionalTooltip'
import { EstimateWithAccountInfo } from 'models/openDapi'
import { formatNumbers } from 'utils'
import {
    EmissionCalculationsContextMenu,
    EstimateTypeIcon,
    EstimateTypeName,
    EstimateTypeSlug,
} from 'views/CalculateEmissions'

const EmissionConditionalTooltip = ({
    slug,
    emissionType,
    children,
}: {
    slug: string | undefined
    emissionType: string
    children: ReactElement
}) => {
    return (
        <ConditionalTooltip
            show={slug === undefined}
            tooltip={({ children }) => (
                <Tooltip
                    style={{ width: '100%' }}
                    title={`${emissionType} can be viewed and edited only via the API`}
                    placement="top"
                >
                    {children}
                </Tooltip>
            )}
        >
            {children}
        </ConditionalTooltip>
    )
}

const EmissionCalculationsRow: FC<{
    calculation: EstimateWithAccountInfo
    onClick: () => void
}> = ({ calculation, onClick }) => {
    const formatDate = (date: string) => {
        return moment(date).format('ll')
    }

    // The dashboard does not support all calculations.
    // 1. We list them all
    // 2. But only allow drilling through and editing for the ones we actually support
    // `undefined` slug means the dashboard does not support the calculation
    const slug = EstimateTypeSlug(calculation.estimateType)

    return (
        <Table.TableRow>
            <Table.TableCell colSpan={12} colSize={12}>
                <EmissionConditionalTooltip
                    slug={slug}
                    emissionType={EstimateTypeName(calculation.estimateType)}
                >
                    <Table.TableContainer>
                        <Table>
                            <Table.TableRow
                                data-testid={calculation.id}
                                aria-label="asdads"
                                hover
                                sx={
                                    slug
                                        ? {}
                                        : {
                                              '&:hover': {
                                                  cursor: 'not-allowed',
                                              },
                                          }
                                }
                                {...(slug
                                    ? {
                                          onClick,
                                      }
                                    : {})}
                            >
                                <Table.TableCell colSize={5}>
                                    {EstimateTypeIcon(calculation.estimateType)}
                                    {calculation.name || EstimateTypeName(calculation.estimateType)}
                                </Table.TableCell>
                                <Table.TableCell colSize={2}>
                                    {formatDate(calculation.createdAt)}
                                </Table.TableCell>
                                <Table.TableCell colSize={2}>
                                    {calculation.accountName}
                                </Table.TableCell>
                                <Table.TableCell alignEnd colSize={2}>
                                    {Big(calculation.quantity).lt(1)
                                        ? formatNumbers(calculation.quantity, 6)
                                        : formatNumbers(calculation.quantity, 2)}
                                </Table.TableCell>
                                <Table.TableCell
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        e.preventDefault()
                                    }}
                                    alignEnd
                                    colSize={1}
                                >
                                    <EmissionCalculationsContextMenu
                                        calculation={calculation}
                                        editable={slug !== undefined}
                                    />
                                </Table.TableCell>
                            </Table.TableRow>
                        </Table>
                    </Table.TableContainer>
                </EmissionConditionalTooltip>
            </Table.TableCell>
        </Table.TableRow>
    )
}

export default EmissionCalculationsRow
