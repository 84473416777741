import {
    Distance,
    MassUnit,
    ShippingEstimateRequest,
    ShippingMethod,
    ShippingRoute,
    SimpleShippingMethod,
    SingleShippingEmissionEstimate,
} from '@lune-climate/lune'
import { BannerMessage } from '@lune-fe/lune-ui-lib'
import { Box } from '@mui/material'
import { FormApi } from 'final-form'
import { useSnackbar } from 'notistack'
import { FC, RefObject, useEffect, useMemo, useState } from 'react'
import { Field, FormSpy } from 'react-final-form'
import { useParams } from 'react-router-dom'

import { FormInput } from 'components/FormInput'
import { useLuneClient } from 'hooks/useLuneClient'
import { SnackbarMessages } from 'SnackbarMessages'
import {
    CalculateEmissionsFormWrapper,
    CalculateEmissionsLabeledFormSection,
    EstimateTypeEnum,
} from 'views/CalculateEmissions'
import Load from 'views/CalculateEmissions/Logistics/Load'
import {
    getShippingMethodMainTypeFromValue,
    isLogisticsSiteMethod,
    isSimpleSeaShippingMethod,
} from 'views/CalculateEmissions/Logistics/LogisticsUtils'
import Method from 'views/CalculateEmissions/Logistics/Method/Method'
import RouteAsAddress from 'views/CalculateEmissions/Logistics/Route/RouteAsAddress'
import RouteAsAirportCode from 'views/CalculateEmissions/Logistics/Route/RouteAsAirportCode'
import RouteAsCoordinates from 'views/CalculateEmissions/Logistics/Route/RouteAsCoordinates'
import RouteAsDistance from 'views/CalculateEmissions/Logistics/Route/RouteAsDistance'
import RouteAsSeaportCode from 'views/CalculateEmissions/Logistics/Route/RouteAsSeaportCode'
import RouteTypes, { RouteType } from 'views/CalculateEmissions/Logistics/RouteTypes'
import { OtherShipmentMethods } from 'views/CalculateEmissions/Logistics/ShipmentMethods'

type ShippingEstimateRequestWithRoute = Extract<ShippingEstimateRequest, { route: ShippingRoute }>

const onCreate = async (
    luneClient: ReturnType<typeof useLuneClient>,
    values: ShippingEstimateRequest,
) => {
    /* SeaShippingComp.tsx sets method as { vesselType }
       It assumes everything is a SeaShippingMethod
       Inland waterways, are actually SimpleShippingMethods

       TODO: Refactor SeaShippingComp.tsx to not typecast
    */
    const method = values.method as any
    const isInlandWaterway = method.vesselType?.split('_')[0] === 'inland'

    return luneClient.createShippingEstimate({
        shippingEstimateRequest: {
            ...values,
            method:
                isInlandWaterway || isSimpleSeaShippingMethod(method) ? method.vesselType : method,
        },
    })
}
const onUpdate = async (
    luneClient: ReturnType<typeof useLuneClient>,
    id: string,
    values: ShippingEstimateRequest,
) => luneClient.updateShippingEstimate(id, { shippingEstimateRequest: values })

const LogisticsEmissions: FC<{ scrollRef: RefObject<HTMLDivElement> }> = ({ scrollRef }) => {
    const { id } = useParams<{ id: string }>()
    const { enqueueSnackbar: snackbar } = useSnackbar()

    const [loading, setLoading] = useState<boolean>(false)
    const [initialFormValue, setInitialFormValue] = useState<ShippingEstimateRequest>()
    const [formValue, setFormValue] = useState<ShippingEstimateRequest>()
    const luneClient = useLuneClient()

    const defaultFormValues: Partial<ShippingEstimateRequest> = useMemo(() => {
        return {
            name: '',
            shipment: {
                mass: {
                    amount: '0',
                    unit: MassUnit.KG,
                },
            },
            route: {
                amount: '0',
                unit: Distance.unit.KM,
            },
            method: undefined,
        }
    }, [])

    useEffect(() => {
        if (id) {
            luneClient
                .getShippingEstimate(id)
                .then((res) => {
                    if (res.isOk()) {
                        const estimate: SingleShippingEmissionEstimate = res.value
                        setInitialFormValue(estimate.request)
                    } else {
                        setInitialFormValue(defaultFormValues as ShippingEstimateRequest)
                    }
                })
                .catch(() => {
                    snackbar(SnackbarMessages.GENERIC_ERROR)
                    setInitialFormValue(defaultFormValues as ShippingEstimateRequest)
                })
                .finally(() => setLoading(false))
        } else {
            setInitialFormValue(defaultFormValues as ShippingEstimateRequest)
        }
    }, [id, defaultFormValues, snackbar, luneClient])

    const handleMethodValidation = (value: ShippingMethod) => {
        const vesselImoNumber =
            value instanceof Object && 'vesselImoNumber' in value
                ? value.vesselImoNumber
                : undefined
        const vesselName =
            value instanceof Object && 'vesselName' in value ? value.vesselName : undefined
        const vesselType =
            value instanceof Object && 'vesselType' in value ? value.vesselType : undefined
        if (vesselType?.trim() === '') {
            return {
                key: 'SHIPPING_METHOD',
                message: 'This is a required field',
            }
        }
        if (vesselImoNumber?.trim() === '' || vesselName?.trim() === '') {
            return {
                key: 'ERROR_IMO_NUMBER',
                message: 'This is a required field',
            }
        } else if (vesselImoNumber && vesselImoNumber.length !== 7) {
            return {
                key: 'ERROR_IMO_NUMBER',
                message: 'IMO number should contain 7 characters',
            }
        }
    }

    const [routeType, setRouteType] = useState<RouteType | undefined>(RouteType.DISTANCE)

    useEffect(() => {
        if (initialFormValue) {
            if ('route' in initialFormValue) {
                // the dashboard always provides route
                const route = initialFormValue.route
                if ('amount' in route) {
                    setRouteType(RouteType.DISTANCE)
                } else {
                    if ('streetLine1' in route.source) {
                        setRouteType(RouteType.ADDRESS)
                    }
                    if ('lat' in route.source) {
                        setRouteType(RouteType.GEO_COORDINATES)
                    }
                    if ('locode' in route.source) {
                        setRouteType(RouteType.SEAPORT)
                    }
                    if ('code' in route.source) {
                        setRouteType(RouteType.AIRPORT)
                    }
                }
            }
        }
    }, [initialFormValue])

    return (
        <CalculateEmissionsFormWrapper<ShippingEstimateRequest>
            emissionType={EstimateTypeEnum.SHIPPING}
            loading={loading}
            initialFormValue={initialFormValue!}
            setUpdatedFormValue={setFormValue}
            onCreate={(v) => onCreate(luneClient, v)}
            onUpdate={(id, v) => onUpdate(luneClient, id, v)}
            emissionId={id}
            layoutScrollRef={scrollRef}
            disableSubmitButton={
                !formValue?.method ||
                formValue.method === (OtherShipmentMethods.OTHER as any as ShippingMethod) ||
                formValue.method === (OtherShipmentMethods.IMO as any as ShippingMethod) ||
                !(formValue as ShippingEstimateRequestWithRoute).route
            }
        >
            <BannerMessage
                textSx={{ whiteSpace: 'pre-line' }}
                sx={{ marginBottom: '0' }}
                message={`Dashboard calculations are simplified and only a subset of functionality is available. \nTo use Lune's full calculations functionality and all of our emissions factors, please use the API.`}
            />
            <FormSpy>
                {({ form }: { form: FormApi }) => {
                    return (
                        <>
                            <CalculateEmissionsLabeledFormSection>
                                <Field
                                    name={'name'}
                                    placeholder={'Title (optional)'}
                                    component={FormInput}
                                />
                            </CalculateEmissionsLabeledFormSection>

                            <CalculateEmissionsLabeledFormSection
                                label={
                                    isLogisticsSiteMethod(formValue?.method)
                                        ? 'Logistics sites'
                                        : 'Shipment method'
                                }
                            >
                                <Field
                                    name={'method'}
                                    validate={(value) => handleMethodValidation(value)}
                                >
                                    {({ input: { onChange, value }, meta }: any) => (
                                        <Method
                                            value={value}
                                            onChange={(method) => {
                                                form.mutators.setValue('route', RouteType.DISTANCE)
                                                setRouteType(RouteType.DISTANCE)
                                                onChange(method)
                                            }}
                                            error={meta.touched && meta.error}
                                        />
                                    )}
                                </Field>
                            </CalculateEmissionsLabeledFormSection>
                            {formValue?.method && (
                                <>
                                    <CalculateEmissionsLabeledFormSection label={'Route'}>
                                        <Box data-testid={`route-select-dropdown-wrapper`}>
                                            <RouteTypes
                                                showAirportCode={
                                                    formValue.method ===
                                                        SimpleShippingMethod.PASSENGER_PLANE ||
                                                    formValue.method ===
                                                        SimpleShippingMethod.CARGO_PLANE
                                                }
                                                showPortCode={
                                                    ![
                                                        'SimpleShippingMethod',
                                                        'LogisticsSiteMethod',
                                                    ].includes(
                                                        getShippingMethodMainTypeFromValue(
                                                            formValue.method,
                                                        ),
                                                    )
                                                }
                                                value={routeType}
                                                onChange={(value) => {
                                                    form.mutators.setValue('route', undefined)
                                                    setRouteType(value)
                                                }}
                                            />
                                        </Box>
                                        {routeType === RouteType.DISTANCE && <RouteAsDistance />}
                                        {routeType === RouteType.ADDRESS && <RouteAsAddress />}
                                        {routeType === RouteType.GEO_COORDINATES && (
                                            <RouteAsCoordinates />
                                        )}
                                        {routeType === RouteType.SEAPORT && <RouteAsSeaportCode />}
                                        {routeType === RouteType.AIRPORT && <RouteAsAirportCode />}
                                    </CalculateEmissionsLabeledFormSection>
                                    <CalculateEmissionsLabeledFormSection label={'Load'}>
                                        <Load form={form} />
                                    </CalculateEmissionsLabeledFormSection>
                                </>
                            )}
                        </>
                    )
                }}
            </FormSpy>
        </CalculateEmissionsFormWrapper>
    )
}

export default LogisticsEmissions
