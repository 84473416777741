import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import type { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { FC } from 'react';

export enum StepState {
  PENDING,
  ACTIVE,
  COMPLETED,
  CANCELED,
}

export interface Step {
  label: string;
  state: StepState;
  icon?: JSX.Element;
  backgroundColor?: string;
}

export interface ProgressStepsProps {
  steps: Step[];
  sx?: SxProps;
}

const ProgressSteps: FC<ProgressStepsProps> = ({ steps, sx }) => {
  const setStateProps = (step: Step): Step => {
    switch (step.state) {
      case StepState.ACTIVE:
        return {
          ...step,
          backgroundColor: `Blue500`,
        };
      case StepState.COMPLETED:
        return {
          ...step,
          icon: <CheckIcon sx={{ width: '20px' }} />,
          backgroundColor: `Blue500`,
        };
      case StepState.PENDING:
        return {
          ...step,
          backgroundColor: `Blue500`,
        };
      case StepState.CANCELED:
        return {
          ...step,
          icon: <CloseIcon sx={{ width: '20px' }} />,
          backgroundColor: `Red500`,
        };
    }
  };

  return (
    <Box
      sx={{
        ...sx,
      }}
    >
      {steps
        .map((step) => setStateProps(step))
        .map((step, index) => (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                p: 1,
              }}
            >
              <Box
                sx={{
                  height: '48px',
                  width: '48px',
                  borderRadius: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'row',
                  mr: 3,
                }}
              >
                <Box
                  sx={{
                    color: 'white',
                    backgroundColor:
                      step.state === StepState.ACTIVE || step.state === StepState.PENDING
                        ? 'transparent'
                        : step.backgroundColor,
                    width: '24px',
                    height: '24px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '100%',
                    boxSizing: 'border-box',
                    border: `1px solid`,
                    borderColor: step.backgroundColor,
                    borderStyle: step.state === StepState.PENDING ? 'dashed' : 'solid',
                    position: 'relative',
                    ...(step.state === StepState.ACTIVE && {
                      '&:before': {
                        content: '""',
                        backgroundColor: step.backgroundColor,
                        animation: 'pulse 4000ms ease-in-out infinite',
                        willChange: 'transform',
                        transform: 'translateZ(0)',
                        backfaceVisibility: 'hidden',
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        bottom: 0,
                        top: 0,
                        borderRadius: '100%',
                        '@keyframes pulse': {
                          '0%': {
                            transform: 'scale(0.8)',
                          },
                          '50%': {
                            transform: 'scale(0.45)',
                          },
                          '100%': {
                            transform: 'scale(0.8)',
                          },
                        },
                      },
                    }),
                    ...(index !== 0 && {
                      '&:after': {
                        content: '""',
                        width: '1px',
                        height: '32px',
                        borderWidth: '0',
                        borderLeft: '1px solid',
                        borderColor: step.backgroundColor,
                        borderStyle: step.state === StepState.PENDING ? 'dashed' : 'solid',
                        margin: 'auto',
                        position: 'absolute',
                        top: '-36px',
                      },
                    }),
                  }}
                >
                  {step.icon}
                </Box>
              </Box>
              {step.label && (
                <Typography
                  variant={'body3'}
                  sx={{
                    color: `Grey900`,
                  }}
                >
                  {step.label}
                </Typography>
              )}
            </Box>
          </>
        ))}
    </Box>
  );
};

export default ProgressSteps;
