import { EmissionFactor } from '@lune-climate/lune'
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined'
import Co2OutlinedIcon from '@mui/icons-material/Co2Outlined'
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined'
import TagOutlinedIcon from '@mui/icons-material/TagOutlined'
import { Big } from 'big.js'
import { FC, useMemo } from 'react'

import { EmissionFactor as DapiEmissionFactor } from 'models/openDapi'
import { formatNumbers } from 'utils'
import ResultLabeledSectionAndTiles, {
    ResultTileProps,
} from 'views/CalculateEmissions/Shared/ResultLabeledSectionAndTiles'
import { emissionFactorLabel } from 'views/EmissionFactors/EmissionFactorsFiltersBox'

type UnitMappingType = {
    [key: string]: string[]
}

const unitsMapping: UnitMappingType = {
    distance: ['m', 'km', 'mi', 'nm'],
    currency: ['GBP', 'EUR', 'USD', 'SEK', 'CHF', 'PLN', 'DKK', 'NOK'],
    item: ['item'],
    time: ['hour'],
    area: ['ha', 'm2'],
    volume: ['l', 'm3', 'Ml'],
    energy: ['kWh', 'MJ', 'GJ'],
    mass: ['kg', 't', 'TEU'],
    'person-time': ['guest*night', 'fte*working_hour'],
    'room-time': ['room*night'],
    'person-distance': ['person*km'],
    'mass-time': ['kg*day'],
    'mass-distance': ['t*km', 'TEU*km'],
    'distance-time': ['m*year', 'km*year'],
    'area-time': ['m2*year'],
}

// exporting for unit tests
export const findUnitType = (unit: string): string | null => {
    for (const [type, units] of Object.entries(unitsMapping)) {
        if (units.includes(unit)) {
            return type
        }
    }
    return null
}

const EmissionFactorExplanation: FC<
    (
        | {
              emissionFactor: EmissionFactor & {
                  gasEmissions: null
              }
              amount: string
          }
        | {
              emissionFactor: DapiEmissionFactor
              amount: null
          }
    ) & {
        label?: string
        href?: string
    }
> = ({ emissionFactor, amount, label, href }) => {
    const { id, region, source, publicationYear, numeratorUnit, denominatorUnit, gasEmissions } =
        emissionFactor

    const formattedAmount = useMemo(() => {
        if (gasEmissions === 'emission_factor_gated') {
            return '**********'
        }

        function _format(intesity: string) {
            return Big(intesity).lt(0.001) ? formatNumbers(intesity, 6) : formatNumbers(intesity)
        }

        // The API has started returning `gasEmissions` since March 2024.
        return _format(amount === null ? gasEmissions!.co2E : amount)
    }, [amount, gasEmissions])

    const tiles: ResultTileProps[] = [
        {
            label: `${formattedAmount} ${numeratorUnit}CO₂e / ${denominatorUnit}`,
            icon: <Co2OutlinedIcon />,
        },
        {
            label: `Source: ${emissionFactorLabel[source]}, ${publicationYear}`,
            icon: <AutoStoriesOutlinedIcon />,
        },
        {
            label: `Region: ${region}`,
            icon: <PublicOutlinedIcon />,
        },
        {
            label: `ID: ${id}`,
            icon: <TagOutlinedIcon />,
            href,
        },
    ]
    return (
        <ResultLabeledSectionAndTiles
            label={label ? `Emission Factor: ${label}` : undefined}
            tiles={tiles}
            dataTestId={'emission-factor'}
        />
    )
}

export default EmissionFactorExplanation
