import { Bundle } from '@lune-climate/lune'
import { Input, ListItemLayout, LuneTheme, Table, Text } from '@lune-fe/lune-ui-lib'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLongOutlined'
import Box from '@mui/material/Box'
import { Big } from 'big.js'
import { FC, useMemo } from 'react'

import { calculateBundleSelectionTotalSum, formatNumbers, formatToCurrency } from 'utils'
import BundleListItemCell from 'views/Settings/BundlePicker/BundleListItemCell'
import { calculateAveragePrice } from 'views/Settings/BundlePicker/BundlePicker'

const PredefinedBundleAllocation: FC<{
    bundles: Bundle[]
    bundleSelection?: Record<string, number | string>
    allocationVolume?: Record<string, number>
}> = ({ bundles, bundleSelection = {}, allocationVolume }) => {
    const { palette } = LuneTheme
    const averageBundlePrice: Big = useMemo(
        () => calculateAveragePrice(bundles, bundleSelection),
        [bundleSelection, bundles],
    )

    const allocationVolumeTotalSum: Big = useMemo(() => {
        if (!allocationVolume) {
            return Big(0)
        }
        return calculateBundleSelectionTotalSum(allocationVolume, true)
    }, [allocationVolume])

    return (
        <Table.TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                {!!allocationVolume && (
                    <Table.TableHead>
                        <Table.TableRow>
                            <Table.TableCell colSize={7}>Bundle</Table.TableCell>
                            <Table.TableCell colSize={2} alignEnd align="right">
                                % of volume
                            </Table.TableCell>
                            <Table.TableCell colSize={2} alignEnd align="right">
                                Volume
                            </Table.TableCell>
                        </Table.TableRow>
                    </Table.TableHead>
                )}
                <Table.TableBody>
                    {bundles
                        .filter((b) => Object.keys(bundleSelection).includes(b.id))
                        .map((bundle) => {
                            const { id, name } = bundle
                            return (
                                <Table.TableRow key={id} data-testid={`bundle-${name}`}>
                                    <BundleListItemCell bundle={bundle} />
                                    <Table.TableCell colSize={3} align="right" alignEnd>
                                        <Input
                                            sx={{ maxWidth: '160px' }}
                                            percentage
                                            disabled
                                            value={bundleSelection[id].toString() || `0`}
                                        />
                                    </Table.TableCell>
                                    {!!allocationVolume && (
                                        <Table.TableCell colSize={2} align="right" alignEnd>
                                            <Text variant={`body2`}>
                                                {formatNumbers(allocationVolume[bundle.id]) || 0}{' '}
                                                tCO₂
                                            </Text>
                                        </Table.TableCell>
                                    )}
                                </Table.TableRow>
                            )
                        })}
                    <Table.TableRow>
                        <Table.TableCell colSize={7}>
                            {!allocationVolume && (
                                <ListItemLayout
                                    image={
                                        <Box
                                            sx={{
                                                width: `48px`,
                                                height: `48px`,
                                                display: `flex`,
                                                justifyContent: `center`,
                                                alignItems: `center`,
                                                border: `thin solid`,
                                                borderColor: palette.Grey300,
                                                borderRadius: `8px`,
                                            }}
                                        >
                                            <ReceiptLongIcon />
                                        </Box>
                                    }
                                    title={
                                        <Text variant={'body2'} color={palette.Grey900}>
                                            Total
                                        </Text>
                                    }
                                    subTitle={`Avg. ${formatToCurrency(
                                        averageBundlePrice.toString(),
                                        bundles[0]?.currency,
                                    )} / tCO₂`}
                                />
                            )}
                        </Table.TableCell>
                        <Table.TableCell colSize={3} align="right" alignEnd>
                            <Text variant={`body2`}>100%</Text>
                        </Table.TableCell>
                        {!!allocationVolume && (
                            <Table.TableCell colSize={2} align="right" alignEnd>
                                <Text variant={`body2`} data-testid={`allocation-volume`}>
                                    {formatNumbers(allocationVolumeTotalSum.toString())} tCO₂
                                </Text>
                            </Table.TableCell>
                        )}
                    </Table.TableRow>
                </Table.TableBody>
            </Table>
        </Table.TableContainer>
    )
}

export default PredefinedBundleAllocation
