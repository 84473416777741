export const luneAssetsToDynamicAssets = (imageUrl: string, width: number, height: number) => {
  if (imageUrl.includes('https://assets.lune.co/')) {
    const newUrl = imageUrl.replace('https://assets.lune.co/', 'https://dynamic-assets.lune.co/');
    return `${newUrl}?width=${width}&height=${height}`;
  } else {
    return imageUrl;
  }
};

export default luneAssetsToDynamicAssets;
