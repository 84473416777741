import { SingleShippingEmissionEstimate } from '@lune-climate/lune'
import { SuccessResponse } from '@lune-climate/lune/esm/core/SuccessResponse'
import { FC, RefObject, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useLuneClient } from 'hooks/useLuneClient'
import { EstimateTypeEnum, ResultsWrapper } from 'views/CalculateEmissions'
import { resolvedLegToApiLeg } from 'views/CalculateEmissions/Logistics/LogisticsUtils'
import SingleLegEstimate from 'views/CalculateEmissions/Logistics/SingleLegEstimate'
import MultiLegEstimate from 'views/CalculateEmissions/MultiLegLogistics/MultiLegEstimate'
import { Leg } from 'views/CalculateEmissions/MultiLegLogistics/MultiLegLogisticsResults'

const LogisticsResults: FC<{ scrollRef: RefObject<HTMLDivElement> }> = ({ scrollRef }) => {
    const { id } = useParams<{ id: string }>()
    const [estimate, setEstimate] = useState<SuccessResponse<SingleShippingEmissionEstimate>>()
    const [resolvedLegs, setResolvedLegs] = useState<Leg[]>([])
    const luneClient = useLuneClient()

    useEffect(() => {
        if (id) {
            luneClient.getShippingEstimate(id).then((result) => {
                if (result.isOk()) {
                    const estimate = result.value
                    if (estimate.resolvedLegs) {
                        setResolvedLegs(
                            estimate.resolvedLegs.map((resolvedLeg) => {
                                return resolvedLegToApiLeg(
                                    resolvedLeg,
                                    result.value.convertedShipment,
                                    result.value.shipmentConversionMethod,
                                )
                            }),
                        )
                    }
                }
                return result.map(setEstimate)
            })
        }
    }, [id, luneClient])

    return (
        <ResultsWrapper
            scrollRef={scrollRef}
            title={estimate?.request.name}
            emissionType={EstimateTypeEnum.SHIPPING}
            calculatedAmount={estimate?.mass.amount!}
            loading={!estimate?.request}
        >
            {estimate?.request && (
                <>
                    {resolvedLegs.length ? (
                        <MultiLegEstimate
                            legs={resolvedLegs}
                            load={estimate.request.shipment}
                            meta={estimate._meta}
                            requestRoute={
                                'route' in estimate.request ? estimate.request.route : undefined
                            }
                        />
                    ) : (
                        <SingleLegEstimate estimate={estimate} />
                    )}
                </>
            )}
        </ResultsWrapper>
    )
}

export default LogisticsResults
