import { styled } from '@mui/system';
import React from 'react';

import { fontFamily, LuneTheme } from '../theme';

const FieldValidationError = ({ error }: { error: string }): JSX.Element => {
  const StyledInput = styled(`div`)(
    LuneTheme.unstable_sx({
      fontFamily,
      fontSize: `0.8rem`,
      mt: 1,
      color: `red`,
    }),
  );
  return <StyledInput>{error}</StyledInput>;
};

export default FieldValidationError;
