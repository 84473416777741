import { Bundle, BundlePercentage, BundlePortfolio, OrderBundle } from '@lune-climate/lune'
import { LuneTheme } from '@lune-fe/lune-ui-lib'
import { Link } from '@mui/material'
import { sortBy } from 'lodash'
import { FC, useCallback, useEffect, useMemo } from 'react'

import useBundles from 'hooks/useBundles'
import useBuyOffsetsState from 'hooks/useBuyOffsetsState'
import { formatToCurrency } from 'utils'
import { Allocation, PortfolioProps } from 'views/BuyOffsets/BuyOffsetsTypes'
import { bundleSelectionToRecord, calculateAveragePrice } from 'views/BuyOffsets/BuyOffsetsUtils'
import CartModal from 'views/BuyOffsets/CartModal/CartModal'
import PortfolioButton from 'views/BuyOffsets/PortfolioButton'

const BuyPredefinedPortfolio: FC<{ predefinedPortfolios: BundlePortfolio[] }> = ({
    predefinedPortfolios,
}) => {
    const { palette } = LuneTheme
    const { bundles } = useBundles()
    const { buyOffsetProps, setBuyOffsetProps } = useBuyOffsetsState()
    const { quote, amount, orderType, allocation, selectedPortfolioId } = buyOffsetProps

    const getPortfolioDescriptions = useCallback(
        (identifier: string) => {
            switch (identifier) {
                // Oxford Offsetting Principles Portfolio
                case 'oxf': {
                    return (
                        <span>
                            Following Oxford University guidelines to decarbonisation.{' '}
                            <Link
                                sx={{
                                    fontStyle: 'normal',
                                    color: 'inherit',
                                    textDecoration: 'underline !important',
                                    '&:hover': {
                                        color: palette.Grey900,
                                    },
                                }}
                                onClick={(e) => e.stopPropagation()}
                                href={
                                    'https://lune.co/blog/the-oxford-offsetting-principles-a-framework-to-maximise-the-impact-of-business-carbon-offsetting/'
                                }
                                target={'_blank'}
                            >
                                Learn more.
                            </Link>
                        </span>
                    )
                }
                // Innovative Carbon Removal Portfolio
                case 'inv': {
                    return 'Projects with both short-lived and long-lived carbon storage'
                }
                // Nature-based Portfolio
                case 'ntr': {
                    return 'Significant biodiversity and other co-benefits'
                }
                // Frontier Carbon Removal Portfolio
                case 'frt': {
                    return 'A $1bn+ collective corporate commitment to a permanent carbon removal portfolio'
                }
            }
        },
        [palette.Grey900],
    )

    const buildPortfoliosWithProps = useCallback(() => {
        const portfolios: PortfolioProps[] = predefinedPortfolios.map((predefinedPortfolio) => {
            /// bundles included in portfolio, used to get images
            const sortedBundleSelection = sortBy(
                predefinedPortfolio.bundleSelection,
                (bundle: BundlePercentage) => bundles.find((b) => b.id === bundle.bundleId)!.name,
            )
            const portfolioBundles: Bundle[] = sortedBundleSelection.map(
                (bundle) => bundles.find((b: Bundle) => b.id === bundle.bundleId)!,
            )
            return {
                id: predefinedPortfolio.id,
                title: predefinedPortfolio.label,
                description: getPortfolioDescriptions(predefinedPortfolio.identifier),
                images: portfolioBundles.map((p) => p.primaryImage || ''),
                price: calculateAveragePrice(
                    bundles,
                    bundleSelectionToRecord(predefinedPortfolio.bundleSelection),
                ),
                bundlesSelection: sortedBundleSelection,
            }
        })
        return portfolios
    }, [bundles, getPortfolioDescriptions, predefinedPortfolios])

    const portfolios = useMemo(() => {
        if (bundles.length && predefinedPortfolios.length) {
            return buildPortfoliosWithProps()
        }
    }, [buildPortfoliosWithProps, bundles.length, predefinedPortfolios.length])

    const getVolumePerBundle = useCallback(
        (bundleId: string) => {
            const calculatedVolume = Number(
                quote?.bundles.find((b: OrderBundle) => b.bundleId === bundleId)?.quantity,
            )
            return !amount || amount === ''
                ? 0
                : quote
                  ? !isNaN(calculatedVolume)
                      ? calculatedVolume
                      : 0
                  : 0
        },
        [amount, quote],
    )

    useEffect(() => {
        const selectedPortfolio = predefinedPortfolios.find((b) => b.id === selectedPortfolioId)
        if (!selectedPortfolio) {
            return
        }
        const allocation: Allocation[] = selectedPortfolio.bundleSelection.map((bundle) => {
            return {
                bundle: bundles.find((b) => b.id === bundle.bundleId)!,
                percentage: bundle.percentage.toString(),
                volume: getVolumePerBundle(bundle.bundleId),
            }
        })
        setBuyOffsetProps({
            allocation,
            selectedPortfolioId: selectedPortfolio.id,
            orderTotalAmount: allocation.reduce(
                (a, b) => Number(a || 0) + Number(b.volume || 0),
                0,
            ),
            orderTotalPercentage: allocation.reduce(
                (a, b) => Number(a || 0) + Number(b.percentage || 0),
                0,
            ),
            error: undefined,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPortfolioId, amount, orderType, quote])

    return (
        <>
            {portfolios?.map((portfolio) => (
                <PortfolioButton
                    key={portfolio.id}
                    title={portfolio.title}
                    subtitle={portfolio.description}
                    price={`${formatToCurrency(
                        portfolio.price.toString(),
                        bundles[0]?.currency,
                    )} / tCO₂`}
                    images={portfolio.images}
                    onClick={() => {
                        setBuyOffsetProps({
                            selectedPortfolioId: portfolio.id,
                        })
                    }}
                />
            ))}
            {selectedPortfolioId && allocation.length && (
                <CartModal
                    onClose={() => {
                        setBuyOffsetProps({
                            allocation: [],
                            quote: undefined,
                            error: undefined,
                            insufficientBundlesError: undefined,
                            selectedPortfolioId: undefined,
                        })
                    }}
                    customizedPortfolio={false}
                    allocation={allocation}
                />
            )}
        </>
    )
}

export default BuyPredefinedPortfolio
