import type { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Text from '@mui/material/Typography';
import React from 'react';

/**
 * A "standard" Layout for a title/subTitle-type Header of a Modal Dialog
 * Only meant to be passed as "header" prop to ui-lib's Modal (help with DRY)
 * @param title
 * @param subTitle - optional
 * @param sx - optional custom style
 * @constructor
 */
const StandardModalHeader = ({ title, subTitle, sx }: { title: string; subTitle?: string; sx?: SxProps }) => (
  <Box
    sx={{
      display: `flex`,
      justifyContent: `center`,
      alignItems: `center`,
      flexDirection: `column`,
      ...sx,
    }}
  >
    <Text
      variant={'h5'}
      sx={{
        mb: 2,
      }}
    >
      {title}
    </Text>
    <Text
      variant={'body3'}
      sx={{
        color: `Grey600`,
      }}
    >
      {subTitle}
    </Text>
  </Box>
);
export default StandardModalHeader;
