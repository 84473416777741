import CloseIcon from '@mui/icons-material/Close';
import type { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import React, { FC, useState } from 'react';

import Button from './Button';
import Input from './Input';

const SearchInput: FC<{
  onSearch: (searchKey: string) => void;
  onClear: () => void;
  sx?: SxProps;
}> = ({ onSearch, onClear, sx }) => {
  const [value, setValue] = useState('');

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <Input
        value={value}
        sx={{
          mb: 9,
          ...sx,
        }}
        wide
        placeholder={'Search'}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onSearch(value);
          }
        }}
        onChange={(e) => setValue(e.target.value)}
      />
      {value && value.length && (
        <Button
          sx={{
            position: 'absolute',
            right: '4px',
            top: '4px',
          }}
          variant={'text'}
          iconButton
          leftIcon={<CloseIcon />}
          onClick={() => {
            setValue('');
            onClear();
          }}
        />
      )}
    </Box>
  );
};

export default SearchInput;
