import { ListItemLayout, LuneTheme, Table, Text } from '@lune-fe/lune-ui-lib'
import TollOutlinedIcon from '@mui/icons-material/TollOutlined'
import Box from '@mui/material/Box'

import useAccounts from 'hooks/useAccounts'
import { getCurrencySymbol } from 'models/currency'
import { formatNumbers } from 'utils'
import CarbonCreditRetirement, {
    RetirementTimeframe,
} from 'views/Projects/Project/CarbonCredits/CarbonCreditRetirement'
import CarbonCreditType, {
    OffsetDelivery,
} from 'views/Projects/Project/CarbonCredits/CarbonCreditType'

const CarbonCredits = ({
    offsetDeliveryType,
    offsetRetirementType,
    availableCredits,
}: {
    offsetDeliveryType: OffsetDelivery
    offsetRetirementType: RetirementTimeframe
    availableCredits?: {
        vintage: string
        quantity: number
        price: string
    }[]
}) => {
    const { activeAccount } = useAccounts()
    const { palette } = LuneTheme

    return (
        <Box
            sx={{
                display: `flex`,
                gap: 1,
                flexWrap: `wrap`,
                width: `100%`,
            }}
        >
            <Box sx={{ display: `flex`, gap: 1, flex: 1 }}>
                <CarbonCreditType offsetDeliveryType={offsetDeliveryType} />
                <CarbonCreditRetirement retirementTimeframe={offsetRetirementType} />
            </Box>
            {availableCredits && (
                <Box
                    sx={{
                        background: palette.Grey50,
                        flex: 1,
                        borderRadius: `16px`,
                        p: 2,
                    }}
                >
                    <Table.TableContainer>
                        <Table aria-label="simple table">
                            <Table.TableHead>
                                <Table.TableRow>
                                    <Table.TableCell colSize={8}>
                                        Credits available for sale
                                    </Table.TableCell>
                                    <Table.TableCell colSize={4}>
                                        <Box
                                            sx={{
                                                width: `100%`,
                                                textAlign: `center`,
                                            }}
                                        >
                                            Vintage
                                        </Box>
                                    </Table.TableCell>
                                    <Table.TableCell
                                        colSize={4}
                                        alignEnd
                                        align="right"
                                        sx={{
                                            whiteSpace: `nowrap`,
                                        }}
                                    >
                                        {activeAccount?.currency &&
                                            getCurrencySymbol(activeAccount.currency)}{' '}
                                        / tCO₂
                                    </Table.TableCell>
                                </Table.TableRow>
                            </Table.TableHead>

                            <Table.TableBody>
                                {availableCredits.map(({ price, quantity, vintage }, index) => (
                                    <Table.TableRow key={`credits-${index}`}>
                                        <Table.TableCell colSize={8}>
                                            <ListItemLayout
                                                image={
                                                    <TollOutlinedIcon
                                                        sx={{
                                                            width: '24px',
                                                            height: '24px',
                                                            color: palette.Grey900,
                                                        }}
                                                    />
                                                }
                                                title={
                                                    <Text
                                                        variant={`body3`}
                                                        sx={{
                                                            color: palette.Grey900,
                                                        }}
                                                    >
                                                        {formatNumbers(quantity)}
                                                    </Text>
                                                }
                                            />
                                        </Table.TableCell>
                                        <Table.TableCell colSize={4} alignEnd>
                                            <Text
                                                variant={'body3'}
                                                sx={{
                                                    textAlign: `center`,
                                                    width: `100%`,
                                                    color: palette.Grey900,
                                                }}
                                            >
                                                {vintage}
                                            </Text>
                                        </Table.TableCell>
                                        <Table.TableCell colSize={4} alignEnd align="right">
                                            <Text
                                                variant={'body3'}
                                                sx={{
                                                    color: palette.Grey900,
                                                }}
                                            >
                                                {price}
                                            </Text>
                                        </Table.TableCell>
                                    </Table.TableRow>
                                ))}
                            </Table.TableBody>
                        </Table>
                    </Table.TableContainer>
                </Box>
            )}
        </Box>
    )
}

export default CarbonCredits
