import React, { FC, useMemo } from 'react';

import AutocompleteSelect from './AutocompleteSelect';
import seaportList from './seaportList';

type SeaportType = {
  label: string;
  value: string;
};

export interface Props {
  value: string | undefined;
  onChange: (value: string | undefined) => void;
  error?: string;
  placeholder?: string;
  disabled?: boolean;
}

const SeaportPicker: FC<Props> = ({ value, onChange, disabled, error, placeholder = 'Port', ...rest }) => {
  const selectedValue = useMemo(() => {
    const port = seaportList.find((port) => port[0] === value);
    if (port) {
      const [locode, name] = port;
      return { label: `${name}, ${locode}`, value: locode };
    } else {
      return null;
    }
  }, [value]);

  return (
    <AutocompleteSelect
      error={error}
      label={placeholder}
      disabled={disabled}
      items={seaportList.map(([locode, name]) => ({
        value: locode,
        label: `${name}, ${locode}`,
      }))}
      onChange={(port: SeaportType) => onChange(port.value)}
      value={selectedValue as SeaportType}
      {...rest}
    />
  );
};

export default SeaportPicker;
