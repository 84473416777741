import { LuneTheme } from '@lune-fe/lune-ui-lib'
import { Chart as ChartJS } from 'chart.js'
import { abbreviateNumber } from 'js-abbreviation-number'

import { formatNumbers } from 'utils'
import { CarbonStorageOverTimeProps } from 'views/Projects/Project/CarbonStorageOverTime/Chart'

export interface Dataset {
    date: string
    quantity: string
    value: string
}

const { palette, typography } = LuneTheme

// Utils
export const chartColors = {
    buffer: '#FDF07D',
    assumedLeakage: '#C4EB9E',
    creditsIssued: '#69DFC9',
    carbonStock: '#212121',
    baseline: '#0099F1',
}

// Custom tooltip
const getOrCreateTooltip = (chart: ChartJS) => {
    if (chart.canvas.parentNode !== null) {
        let tooltipEl = chart.canvas.parentNode.querySelector('div')
        if (!tooltipEl) {
            tooltipEl = document.createElement('div')
            tooltipEl.style.background = 'rgba(0, 0, 0)'
            tooltipEl.style.borderRadius = '4px'
            tooltipEl.style.color = 'white'
            tooltipEl.style.opacity = '1'
            tooltipEl.style.pointerEvents = 'none'
            tooltipEl.style.position = 'absolute'
            tooltipEl.style.transform = 'translate(-50%, 0)'
            tooltipEl.style.transition = 'all .1s ease'
            tooltipEl.style.fontSize = '12px'
            tooltipEl.style.marginTop = '-15px'
            tooltipEl.style.transform = 'translate(-50%, -100%)'
            tooltipEl.style.fontFamily = typography.fontFamily as any
            tooltipEl.style.minWidth = '100px'

            const caret = document.createElement('span')
            caret.style.width = '8px'
            caret.style.height = '8px'
            caret.style.backgroundColor = 'black'
            caret.style.position = 'absolute'
            caret.style.bottom = '-4px'
            caret.style.left = '0'
            caret.style.right = '0'
            caret.style.margin = 'auto'
            caret.style.zIndex = '-1'
            caret.style.transform = 'rotate(45deg)'

            const dot = document.createElement('span')
            dot.style.width = '8px'
            dot.style.height = '8px'
            dot.style.backgroundColor = 'black'
            dot.style.position = 'absolute'
            dot.style.bottom = '-18px'
            dot.style.left = '0'
            dot.style.right = '0'
            dot.style.margin = 'auto'
            dot.style.borderRadius = '100%'

            const table = document.createElement('table')
            table.style.margin = '0'

            tooltipEl.appendChild(table)
            tooltipEl.appendChild(caret)
            tooltipEl.appendChild(dot)
            chart.canvas.parentNode.appendChild(tooltipEl)
        }
        return tooltipEl
    }
}

const externalTooltipHandler = (context: any, chartData: CarbonStorageOverTimeProps[]) => {
    // Tooltip Element
    const { chart, tooltip } = context
    const year = tooltip.dataPoints[0].label
    const datapointByYear: CarbonStorageOverTimeProps = chartData.find(
        (d) => d.year.toString() === year,
    )!

    const tooltipEl = getOrCreateTooltip(chart)

    // Hide if no tooltip
    if (tooltipEl && tooltip.opacity === 0) {
        tooltipEl.style.opacity = '0'
        return
    }

    // Set Text
    if (tooltip.body) {
        const titleLines = tooltip.title || []
        const head = document.createElement('div')
        titleLines.forEach((title: string) => {
            const titleDiv = document.createElement('div')
            titleDiv.style.fontWeight = 'bold'
            titleDiv.style.marginBottom = '8px'
            const text = document.createTextNode(title)
            titleDiv.appendChild(text)
            head.appendChild(titleDiv)
        })

        const tableRoot = tooltipEl?.querySelector('table')
        const body = document.createElement('div')
        body.style.fontSize = '12px'
        body.style.fontFamily = typography.fontFamily as string

        // Remove old children
        while (tableRoot?.firstChild) {
            tableRoot.firstChild.remove()
        }

        // Add new children
        const baseline = document.createElement('div')
        baseline.appendChild(
            document.createTextNode(
                `Baseline projection: ${formatNumbers(datapointByYear.baseline)} tCO₂`,
            ),
        )
        baseline.style.marginBottom = '2px'
        const carbonStock = document.createElement('div')
        carbonStock.appendChild(
            document.createTextNode(
                `Project carbon stock: ${formatNumbers(datapointByYear.carbonStock)} tCO₂`,
            ),
        )
        carbonStock.style.marginBottom = '2px'
        const buffer = document.createElement('div')
        buffer.appendChild(
            document.createTextNode(`Buffer: ${formatNumbers(datapointByYear.buffer)} tCO₂`),
        )
        buffer.style.marginBottom = '2px'
        const assumedLeakage = document.createElement('div')
        assumedLeakage.appendChild(
            document.createTextNode(
                `Assumed leakage: ${formatNumbers(datapointByYear.assumedLeakage)} tCO₂`,
            ),
        )
        assumedLeakage.style.marginBottom = '2px'
        const creditsIssued = document.createElement('div')
        creditsIssued.appendChild(
            document.createTextNode(
                `Credits issued: ${formatNumbers(datapointByYear.creditsIssued)} tCO₂`,
            ),
        )
        creditsIssued.style.marginBottom = '2px'

        body.appendChild(head)
        body.appendChild(carbonStock)
        body.appendChild(baseline)
        body.appendChild(buffer)
        body.appendChild(assumedLeakage)
        body.appendChild(creditsIssued)
        tableRoot?.appendChild(body)
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas

    // Display, position, and set styles for font
    if (tooltipEl) {
        tooltipEl.style.opacity = '1'
        tooltipEl.style.left = positionX + tooltip.caretX + 'px'
        tooltipEl.style.top = positionY + tooltip.caretY + 'px'
        tooltipEl.style.font = typography.fontFamily as any
        tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px'
    }
}

export const chartAreaBorder = {
    id: 'chartAreaBorder',
    beforeDraw(chart: any, args: any, options: any) {
        const {
            ctx,
            chartArea: { left, top, width, height },
        } = chart
        ctx.save()
        ctx.strokeStyle = options.borderColor
        ctx.lineWidth = options.borderWidth
        ctx.setLineDash(options.borderDash || [])
        ctx.lineDashOffset = options.borderDashOffset
        ctx.strokeRect(left, top, width, height)
        ctx.restore()
    },
}

// Chart options
export const chartOptions = (chartData: CarbonStorageOverTimeProps[]) => {
    return {
        maintainAspectRatio: false,
        elements: {
            point: {
                pointBorderColor: 'white',
                pointBorderWidth: '2',
                radius: 4,
            },
        },
        layout: {
            autoPadding: false,
            padding: {
                left: -20,
            },
        },
        scales: {
            y: {
                grid: {
                    tickLength: 0,
                    drawBorder: false,
                    color: ['#e9e9e9'],
                    tickBorderDashOffset: 0,
                },
                ticks: {
                    mirror: true,
                    z: 9 as const,
                    padding: 10 as const,
                    showLabelBackdrop: true,
                    backdropColor: palette.White,
                    backdropPadding: 4,
                    textStrokeWidth: 10,
                    color: palette.Grey900,
                    font: {
                        family: typography.fontFamily,
                    },
                    callback: (value: any, index: number, ticks: any) => {
                        if (index === 0 || index === ticks.length - 1) {
                            return null
                        } else {
                            return abbreviateNumber(value)
                        }
                    },
                },
            },
            x: {
                stacked: true,
                ticks: {
                    beginAtZero: true,
                    font: {
                        family: typography.fontFamily,
                    },
                    color: palette.Grey900,
                },
                grid: {
                    tickLength: 10,
                    tickColor: palette.White,
                    drawBorder: false,
                    color: ['#e9e9e9'],
                },
            },
        },
        plugins: {
            paddingBelowLegends: '30px',
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false,
                position: 'nearest' as const,
                external: (context: any) => externalTooltipHandler(context, chartData),
            },
            chartAreaBorder: {
                borderColor: '#e9e9e9',
                borderWidth: 1,
            },
        },
    }
}
