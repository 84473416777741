import { Text } from '@lune-fe/lune-ui-lib'
import { SliderValueLabelProps } from '@mui/material'
import Box from '@mui/material/Box'
import BaseSlider, { sliderClasses } from '@mui/material/Slider'
import { styled } from '@mui/system'
import { FC, useCallback } from 'react'
import { debounce } from 'throttle-debounce'

const Slider = styled(BaseSlider)(
    () => `
  background: linear-gradient(270deg, #FF27A5 0%, #F5F5F5 100%);
  height: 4px;
  width: 100%;
  padding: 0;
  display: inline-flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  touch-action: none;
  border-radius: 20px;
  -webkit-tap-highlight-color: transparent;

  & .${sliderClasses.thumb} {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    margin-left: -24px;
    box-sizing: border-box;
    border-radius: 24px;
    outline: 0;
    background-color: white;
    width: 52px;
    height: 30px;
  }
`,
)

function SliderValueLabel({ children }: SliderValueLabelProps) {
    return (
        <Box
            sx={{
                background: 'red',
                borderRadius: '24px',
                outline: 0,
                backgroundColor: '#212121',
                width: '44px',
                textAlign: 'center',
                color: 'white',
            }}
        >
            <Text variant={'caption'}>{children}</Text>
        </Box>
    )
}

const MapYearlyLegend: FC<{
    startYear: number
    endYear: number
    setYear: (year: number) => void
}> = ({ startYear, endYear, setYear }) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onYearChange = useCallback(
        debounce(
            300,
            (year: number) => {
                setYear(year)
            },
            { atBegin: false },
        ),
        [],
    )

    return (
        <Box
            sx={{
                position: 'absolute',
                left: '24px',
                right: '24px',
                bottom: '32px',
            }}
        >
            <Box
                sx={{
                    background: 'white',
                    borderRadius: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '30px',
                    padding: '0 32px',
                }}
            >
                <Slider
                    slots={{ valueLabel: SliderValueLabel }}
                    min={startYear}
                    max={endYear}
                    defaultValue={endYear}
                    // Slider can emit a number or Array of numbers
                    // but in our case it will always number because we're using a single value not a range
                    onChange={(_, newValue) => onYearChange(newValue as number)}
                    step={1}
                />
            </Box>
        </Box>
    )
}

export default MapYearlyLegend
