import type { SxProps } from '@mui/material';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/system';
import React, { useMemo } from 'react';

import { fontFamily, LuneTheme } from '../theme';
import FieldValidationError from './FieldValidationError';

const Textarea = ({
  id,
  name,
  disabled,
  error,
  placeholder,
  onChange,
  onBlur,
  onFocus,
  className,
  wide,
  rows,
  maxRows,
  autofocus,
  inputRef,
  sx,
  singleLine,
  ...rest
}: {
  id?: string;
  disabled?: boolean;
  error?: string | boolean;
  placeholder?: string;
  name?: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
  onFocus?: React.FocusEventHandler<HTMLTextAreaElement>;
  value?: string;
  className?: string;
  wide?: boolean;
  rows?: number;
  maxRows?: number;
  autofocus?: boolean;
  inputRef?: React.Ref<any>;
  sx?: SxProps;
  singleLine?: boolean;
}) => {
  const { palette, spacing } = LuneTheme;

  const StyledTextarea = useMemo(
    () =>
      styled(TextField)(
        LuneTheme.unstable_sx({
          ...(wide && {
            width: 1,
          }),
          '> div': {
            fontFamily,
            minHeight: spacing(7),
            height: 'auto',
            fontSize: `1rem`,
            borderRadius: `8px`,
            p: 1.5,
            color: `Grey900`,
            backgroundColor: `Grey100`,
            ...(wide && {
              width: '100%',
            }),
            '&:hover': {
              backgroundColor: `Grey300`,
            },
            '&:focus-within': {
              backgroundColor: `Grey100`,
            },
            '*::placeholder': {
              color: `${palette.Grey700} !important`,
              opacity: '1 !important',
            },
          },
          '&.error': {
            '> div': {
              backgroundColor: `Red50`,
            },
          },
          '&.disabled': {
            cursor: 'not-allowed',
            '> div': {
              backgroundColor: `Grey100`,
              color: `Grey400`,
              pointerEvents: 'none',
              textarea: {
                color: `Grey400`,
                textFillColor: `${palette.Grey400} !important`,
              },
              '*::placeholder': {
                color: `${palette.Grey400} !important`,
              },
            },
          },
          fieldset: {
            px: 0,
            border: `none`,
          },
        }),
      ),
    [wide],
  );

  return (
    <>
      <StyledTextarea
        multiline={!singleLine}
        maxRows={maxRows}
        rows={rows}
        autoFocus={autofocus}
        inputRef={inputRef}
        {...(id && { id })}
        {...(placeholder && { placeholder })}
        {...(onChange && { onChange })}
        {...(onFocus && { onFocus })}
        {...(onBlur && { onBlur })}
        {...(name && { name })}
        className={`${className} ${disabled ? 'disabled' : error ? 'error' : ''}`}
        disabled={disabled ?? false}
        sx={sx}
        {...rest}
      />
      {typeof error === `string` && <FieldValidationError error={error} />}
    </>
  );
};

export default Textarea;
