import type { SxProps } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import React, { useMemo } from 'react';

import ListItemLayout from './ListItemLayout';

const MemberInfoCard = ({
  name,
  suffix,
  email,
  sx,
  src,
}: {
  src?: string;
  name: string;
  suffix?: string;
  email: string;
  sx?: SxProps;
}) => {
  const initials = useMemo(() => {
    if (!name) {
      return ``;
    }
    const hasTokens = name.indexOf(' ') !== -1;
    return name.substring(0, hasTokens ? 1 : 2) + (hasTokens ? name.charAt(name.lastIndexOf(' ') + 1) : '');
  }, [name]);

  const title = useMemo(() => {
    return suffix ? `${name} ${suffix}` : name;
  }, [name, suffix]);

  return <ListItemLayout sx={sx} image={<Avatar src={src}>{initials}</Avatar>} title={title} subTitle={email} />;
};

export default MemberInfoCard;
