import { LuneTheme, Tooltip } from '@lune-fe/lune-ui-lib'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Box } from '@mui/material'

import { ExplanationWrapper } from 'views/CalculateEmissions/EmissionsExplanation/ExplanationSteps'

const StepWithInfoIcon = ({
    index,
    step,
    info,
}: {
    index: number
    step: { description?: JSX.Element }
    info: string | JSX.Element | null
}) => {
    const { palette } = LuneTheme
    return (
        <>
            {info ? (
                <>
                    <Box
                        key={`emission-explanation-tile-${index + 1}`}
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >
                        <ExplanationWrapper>{step.description}</ExplanationWrapper>
                        <Tooltip title={info} placement="top">
                            <Box
                                sx={{
                                    height: '48px',
                                    width: '48px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                    borderRadius: '24px',
                                    '&:hover': { backgroundColor: palette.Grey100 },
                                }}
                            >
                                <InfoOutlinedIcon sx={{ color: palette.Grey900 }} />
                            </Box>
                        </Tooltip>
                    </Box>
                </>
            ) : (
                <ExplanationWrapper key={`emission-explanation-tile-${index + 1}`}>
                    {step.description}
                </ExplanationWrapper>
            )}
        </>
    )
}

export default StepWithInfoIcon
