import {
    CalculatedRoute,
    ConvertedShipment,
    Distance,
    DistanceCalculationDetails,
    DistanceCalculationMethod,
    EmissionFactorWithGasEmissions,
    LogisticsSiteMethod,
    Mass,
    MultiLegShippingEmissionEstimate,
    NullEnum,
    RoadMethodologyDetails,
    ShipmentConversionMethod,
    ShippingCountryCode,
    ShippingMethod,
    ShippingRoute,
    VesselInferenceMethod,
} from '@lune-climate/lune'
import { FC, RefObject, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import useMultiLegShipping from 'hooks/useMultiLegShipping'
import { EstimateTypeEnum, ResultsWrapper } from 'views/CalculateEmissions'
import { MethodologyDetails } from 'views/CalculateEmissions/EmissionsExplanation/EmissionsExplanationSection'
import { resolvedLegToApiLeg } from 'views/CalculateEmissions/Logistics/LogisticsUtils'
import MultiLegEstimate from 'views/CalculateEmissions/MultiLegLogistics/MultiLegEstimate'

export interface Leg {
    method: ShippingMethod | LogisticsSiteMethod
    route?: ShippingRoute
    countryCode?: ShippingCountryCode
    distance?: Distance
    adjustedDistance?: Distance
    distanceCalculationMethod: DistanceCalculationMethod | NullEnum
    distanceCalculationDetails: DistanceCalculationDetails | NullEnum
    convertedShipment: ConvertedShipment | NullEnum
    shipmentConversionMethod: ShipmentConversionMethod | NullEnum
    methodology: string[]
    emissionFactor: EmissionFactorWithGasEmissions
    mass: Mass
    responseRoute: CalculatedRoute | null
    methodologyDetails: MethodologyDetails | RoadMethodologyDetails | null
    vesselInferenceDetails?: VesselInferenceMethod | null
}

const flattenLegs = (estimate: MultiLegShippingEmissionEstimate): Leg[] => {
    const legs = []
    const requestLegs = estimate.request.legs
    const responseLegs = estimate.legs

    for (let i = 0; i < responseLegs.length; i++) {
        const responseLeg = responseLegs[i]

        if (responseLeg.resolvedLegs) {
            legs.push(
                ...responseLeg.resolvedLegs.map((resolvedLeg) => {
                    return resolvedLegToApiLeg(
                        resolvedLeg,
                        responseLeg.convertedShipment,
                        responseLeg.shipmentConversionMethod,
                    )
                }),
            )
            continue
        }
        const { route: responseRoute, ...responseWithoutRoute } = responseLeg
        legs.push({
            ...responseWithoutRoute,
            ...requestLegs[i],
            responseRoute,
        })
    }
    return legs
}

const MultiLegLogisticsResults: FC<{ scrollRef: RefObject<HTMLDivElement> }> = ({ scrollRef }) => {
    const { id } = useParams<{ id: string }>()
    const { estimate, loadingEstimate } = useMultiLegShipping(id)

    const legs: Leg[] = useMemo(() => {
        if (!estimate?.legs) {
            return []
        }
        return flattenLegs(estimate)
    }, [estimate])

    return (
        <ResultsWrapper
            scrollRef={scrollRef}
            title={estimate?.request.name}
            emissionType={EstimateTypeEnum.MULTI_LEG_SHIPPING}
            calculatedAmount={estimate?.mass.amount!}
            loading={loadingEstimate}
        >
            {estimate?.request && (
                <MultiLegEstimate
                    legs={legs}
                    load={estimate.request.shipment}
                    meta={estimate._meta}
                />
            )}
        </ResultsWrapper>
    )
}

export default MultiLegLogisticsResults
