import { Big } from 'big.js'

import useAccounts from 'hooks/useAccounts'
import { formatToCurrency } from 'utils'

/**
 * Returns a function that converts a string/number into a pretty-formatted
 * representation of a sum in the account's currency of precision 2
 * eg 12640820901.56 -> £12,640,820,901.56
 */
const useAccountCurrency = (showZero?: boolean) => {
    const { activeAccount, loading } = useAccounts()
    return loading
        ? undefined
        : (number?: number | string) => {
              if (!number && !showZero) {
                  return undefined
              }

              if (!activeAccount?.currency) {
                  return Big(number || 0).toFixed(2)
              }

              return formatToCurrency(number || 0, activeAccount.currency)
          }
}

export default useAccountCurrency
