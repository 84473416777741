import type { SxProps } from '@mui/material';
import { styled } from '@mui/system';
import React, { useEffect, useRef } from 'react';

import luneLoader from '../images/lune-loader.json';
import { LuneTheme } from '../theme';

const Loading = ({ light, sx, ...rest }: { light?: boolean; sx?: SxProps }) => {
  const loadingContainerRef = useRef<HTMLDivElement>(null);

  /*
   * nextjs workaround
   * https://stackoverflow.com/questions/77612357/referenceerror-with-lottie-react-on-next-js-13-ssr-incompatibility
   *
   * Solves the following problem:
   *    Collecting page data  ...ReferenceError: document is not defined
   *    at createTag (/Users/rbruggem/src/lune-fe/node_modules/lottie-web/build/player/lottie_light.js:30:5)
   *    at /Users/rbruggem/src/lune-fe/node_modules/lottie-web/build/player/lottie_light.js:1316:20
   *    at /Users/rbruggem/src/lune-fe/node_modules/lottie-web/build/player/lottie_light.js:1323:6
   *    at /Users/rbruggem/src/lune-fe/node_modules/lottie-web/build/player/lottie_light.js:1540:4
   *    at /Users/rbruggem/src/lune-fe/node_modules/lottie-web/build/player/lottie_light.js:2:83
   *    at Object.<anonymous> (/Users/rbruggem/src/lune-fe/node_modules/lottie-web/build/player/lottie_light.js:5:3)
   *    at Module._compile (node:internal/modules/cjs/loader:1376:14)
   *    at Module._extensions..js (node:internal/modules/cjs/loader:1435:10)
   *    at Module.load (node:internal/modules/cjs/loader:1207:32)
   *    at Module._load (node:internal/modules/cjs/loader:1023:12)

   *    > Build error occurred
   *    Error: Failed to collect page data for /404
   *        at /Users/rbruggem/src/lune-fe/node_modules/next/dist/build/utils.js:1268:15
   *        at process.processTicksAndRejections (node:internal/process/task_queues:95:5) {
   *      type: 'Error'
   *    }
   *       Collecting page data  .
   *    —————————————————————————————————
   */
  async function getLottie(ref: typeof loadingContainerRef) {
    const lottie = await import('lottie-web/build/player/lottie_light');

    // The element may have disappeared between the time this function was called
    // and now (after the await call above), hence the ? operator to not crash if
    // that's the case.
    if (!ref.current) {
      return;
    }

    lottie.default.loadAnimation({
      container: ref.current.querySelector('#lune-loader') || new Element(),
      animationData: luneLoader,
    });
  }

  useEffect(() => {
    if (loadingContainerRef.current) {
      getLottie(loadingContainerRef);
    }
  }, [light, loadingContainerRef]);

  const LoadingContainer = styled('div')(
    LuneTheme.unstable_sx({
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      svg: {
        filter: light ? 'invert(1)' : '',
      },
      ...sx,
    }),
  );

  const LuneLoader = styled(`div`)(
    LuneTheme.unstable_sx({
      maxWidth: '100%',
      display: `flex`,
      justifyContent: `center`,
      alignItems: `center`,
    }),
  );

  return (
    <LoadingContainer {...rest} ref={loadingContainerRef}>
      <LuneLoader id="lune-loader" />
    </LoadingContainer>
  );
};

export default Loading;
