import { Bundle } from '@lune-climate/lune'
import { Button, Text, Tooltip } from '@lune-fe/lune-ui-lib'
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined'
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Box } from '@mui/material'
import { FC, useEffect, useMemo, useState } from 'react'

import { PublicPortfolio } from 'models/openDapi'
import { formatNumbers, formatToCurrency } from 'utils'
import BundlesGrid from 'views/Proposals/Proposal/BundlesGrid'
import BundlesList from 'views/Proposals/Proposal/BundlesList'
import ExpandableSection from 'views/Proposals/Proposal/ExpandableSection'
import { setFixedPercentages } from 'views/Proposals/Proposal/Option'

export const PROPOSAL_OPTION_PREFIX = 'proposal-option'

const Option: FC<{
    option: PublicPortfolio
    index: number
    proposalId: string
    currency: string
}> = ({ option, index, proposalId, currency }) => {
    const [expanded, setExpanded] = useState<boolean>(
        sessionStorage.getItem(`${PROPOSAL_OPTION_PREFIX}-${index}-${proposalId}`) === 'expanded',
    )

    useEffect(() => {
        sessionStorage.setItem(
            `${PROPOSAL_OPTION_PREFIX}-${index}-${proposalId}`,
            expanded ? 'expanded' : 'collapsed',
        )
    }, [expanded, index, proposalId])

    const selectedBundles: { bundle: Bundle; percentage: string }[] = useMemo(() => {
        return option.bundleSelection
            .map((b) => {
                return {
                    bundle: b.bundle as Bundle,
                    percentage: b.percentage.toString(),
                }
            })
            .sort((b1, b2) => Number(b1.percentage) - Number(b2.percentage))
    }, [option])
    const selectedBundlesWithFixedPercentages = setFixedPercentages(selectedBundles)

    return (
        <Box>
            <Text variant={'h5'}>Option {index + 1}</Text>
            <ExpandableSection
                expanded={expanded}
                bundlesList={
                    <BundlesList
                        selectedBundles={selectedBundlesWithFixedPercentages}
                        currency={currency}
                    />
                }
                bundlesGrid={
                    <BundlesGrid
                        currency={currency}
                        viewOnly={true}
                        scrollRef={undefined}
                        selectedBundles={selectedBundlesWithFixedPercentages}
                    />
                }
                optionsLength={option.bundleSelection.length}
            />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        gap: 1,
                        alignItems: 'center',
                    }}
                >
                    <Button
                        variant={'text'}
                        onClick={() => setExpanded(!expanded)}
                        leftIcon={
                            expanded ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />
                        }
                    >
                        {expanded ? 'Less details' : 'More details'}
                    </Button>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: 3,
                        alignItems: 'center',
                    }}
                >
                    <Text variant={'h6'}>{formatNumbers(option.quantity.amount)} tCO₂</Text>
                    <Text
                        variant={'h6'}
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            gap: 1,
                            alignItems: 'center',
                        }}
                    >
                        Total {formatToCurrency(option.estimatedTotalCost, currency)}
                        <Tooltip
                            title={
                                <Box sx={{ maxWidth: '140px', textAlign: 'center' }}>
                                    All prices are indicative until final purchase
                                </Box>
                            }
                            placement="top"
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <InfoOutlinedIcon
                                    sx={{
                                        verticalAlign: 'middle',
                                        width: '20px',
                                        height: '20px',
                                    }}
                                />
                            </Box>
                        </Tooltip>
                    </Text>
                </Box>
            </Box>
        </Box>
    )
}

export default Option
