import React, { FC, useMemo } from 'react';

import airportList from './airportList';
import AutocompleteSelect from './AutocompleteSelect';

type AirportType = {
  label: string;
  value: string;
};

export interface Props {
  value: string | undefined;
  onChange: (value: string | undefined) => void;
  error?: string;
  placeholder?: string;
  disabled?: boolean;
}

const AirportPicker: FC<Props> = ({ value, onChange, disabled, error, placeholder = 'Airport', ...rest }) => {
  const selectedValue = useMemo(() => {
    const airport = airportList.find((a) => a[0] === value);
    if (airport) {
      const [iata, name] = airport;
      return { label: `${name}, ${iata}`, value: iata };
    } else {
      return null;
    }
  }, [value]);

  return (
    <AutocompleteSelect
      error={error}
      label={placeholder}
      disabled={disabled}
      items={airportList.map(([iata, name]) => ({
        value: iata,
        label: `${name}, ${iata}`,
      }))}
      onChange={(airport: AirportType) => onChange(airport.value)}
      value={selectedValue as AirportType}
      {...rest}
    />
  );
};

export default AirportPicker;
