import type { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { styled } from '@mui/system';
import React, { FC, useState } from 'react';

import { LuneTheme } from '../theme';
import { luneAssetsToDynamicAssets } from '../utils';
import Lightbox from './Lightbox';
import Media from './Media';

type MediaItem = {
  url: string;
  type: 'image' | 'video';
  attributionText?: string;
  attributionUrl?: string;
  previewWidth?: number;
  previewHeight?: number;
};

const primaryMediaWidth = (n: number): number => {
  if (n >= 5) {
    return 2;
  }
  if (n > 1) {
    return 3;
  }
  return 4;
};

const sliceMedia = (media: MediaItem[]): MediaItem[] => {
  // support 1, 3 or 5 media items
  if (media.length >= 5) {
    return media.slice(0, 5);
  }
  if (media.length >= 3) {
    return media.slice(0, 3);
  }
  return media.slice(0, 1);
};

const firstImageAspectRatio = (mediaLength: number): string | undefined => {
  if (mediaLength === 1) {
    return `2 / 1`;
  }
  if (mediaLength === 3) {
    return `3 / 2`;
  }
  if (mediaLength === 5) {
    return `1 / 1`;
  }
  return undefined;
};

const MediaBox: FC<{
  media: MediaItem[];
  sx?: SxProps;
}> = ({ media, sx }) => {
  const [lightboxActiveIndex, setLightboxActiveIndex] = useState<number | undefined>(undefined);
  media = sliceMedia(media);

  const StyledImageList = styled(ImageList)(
    LuneTheme.unstable_sx({
      borderRadius: '40px',
      aspectRatio: '2 / 1',
      maxHeight: '100%',
      overflow: 'hidden',
      zIndex: 1,
      position: 'relative',
    }),
  );

  return (
    <>
      <StyledImageList sx={sx} variant="quilted" cols={4}>
        {media.map((item, i) => (
          // https://mui.com/material-ui/react-image-list/
          <ImageListItem
            key={i}
            cols={i === 0 ? primaryMediaWidth(media.length) : 1}
            rows={i === 0 ? 2 : 1}
            onClick={() => setLightboxActiveIndex(i)}
            sx={{
              overflow: 'hidden',
              '&:hover': {
                cursor: 'pointer',
              },
            }}
          >
            {item.type === 'image' && (
              <Media
                type={'image'}
                src={
                  item.previewWidth && item.previewHeight
                    ? luneAssetsToDynamicAssets(item.url, item.previewWidth, item.previewHeight)
                    : item.url
                }
                alt={item.attributionText}
                dynamicPlaceholder={{
                  width: 1000,
                  height: 1000,
                }}
                sx={{
                  img: {
                    aspectRatio: `${i === 0 ? firstImageAspectRatio(media.length) : '1 / 1'}`,
                    objectFit: 'cover',
                  },
                }}
              />
            )}
            {item.type === 'video' && (
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                }}
              >
                <Media
                  type={'video'}
                  src={item.url}
                  autoPlay
                  muted
                  loop
                  dynamicPlaceholder={{
                    height: 1000,
                    width: 1000,
                  }}
                  sx={{
                    video: {
                      objectFit: 'cover',
                      height: '100% !important',
                    },
                  }}
                />
              </Box>
            )}
          </ImageListItem>
        ))}
      </StyledImageList>
      <Lightbox
        onClose={() => setLightboxActiveIndex(undefined)}
        media={media}
        open={lightboxActiveIndex !== undefined}
        activeIndex={lightboxActiveIndex}
      />
    </>
  );
};

export default MediaBox;
