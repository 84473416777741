import { Table } from '@lune-fe/lune-ui-lib'
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined'
import { Tooltip } from '@mui/material'
import { Big } from 'big.js'
import { ReactElement } from 'react'

import ConditionalTooltip from 'components/ConditionalTooltip'
import { EmissionFactor } from 'models/openDapi'
import { formatNumbers, toTitleCase } from 'utils'
import { emissionFactorLabel } from 'views/EmissionFactors/EmissionFactorsFiltersBox'

function isEmissionFactorGated(emissionFactor: EmissionFactor): boolean {
    return emissionFactor.gasEmissions === 'emission_factor_gated'
}

function emissionFactorDisplay(emissionFactor: EmissionFactor): string {
    // Some emission factors are "gated" (the emission values are hidden from the customers
    // until they pay for them), we have every other piece of information about them though
    // so we can display the value as "***" and the units work normally (they're always there).
    const formatCo2e = (co2E: string) => {
        return Big(co2E).lt(0.001) ? formatNumbers(co2E, 6) : formatNumbers(co2E)
    }
    return `${
        isEmissionFactorGated(emissionFactor)
            ? '**********'
            : typeof emissionFactor.gasEmissions !== 'string' &&
              formatCo2e(emissionFactor.gasEmissions.co2E) // the dapi interceptor makes this camelcase
    } ${emissionFactor.numeratorUnit}CO₂e/${emissionFactor.denominatorUnit}`
}

function EmissionFactorTableRow({ emissionFactor }: { emissionFactor: EmissionFactor }) {
    const gatedTooltip = ({ children }: { children: ReactElement }) => {
        return (
            <Tooltip title="Contact us to get access to this data" placement="top">
                {children}
            </Tooltip>
        )
    }

    return (
        <Table.TableBody>
            <Table.TableRow data-testid={emissionFactor.id} hover sx={{ cursor: undefined }}>
                <Table.TableCell colSize={5}>
                    <LabelOutlinedIcon sx={{ marginRight: '26px' }} />
                    {toTitleCase(emissionFactor.name)}
                </Table.TableCell>
                <Table.TableCell>
                    <ConditionalTooltip
                        show={isEmissionFactorGated(emissionFactor)}
                        tooltip={gatedTooltip}
                    >
                        <div>{emissionFactorDisplay(emissionFactor)}</div>
                    </ConditionalTooltip>
                </Table.TableCell>
                <Table.TableCell>{emissionFactorLabel[emissionFactor.source]}</Table.TableCell>
                <Table.TableCell>{emissionFactor.sourceVersion}</Table.TableCell>
                <Table.TableCell>{toTitleCase(emissionFactor.region)}</Table.TableCell>
                <Table.TableCell alignEnd>{emissionFactor.publicationYear}</Table.TableCell>
            </Table.TableRow>
        </Table.TableBody>
    )
}

export default function EmissionFactors({
    paginatedEmissionFactors,
}: {
    paginatedEmissionFactors: EmissionFactor[]
}) {
    return (
        <Table.TableContainer>
            <Table>
                <Table.TableHead>
                    <Table.TableRow>
                        <Table.TableCell colSize={5}>Name</Table.TableCell>
                        <Table.TableCell>Emission Factor</Table.TableCell>
                        <Table.TableCell>Source</Table.TableCell>
                        <Table.TableCell>Version</Table.TableCell>
                        <Table.TableCell>Region</Table.TableCell>
                        <Table.TableCell>
                            {' '}
                            <div style={{ textAlign: `right`, width: `100%` }}>Year</div>
                        </Table.TableCell>
                    </Table.TableRow>
                </Table.TableHead>
                {paginatedEmissionFactors.map((emissionFactor: EmissionFactor, i: number) => (
                    <EmissionFactorTableRow key={i} emissionFactor={emissionFactor} />
                ))}
            </Table>
        </Table.TableContainer>
    )
}
