import {
    ContainerShippingMethod,
    ConvertedShipment,
    HugeOilTankerSeaShippingMethod,
    IdentifiedVesselShippingMethod,
    LogisticsSiteMethod,
    ResolvedShippingLegEmissionEstimate,
    RoadEmissionStandard,
    RoadMethodologyDetails,
    RoadShippingMethod,
    RoRoSeaShippingMethod,
    SeaShippingMethod,
    ShipmentConversionMethod,
    ShippingMethod,
    SimpleSeaShippingMethod,
    SimpleShippingMethod,
    VariableFuelSeaShippingMethod,
    VariableFuelVariableLoadSeaShippingMethod,
} from '@lune-climate/lune'
import Big from 'big.js'
import { startCase } from 'lodash'

// eslint-disable-next-line complexity
export const getShippingMethodLabel = (
    name:
        | ContainerShippingMethod.vessel_type
        | SimpleShippingMethod
        | SimpleSeaShippingMethod
        | SeaShippingMethod['vesselType']
        | LogisticsSiteMethod,
) => {
    switch (name) {
        case ContainerShippingMethod.vessel_type.CONTAINER_SHIP: {
            return 'Container ship'
        }
        case SimpleShippingMethod.CARGO_PLANE: {
            return 'Cargo Plane'
        }
        case SimpleShippingMethod.PASSENGER_PLANE: {
            return 'Passenger Plane'
        }
        case SimpleShippingMethod.DIESEL_TRUCK: {
            return 'Truck'
        }
        case SimpleShippingMethod.TRUCK_RIGID_7_5T_ELECTRIC: {
            return 'Electric truck'
        }
        case SimpleShippingMethod.TRUCK_GENERIC_VAN_ELECTRIC: {
            return 'Electric van'
        }
        case SimpleShippingMethod.ELECTRIC_FREIGHT_TRAIN: {
            return 'Electric train'
        }
        case SimpleShippingMethod.DIESEL_FREIGHT_TRAIN: {
            return 'Diesel train'
        }
        // SEA
        case VariableFuelSeaShippingMethod.vessel_type.SEA_BULK_CARRIER_SMALL: {
            return 'Bulk carrier, small'
        }
        case VariableFuelSeaShippingMethod.vessel_type.SEA_GENERAL_CARGO_LARGE: {
            return 'General cargo, large'
        }
        case VariableFuelSeaShippingMethod.vessel_type.SEA_GENERAL_CARGO_SMALL: {
            return 'General cargo, small'
        }
        case VariableFuelSeaShippingMethod.vessel_type.SEA_ROPAX: {
            return 'RoPax'
        }
        case VariableFuelSeaShippingMethod.vessel_type.SEA_OIL_TANKER_LARGE: {
            return 'Oil tanker, large'
        }
        case VariableFuelSeaShippingMethod.vessel_type.SEA_OIL_TANKER_MEDIUM: {
            return 'Oil tanker, medium'
        }
        case VariableFuelSeaShippingMethod.vessel_type.SEA_OIL_TANKER_SMALL: {
            return 'Oil tanker, small'
        }
        case VariableFuelVariableLoadSeaShippingMethod.vessel_type.SEA_BULK_CARRIER_LARGE: {
            return 'Bulk carrier, large'
        }
        case VariableFuelVariableLoadSeaShippingMethod.vessel_type.SEA_BULK_CARRIER_MEDIUM: {
            return 'Bulk carrier, medium'
        }
        case RoRoSeaShippingMethod.vessel_type.SEA_RORO: {
            return 'RoRo'
        }
        case HugeOilTankerSeaShippingMethod.vessel_type.SEA_OIL_TANKER_HUGE: {
            return 'Oil tanker, huge'
        }
        // INLAND
        case SimpleShippingMethod.INLAND_WATERWAY_CONTAINER_VESSEL_CONVOY: {
            return 'Container vessel, convoy'
        }
        case SimpleShippingMethod.INLAND_WATERWAY_COUPLED_CONVOY: {
            return 'Coupled convoy (163–185 m)'
        }
        case SimpleShippingMethod.INLAND_WATERWAY_CONTAINER_VESSEL_LARGE: {
            return 'Container vessel, large (135 m)'
        }
        case SimpleShippingMethod.INLAND_WATERWAY_CONTAINER_VESSEL_MEDIUM: {
            return 'Container vessel, medium (110 m)'
        }
        case SimpleShippingMethod.INLAND_WATERWAY_MOTOR_VESSEL_MEDIUM: {
            return 'Motor vessel, medium (85–110 m / 1,000–2,000 t)'
        }
        case SimpleShippingMethod.INLAND_WATERWAY_MOTOR_VESSEL_LARGE: {
            return 'Motor vessel, large (135 m / 2,000–3,000 t)'
        }
        case SimpleShippingMethod.INLAND_WATERWAY_MOTOR_VESSEL_SMALL: {
            return 'Motor vessel, small (50-80 m / 650-1,000 t)'
        }
        case SimpleShippingMethod.INLAND_WATERWAY_MOTOR_VESSEL_EXTRA_SMALL: {
            return 'Motor vessel, extra small (less than 50 m / 650 t)'
        }
        case SimpleShippingMethod.INLAND_WATERWAY_PUSHED_CONVOY_MEDIUM: {
            return 'Pushed convoy, medium (4–5 barges)'
        }
        case SimpleShippingMethod.INLAND_WATERWAY_PUSHED_CONVOY_LARGE: {
            return 'Pushed convoy, large (6 barges)'
        }
        case SimpleShippingMethod.INLAND_WATERWAY_PUSHED_CONVOY_SMALL: {
            return 'Pushed convoy, small (2 barges)'
        }
        case SimpleShippingMethod.INLAND_WATERWAY_TANKER: {
            return 'Tanker'
        }
        case LogisticsSiteMethod.MARITIME_CONTAINER_TERMINAL: {
            return 'Maritime container terminal'
        }
        case LogisticsSiteMethod.TRANSHIPMENT_SITE_AMBIENT: {
            return 'Ambient transhipment site'
        }
        case LogisticsSiteMethod.TRANSHIPMENT_SITE_CHILLED: {
            return 'Chilled transhipment site'
        }
        case LogisticsSiteMethod.TRANSHIPMENT_SITE_MIXED: {
            return 'Mixed transhipment site'
        }
        case LogisticsSiteMethod.STORAGE_AND_TRANSHIPMENT_AMBIENT: {
            return 'Ambient storage and transhipment site'
        }
        case LogisticsSiteMethod.STORAGE_AND_TRANSHIPMENT_CHILLED: {
            return 'Chilled storage and transhipment site'
        }
        case LogisticsSiteMethod.STORAGE_AND_TRANSHIPMENT_MIXED: {
            return 'Mixed storage and transhipment site'
        }
        case LogisticsSiteMethod.WAREHOUSE_AMBIENT: {
            return 'Ambient warehouse'
        }
        case LogisticsSiteMethod.WAREHOUSE_CHILLED: {
            return 'Chilled warehouse'
        }
        case LogisticsSiteMethod.WAREHOUSE_MIXED: {
            return 'Mixed warehouse'
        }
        case SimpleSeaShippingMethod.SEA_BULK_CARRIER_10DWKT_HFO: {
            return 'Bulk carrier, <10dwkt, HFO'
        }
        case SimpleSeaShippingMethod.SEA_BULK_CARRIER_10DWKT_VLSFO: {
            return 'Bulk carrier, <10dwkt, VLSFO'
        }
        case SimpleSeaShippingMethod.SEA_BULK_CARRIER_10DWKT_MDO: {
            return 'Bulk carrier, <10dwkt, MDO'
        }
        case SimpleSeaShippingMethod.SEA_BULK_CARRIER_35DWKT_HFO: {
            return 'Bulk carrier, 10-35dwkt, HFO'
        }
        case SimpleSeaShippingMethod.SEA_BULK_CARRIER_35DWKT_VLSFO: {
            return 'Bulk carrier, 10-35dwkt, VLSFO'
        }
        case SimpleSeaShippingMethod.SEA_BULK_CARRIER_35DWKT_MDO: {
            return 'Bulk carrier, 10-35dwkt, MDO'
        }
        case SimpleSeaShippingMethod.SEA_BULK_CARRIER_60DWKT_HFO: {
            return 'Bulk carrier, 35-60dwkt, HFO'
        }
        case SimpleSeaShippingMethod.SEA_BULK_CARRIER_60DWKT_VLSFO: {
            return 'Bulk carrier, 35-60dwkt, VLSFO'
        }
        case SimpleSeaShippingMethod.SEA_BULK_CARRIER_60DWKT_MDO: {
            return 'Bulk carrier, 35-60dwkt, MDO'
        }
        case SimpleSeaShippingMethod.SEA_BULK_CARRIER_100DWKT_HFO: {
            return 'Bulk carrier, 60-100dwkt, HFO'
        }
        case SimpleSeaShippingMethod.SEA_BULK_CARRIER_100DWKT_VLSFO: {
            return 'Bulk carrier, 60-100dwkt, VLSFO'
        }
        case SimpleSeaShippingMethod.SEA_BULK_CARRIER_100DWKT_MDO: {
            return 'Bulk carrier, 60-100dwkt, MDO'
        }
        case SimpleSeaShippingMethod.SEA_BULK_CARRIER_200DWKT_HFO: {
            return 'Bulk carrier, 100-200dwkt, HFO'
        }
        case SimpleSeaShippingMethod.SEA_BULK_CARRIER_200DWKT_VLSFO: {
            return 'Bulk carrier, 100-200dwkt, VLSFO'
        }
        case SimpleSeaShippingMethod.SEA_BULK_CARRIER_200DWKT_MDO: {
            return 'Bulk carrier, 100-200dwkt, MDO'
        }
        case SimpleSeaShippingMethod.SEA_BULK_CARRIER_OVER_200DWKT_HFO: {
            return 'Bulk carrier, >200dwkt, HFO'
        }
        case SimpleSeaShippingMethod.SEA_BULK_CARRIER_OVER_200DWKT_VLSFO: {
            return 'Bulk carrier, >200dwkt, VLSFO'
        }
        case SimpleSeaShippingMethod.SEA_BULK_CARRIER_OVER_200DWKT_MDO: {
            return 'Bulk carrier, >200dwkt, MDO'
        }
        case SimpleSeaShippingMethod.SEA_CHEMICAL_TANKER_5DWKT_HFO: {
            return 'Chemical tankerr, <5dwkt, HFO'
        }
        case SimpleSeaShippingMethod.SEA_CHEMICAL_TANKER_5DWKT_VLSFO: {
            return 'Chemical tanker, <5dwkt, VLSFO'
        }
        case SimpleSeaShippingMethod.SEA_CHEMICAL_TANKER_5DWKT_MDO: {
            return 'Chemical tanker, <5dwkt, MDO'
        }
        case SimpleSeaShippingMethod.SEA_CHEMICAL_TANKER_10DWKT_HFO: {
            return 'Chemical tanker, 5-10dwkt, HFO'
        }
        case SimpleSeaShippingMethod.SEA_CHEMICAL_TANKER_10DWKT_VLSFO: {
            return 'Chemical tanker, 5-10dwkt, VLSFO'
        }
        case SimpleSeaShippingMethod.SEA_CHEMICAL_TANKER_10DWKT_MDO: {
            return 'Chemical tanker, 5-10dwkt, MDO'
        }
        case SimpleSeaShippingMethod.SEA_CHEMICAL_TANKER_20DWKT_HFO: {
            return 'Chemical tanker, 10-20dwkt, HFO'
        }
        case SimpleSeaShippingMethod.SEA_CHEMICAL_TANKER_20DWKT_VLSFO: {
            return 'Chemical tanker, 10-20dwkt, VLSFO'
        }
        case SimpleSeaShippingMethod.SEA_CHEMICAL_TANKER_20DWKT_MDO: {
            return 'Chemical tanker, 10-20dwkt, MDO'
        }
        case SimpleSeaShippingMethod.SEA_CHEMICAL_TANKER_40DWKT_HFO: {
            return 'Chemical tanker, 20-40dwkt, HFO'
        }
        case SimpleSeaShippingMethod.SEA_CHEMICAL_TANKER_40DWKT_VLSFO: {
            return 'Chemical tanker, 20-40dwkt, VLSFO'
        }
        case SimpleSeaShippingMethod.SEA_CHEMICAL_TANKER_40DWKT_MDO: {
            return 'Chemical tanker, 20-40dwkt, MDO'
        }
        case SimpleSeaShippingMethod.SEA_CHEMICAL_TANKER_OVER_40DWKT_HFO: {
            return 'Chemical tanker, >40dwkt, HFO'
        }
        case SimpleSeaShippingMethod.SEA_CHEMICAL_TANKER_OVER_40DWKT_VLSFO: {
            return 'Chemical tanker, >40dwkt, VLSFO'
        }
        case SimpleSeaShippingMethod.SEA_CHEMICAL_TANKER_OVER_40DWKT_MDO: {
            return 'Chemical tanker, >40dwkt, MDO'
        }
        case SimpleSeaShippingMethod.SEA_GENERAL_CARGO_5DWKT_HFO: {
            return 'General cargo, <5dwkt, HFO'
        }
        case SimpleSeaShippingMethod.SEA_GENERAL_CARGO_5DWKT_VLSFO: {
            return 'General cargo, <5dwkt, VLSFO'
        }
        case SimpleSeaShippingMethod.SEA_GENERAL_CARGO_5DWKT_MDO: {
            return 'General cargo, <5dwkt, MDO'
        }
        case SimpleSeaShippingMethod.SEA_GENERAL_CARGO_10DWKT_HFO: {
            return 'General cargo, 5-10dwkt, HFO'
        }
        case SimpleSeaShippingMethod.SEA_GENERAL_CARGO_10DWKT_VLSFO: {
            return 'General cargo, 5-10dwkt, VLSFO'
        }
        case SimpleSeaShippingMethod.SEA_GENERAL_CARGO_10DWKT_MDO: {
            return 'General cargo, 5-10dwkt, MDO'
        }
        case SimpleSeaShippingMethod.SEA_GENERAL_CARGO_20DWKT_HFO: {
            return 'General cargo, 10-20dwkt, HFO'
        }
        case SimpleSeaShippingMethod.SEA_GENERAL_CARGO_20DWKT_VLSFO: {
            return 'General cargo, 10-20dwkt, VLSFO'
        }
        case SimpleSeaShippingMethod.SEA_GENERAL_CARGO_20DWKT_MDO: {
            return 'General cargo, 10-20dwkt, MDO'
        }
        case SimpleSeaShippingMethod.SEA_GENERAL_CARGO_OVER_20DWKT_HFO: {
            return 'General cargo, >20dwkt, HFO'
        }
        case SimpleSeaShippingMethod.SEA_GENERAL_CARGO_OVER_20DWKT_VLSFO: {
            return 'General cargo, >20dwkt, VLSFO'
        }
        case SimpleSeaShippingMethod.SEA_GENERAL_CARGO_OVER_20DWKT_MDO: {
            return 'General cargo, >20dwkt, MDO'
        }
        case SimpleSeaShippingMethod.SEA_LIQUEFIED_GAS_TANKER_50DWKT_HFO: {
            return 'Liquefied tanker, <50dwkt, HFO'
        }
        case SimpleSeaShippingMethod.SEA_LIQUEFIED_GAS_TANKER_50DWKT_VLSFO: {
            return 'Liquefied tanker, <50dwkt, VLSFO'
        }
        case SimpleSeaShippingMethod.SEA_LIQUEFIED_GAS_TANKER_50DWKT_MDO: {
            return 'Liquefied tanker, <50dwkt, MDO'
        }
        case SimpleSeaShippingMethod.SEA_LIQUEFIED_GAS_TANKER_100DWKT_HFO: {
            return 'Liquefied tanker, 50-100dwkt, HFO'
        }
        case SimpleSeaShippingMethod.SEA_LIQUEFIED_GAS_TANKER_100DWKT_VLSFO: {
            return 'Liquefied tanker, 50-100dwkt, VLSFO'
        }
        case SimpleSeaShippingMethod.SEA_LIQUEFIED_GAS_TANKER_100DWKT_MDO: {
            return 'Liquefied tanker, 50-100dwkt, MDO'
        }
        case SimpleSeaShippingMethod.SEA_LIQUEFIED_GAS_TANKER_200DWKT_HFO: {
            return 'Liquefied tanker, 100-200dwkt, HFO'
        }
        case SimpleSeaShippingMethod.SEA_LIQUEFIED_GAS_TANKER_200DWKT_VLSFO: {
            return 'Liquefied tanker, 100-200dwkt, VLSFO'
        }
        case SimpleSeaShippingMethod.SEA_LIQUEFIED_GAS_TANKER_200DWKT_MDO: {
            return 'Liquefied tanker, 100-200dwkt, MDO'
        }
        case SimpleSeaShippingMethod.SEA_LIQUEFIED_GAS_TANKER_OVER_200DWKT_HFO: {
            return 'Liquefied tanker, >200dwkt, HFO'
        }
        case SimpleSeaShippingMethod.SEA_LIQUEFIED_GAS_TANKER_OVER_200DWKT_VLSFO: {
            return 'Liquefied tanker, >200dwkt, VLSFO'
        }
        case SimpleSeaShippingMethod.SEA_LIQUEFIED_GAS_TANKER_OVER_200DWKT_MDO: {
            return 'Liquefied tanker, >200dwkt, MDO'
        }
        case SimpleSeaShippingMethod.SEA_OIL_TANKER_5DWKT_HFO: {
            return 'Oil tanker, <5dwkt, HFO'
        }
        case SimpleSeaShippingMethod.SEA_OIL_TANKER_5DWKT_VLSFO: {
            return 'Oil tanker, <5dwkt, VLSFO'
        }
        case SimpleSeaShippingMethod.SEA_OIL_TANKER_5DWKT_MDO: {
            return 'Oil tanker, <5dwkt, MDO'
        }
        case SimpleSeaShippingMethod.SEA_OIL_TANKER_10DWKT_HFO: {
            return 'Oil tanker, 5-10dwkt, HFO'
        }
        case SimpleSeaShippingMethod.SEA_OIL_TANKER_10DWKT_VLSFO: {
            return 'Oil tanker, 5-10dwkt, VLSFO'
        }
        case SimpleSeaShippingMethod.SEA_OIL_TANKER_10DWKT_MDO: {
            return 'Oil tanker, 5-10dwkt, MDO'
        }
        case SimpleSeaShippingMethod.SEA_OIL_TANKER_20DWKT_HFO: {
            return 'Oil tanker, 10-20dwkt, HFO'
        }
        case SimpleSeaShippingMethod.SEA_OIL_TANKER_20DWKT_VLSFO: {
            return 'Oil tanker, 10-20dwkt, VLSFO'
        }
        case SimpleSeaShippingMethod.SEA_OIL_TANKER_20DWKT_MDO: {
            return 'Oil tanker, 10-20dwkt, MDO'
        }
        case SimpleSeaShippingMethod.SEA_OIL_TANKER_60DWKT_HFO: {
            return 'Oil tanker, 20-60dwkt, HFO'
        }
        case SimpleSeaShippingMethod.SEA_OIL_TANKER_60DWKT_VLSFO: {
            return 'Oil tanker, 20-60dwkt, VLSFO'
        }
        case SimpleSeaShippingMethod.SEA_OIL_TANKER_60DWKT_MDO: {
            return 'Oil tanker, 20-60dwkt, MDO'
        }
        case SimpleSeaShippingMethod.SEA_OIL_TANKER_80DWKT_HFO: {
            return 'Oil tanker, 60-80dwkt, HFO'
        }
        case SimpleSeaShippingMethod.SEA_OIL_TANKER_80DWKT_VLSFO: {
            return 'Oil tanker, 60-80dwkt, VLSFO'
        }
        case SimpleSeaShippingMethod.SEA_OIL_TANKER_80DWKT_MDO: {
            return 'Oil tanker, 60-80dwkt, MDO'
        }
        case SimpleSeaShippingMethod.SEA_OIL_TANKER_112DWKT_HFO: {
            return 'Oil tanker, 80-112dwkt, HFO'
        }
        case SimpleSeaShippingMethod.SEA_OIL_TANKER_112DWKT_VLSFO: {
            return 'Oil tanker, 80-112dwkt, VLSFO'
        }
        case SimpleSeaShippingMethod.SEA_OIL_TANKER_112DWKT_MDO: {
            return 'Oil tanker, 80-112dwkt, MDO'
        }
        case SimpleSeaShippingMethod.SEA_OIL_TANKER_200DWKT_HFO: {
            return 'Oil tanker, 112-200dwkt, HFO'
        }
        case SimpleSeaShippingMethod.SEA_OIL_TANKER_200DWKT_VLSFO: {
            return 'Oil tanker, 112-200dwkt, VLSFO'
        }
        case SimpleSeaShippingMethod.SEA_OIL_TANKER_200DWKT_MDO: {
            return 'Oil tanker, 112-200dwkt, MDO'
        }
        case SimpleSeaShippingMethod.SEA_OIL_TANKER_OVER_200DWKT_HFO: {
            return 'Oil tanker, >200dwkt, HFO'
        }
        case SimpleSeaShippingMethod.SEA_OIL_TANKER_OVER_200DWKT_VLSFO: {
            return 'Oil tanker, >200dwkt, VLSFO'
        }
        case SimpleSeaShippingMethod.SEA_OIL_TANKER_OVER_200DWKT_MDO: {
            return 'Oil tanker, >200dwkt, MDO'
        }
        case SimpleSeaShippingMethod.SEA_OTHER_LIQUIDS_TANKER_1DWKT_HFO: {
            return 'Other liquids tanker, <1dwkt, HFO'
        }
        case SimpleSeaShippingMethod.SEA_OTHER_LIQUIDS_TANKER_1DWKT_VLSFO: {
            return 'Other liquids tanker, <1dwkt, VLSFO'
        }
        case SimpleSeaShippingMethod.SEA_OTHER_LIQUIDS_TANKER_1DWKT_MDO: {
            return 'Other liquids tanker, <1dwkt, MDO'
        }
        case SimpleSeaShippingMethod.SEA_OTHER_LIQUIDS_TANKER_OVER_1DWKT_HFO: {
            return 'Other liquids tanker, >1dwkt, HFO'
        }
        case SimpleSeaShippingMethod.SEA_OTHER_LIQUIDS_TANKER_OVER_1DWKT_VLSFO: {
            return 'Other liquids tanker, >1dwkt, VLSFO'
        }
        case SimpleSeaShippingMethod.SEA_OTHER_LIQUIDS_TANKER_OVER_1DWKT_MDO: {
            return 'Other liquids tanker, >1dwkt, MDO'
        }
        case SimpleSeaShippingMethod.SEA_ROPAX_2DWKT_HFO: {
            return 'Ro-Pax, <2dwkt, HFO'
        }
        case SimpleSeaShippingMethod.SEA_ROPAX_2DWKT_VLSFO: {
            return 'Ro-Pax, <2dwkt, VLSFO'
        }
        case SimpleSeaShippingMethod.SEA_ROPAX_2DWKT_MDO: {
            return 'Ro-Pax, <2dwkt, MDO'
        }
        case SimpleSeaShippingMethod.SEA_ROPAX_5DWKT_HFO: {
            return 'Ro-Pax, 2-5dwkt, HFO'
        }
        case SimpleSeaShippingMethod.SEA_ROPAX_5DWKT_VLSFO: {
            return 'Ro-Pax, 2-5dwkt, VLSFO'
        }
        case SimpleSeaShippingMethod.SEA_ROPAX_5DWKT_MDO: {
            return 'Ro-Pax, 2-5dwkt, MDO'
        }
        case SimpleSeaShippingMethod.SEA_ROPAX_10DWKT_HFO: {
            return 'Ro-Pax, 5-10dwkt, MDO'
        }
        case SimpleSeaShippingMethod.SEA_ROPAX_10DWKT_VLSFO: {
            return 'Ro-Pax, 5-10dwkt, VLSFO'
        }
        case SimpleSeaShippingMethod.SEA_ROPAX_10DWKT_MDO: {
            return 'Ro-Pax, 5-10dwkt, MDO'
        }
        case SimpleSeaShippingMethod.SEA_ROPAX_20DWKT_HFO: {
            return 'Ro-Pax, 10-20dwkt, HFO'
        }
        case SimpleSeaShippingMethod.SEA_ROPAX_20DWKT_VLSFO: {
            return 'Ro-Pax, 10-20dwkt, VLSFO'
        }
        case SimpleSeaShippingMethod.SEA_ROPAX_20DWKT_MDO: {
            return 'Ro-Pax, 10-20dwkt, MDO'
        }
        case SimpleSeaShippingMethod.SEA_ROPAX_OVER_20DWKT_HFO: {
            return 'Ro-Pax, >20dwkt, HFO'
        }
        case SimpleSeaShippingMethod.SEA_ROPAX_OVER_20DWKT_VLSFO: {
            return 'Ro-Pax, >20dwkt, VLSFO'
        }
        case SimpleSeaShippingMethod.SEA_ROPAX_OVER_20DWKT_MDO: {
            return 'Ro-Pax, >20dwkt, MDO'
        }
        case SimpleSeaShippingMethod.SEA_REFRIGERATED_BULK_CARRIER_2DWKT_HFO: {
            return 'Refrigerated bulk carrier, <2dwkt, HFO'
        }
        case SimpleSeaShippingMethod.SEA_REFRIGERATED_BULK_CARRIER_2DWKT_VLSFO: {
            return 'Refrigerated bulk carrier, <2dwkt, VLSFO'
        }
        case SimpleSeaShippingMethod.SEA_REFRIGERATED_BULK_CARRIER_2DWKT_MDO: {
            return 'Refrigerated bulk carrier, <2dwkt, MDO'
        }
        case SimpleSeaShippingMethod.SEA_REFRIGERATED_BULK_CARRIER_6DWKT_HFO: {
            return 'Refrigerated bulk carrier, 2-6dwkt, HFO'
        }
        case SimpleSeaShippingMethod.SEA_REFRIGERATED_BULK_CARRIER_6DWKT_VLSFO: {
            return 'Refrigerated bulk carrier, 2-6dwkt, VLSFO'
        }
        case SimpleSeaShippingMethod.SEA_REFRIGERATED_BULK_CARRIER_6DWKT_MDO: {
            return 'Refrigerated bulk carrier, 2-6dwkt, MDO'
        }
        case SimpleSeaShippingMethod.SEA_REFRIGERATED_BULK_CARRIER_10DWKT_HFO: {
            return 'Refrigerated bulk carrier, 6-10dwkt, HFO'
        }
        case SimpleSeaShippingMethod.SEA_REFRIGERATED_BULK_CARRIER_10DWKT_VLSFO: {
            return 'Refrigerated bulk carrier, 6-10dwkt, VLSFO'
        }
        case SimpleSeaShippingMethod.SEA_REFRIGERATED_BULK_CARRIER_10DWKT_MDO: {
            return 'Refrigerated bulk carrier, 6-10dwkt, MDO'
        }
        case SimpleSeaShippingMethod.SEA_REFRIGERATED_BULK_CARRIER_OVER_10DWKT_HFO: {
            return 'Refrigerated bulk carrier, >10dwkt, HFO'
        }
        case SimpleSeaShippingMethod.SEA_REFRIGERATED_BULK_CARRIER_OVER_10DWKT_VLSFO: {
            return 'Refrigerated bulk carrier, <10dwkt, VLSFO'
        }
        case SimpleSeaShippingMethod.SEA_REFRIGERATED_BULK_CARRIER_OVER_10DWKT_MDO: {
            return 'Refrigerated bulk carrier, <10dwkt, MDO'
        }
        case SimpleSeaShippingMethod.SEA_RORO_5DWKT_HFO: {
            return 'Ro-Ro, <5dwkt, HFO'
        }
        case SimpleSeaShippingMethod.SEA_RORO_5DWKT_VLSFO: {
            return 'Ro-Ro, <5dwkt, VLSFO'
        }
        case SimpleSeaShippingMethod.SEA_RORO_5DWKT_MDO: {
            return 'Ro-Ro, <5dwkt, MDO'
        }
        case SimpleSeaShippingMethod.SEA_RORO_10DWKT_HFO: {
            return 'Ro-Ro, 5-10dwkt, HFO'
        }
        case SimpleSeaShippingMethod.SEA_RORO_10DWKT_VLSFO: {
            return 'Ro-Ro, 5-10dwkt, VLSFO'
        }
        case SimpleSeaShippingMethod.SEA_RORO_10DWKT_MDO: {
            return 'Ro-Ro, 5-10dwkt, MDO'
        }
        case SimpleSeaShippingMethod.SEA_RORO_15DWKT_HFO: {
            return 'Ro-Ro, 10-15dwkt, HFO'
        }
        case SimpleSeaShippingMethod.SEA_RORO_15DWKT_VLSFO: {
            return 'Ro-Ro, 10-15dwkt, VLSFO'
        }
        case SimpleSeaShippingMethod.SEA_RORO_15DWKT_MDO: {
            return 'Ro-Ro, 10-15dwkt, MDO'
        }
        case SimpleSeaShippingMethod.SEA_RORO_OVER_15DWKT_HFO: {
            return 'Ro-Ro, >15dwkt, HFO'
        }
        case SimpleSeaShippingMethod.SEA_RORO_OVER_15DWKT_VLSFO: {
            return 'Ro-Ro, >15dwkt, VLSFO'
        }
        case SimpleSeaShippingMethod.SEA_RORO_OVER_15DWKT_MDO: {
            return 'Ro-Ro, >15dwkt, MDO'
        }
        case SimpleSeaShippingMethod.SEA_VEHICLE_CARRIER_30DWKT_HFO: {
            return 'Vehicle carrier, <30dwkt, HFO'
        }
        case SimpleSeaShippingMethod.SEA_VEHICLE_CARRIER_30DWKT_VLSFO: {
            return 'Vehicle carrier, <30dwkt, VLSFO'
        }
        case SimpleSeaShippingMethod.SEA_VEHICLE_CARRIER_30DWKT_MDO: {
            return 'Vehicle carrier, <30dwkt, MDO'
        }
        case SimpleSeaShippingMethod.SEA_VEHICLE_CARRIER_50DWKT_HFO: {
            return 'Vehicle carrier, 30-50dwkt, HFO'
        }
        case SimpleSeaShippingMethod.SEA_VEHICLE_CARRIER_50DWKT_VLSFO: {
            return 'Vehicle carrier, 30-50dwkt, VLSFO'
        }
        case SimpleSeaShippingMethod.SEA_VEHICLE_CARRIER_50DWKT_MDO: {
            return 'Vehicle carrier, 30-50dwkt, MDO'
        }
        case SimpleSeaShippingMethod.SEA_VEHICLE_CARRIER_OVER_50DWKT_HFO: {
            return 'Vehicle carrier, >50dwkt, HFO'
        }
        case SimpleSeaShippingMethod.SEA_VEHICLE_CARRIER_OVER_50DWKT_VLSFO: {
            return 'Vehicle carrier, >50dwkt, VLSFO'
        }
        case SimpleSeaShippingMethod.SEA_VEHICLE_CARRIER_OVER_50DWKT_MDO: {
            return 'Vehicle carrier, >50dwkt, MDO'
        }
        default: {
            return name
        }
    }
}

export type ShippingMethodType =
    | 'SimpleShippingMethod'
    | 'SeaShippingMethod'
    | 'ContainerShippingMethod'
    | `IdentifiedVesselShippingMethod`
    | `AirShippingMethod`
    | `RoadShippingMethod`

export type LogisticsSiteMethodType = 'LogisticsSiteMethod'

export const isSimpleShippingMethod = (selection?: ShippingMethod | LogisticsSiteMethod) => {
    return Object.values(SimpleShippingMethod).some((value) => value === selection)
}

export const isSimpleSeaShippingMethod = (selection?: ShippingMethod | LogisticsSiteMethod) => {
    // These functions are a bit annoying because compile time types and runtime types are inconsistent.
    // See src/views/CalculateEmissions/Logistics/ShipmentMethods.tsx, search for
    // value: {
    //     vesselType: '' as any,
    // },
    return (
        selection instanceof Object &&
        'vesselType' in selection &&
        Object.values(SimpleSeaShippingMethod).some(
            (val) => val === (selection.vesselType as unknown as SimpleSeaShippingMethod),
        )
    )
}

export const isContainerShippingMethod = (selection?: ShippingMethod | LogisticsSiteMethod) => {
    return (
        selection instanceof Object &&
        'vesselType' in selection &&
        Object.values(ContainerShippingMethod.vessel_type).some(
            (val) => val === selection.vesselType,
        )
    )
}
export const isIdentifiedVesselShippingMethod = (
    selection?: ShippingMethod | LogisticsSiteMethod,
) => {
    const selectionKeys = Object.keys(selection as IdentifiedVesselShippingMethod)
    return selectionKeys.includes('vesselImoNumber') || selectionKeys.includes('vesselName')
}

export const isLogisticsSiteMethod = (selection?: ShippingMethod | LogisticsSiteMethod) => {
    return (
        typeof selection === 'string' &&
        Object.values(LogisticsSiteMethod).includes(selection as LogisticsSiteMethod)
    )
}

export const isAirShippingMethod = (selection?: ShippingMethod | LogisticsSiteMethod) => {
    return typeof selection === 'object' && 'flightNumber' in selection
}

export const isRoadShippingMethod = (selection: ShippingMethod | LogisticsSiteMethod) => {
    return typeof selection === 'object' && 'vehicleType' in selection && 'fuel' in selection
}

export const getShippingMethodMainTypeFromValue = (
    selection: ShippingMethod | LogisticsSiteMethod,
): ShippingMethodType | LogisticsSiteMethodType => {
    if (isSimpleShippingMethod(selection)) {
        return 'SimpleShippingMethod'
    }

    if (isIdentifiedVesselShippingMethod(selection)) {
        return `IdentifiedVesselShippingMethod`
    }

    if (isContainerShippingMethod(selection)) {
        return 'ContainerShippingMethod'
    }

    if (isLogisticsSiteMethod(selection)) {
        return 'LogisticsSiteMethod'
    }

    if (isAirShippingMethod(selection)) {
        return 'AirShippingMethod'
    }

    if (isRoadShippingMethod(selection)) {
        return 'RoadShippingMethod'
    }

    return 'SeaShippingMethod'
}

export const getLoadLabel = (name: string): string => {
    switch (name) {
        case RoRoSeaShippingMethod.load.FREIGHT_ONLY: {
            return 'Freight only'
        }
        case RoRoSeaShippingMethod.load.TRAILER_ONLY: {
            return 'Trailer only'
        }
        case RoRoSeaShippingMethod.load.TRUCK_AND_TRAILER: {
            return 'Truck and trailer'
        }
        case VariableFuelVariableLoadSeaShippingMethod.load.AVERAGE: {
            return 'Average load'
        }
        case VariableFuelVariableLoadSeaShippingMethod.load.HEAVY: {
            return 'Heavy load'
        }
        default: {
            return name
        }
    }
}

export const isVariableFuelSeaShippingMethod = (selection?: ShippingMethod) => {
    return (
        selection instanceof Object &&
        'vesselType' in selection &&
        Object.values(VariableFuelSeaShippingMethod.vessel_type).some(
            (val) => val === selection.vesselType,
        )
    )
}
export const isVariableFuelVariableLoadSeaShippingMethod = (selection?: ShippingMethod) => {
    return (
        selection instanceof Object &&
        'vesselType' in selection &&
        Object.values(VariableFuelVariableLoadSeaShippingMethod.vessel_type).some(
            (val) => val === selection.vesselType,
        )
    )
}
export const isRoRoSeaShippingMethod = (selection?: ShippingMethod) => {
    return (
        selection instanceof Object &&
        'vesselType' in selection &&
        Object.values(RoRoSeaShippingMethod.vessel_type).some((val) => val === selection.vesselType)
    )
}
export const isHugeOilTankerSeaShippingMethod = (selection?: ShippingMethod) => {
    return (
        selection instanceof Object &&
        'vesselType' in selection &&
        Object.values(HugeOilTankerSeaShippingMethod.vessel_type).some(
            (val) => val === selection.vesselType,
        )
    )
}

export const getTradeLaneLabel = (name: string) => {
    const newName = name
        .replaceAll('disaggregated_other', 'other disaggregated')
        .replaceAll('aggregated_other', 'other aggregated')
        .replaceAll('disaggregated_', '')
        .replaceAll('aggregated_', '')
        .replaceAll('_', ' ')
    return startCase(newName)
        .replaceAll('Se', 'South East')
        .replaceAll('Ne', 'North East')
        .replaceAll('America East', 'America, East coast')
        .replaceAll('America West', 'America, West coast')
        .replaceAll('To', 'to')
}

export const resolvedLegToApiLeg = (
    resolvedLeg: ResolvedShippingLegEmissionEstimate,
    convertedShipment: ConvertedShipment | null,
    shipmentConversionMethod: ShipmentConversionMethod | null,
) => {
    // SAFETY: The types don't currently reflect this, but this function is expected to
    // be called only with "resolved legs" where route is always non-null.
    if (resolvedLeg.route === null) {
        throw new Error(`Unexpected resolved leg format ${JSON.stringify(resolvedLeg)}`)
    }
    const { route: resolvedLegRoute, ...resolvedLegWithoutRoute } = resolvedLeg
    return {
        ...resolvedLegWithoutRoute,
        route: {
            // SAFETY: While it's not currently reflected in the types, the API always
            // provides non-null labels in resolve legs' locations, hence the non-null
            // assertions.
            source: { code: resolvedLegRoute.source.label! },
            destination: {
                code: resolvedLegRoute.legs.at(-1)!.location.label!,
            },
        },
        responseRoute: resolvedLegRoute,
        convertedShipment,
        shipmentConversionMethod,
    }
}

export const getRoadVehicleName = (vehicleType: RoadShippingMethod['vehicleType']): string => {
    switch (vehicleType) {
        case RoadShippingMethod.vehicle_type.TRUCK_GENERIC_VAN:
            return 'Van <3.5 t GVW'
        case RoadShippingMethod.vehicle_type.TRUCK_RIGID_7_5T:
            return 'Rigid truck 3.5-7.5 t GVW'
        case RoadShippingMethod.vehicle_type.TRUCK_RIGID_12T:
            return 'Rigid truck 7.5-12 t GVW'
        case RoadShippingMethod.vehicle_type.TRUCK_RIGID_20T:
            return 'Rigid truck 12-20 t GVW'
        case RoadShippingMethod.vehicle_type.TRUCK_RIGID_26T:
            return 'Rigid truck 20-26 t GVW'
        case RoadShippingMethod.vehicle_type.TRUCK_RIGID_32T:
            return 'Rigid truck 26-32 t GVW'
        case RoadShippingMethod.vehicle_type.TRUCK_ARTICULATED_34T:
            return 'Articulated truck <34 t GVW'
        case RoadShippingMethod.vehicle_type.TRUCK_ARTICULATED_40T:
            return 'Articulated truck 34-40 t GVW'
    }
    throw new Error(`Unsupported vehicleType: ${vehicleType}`)
}

export const getRoadEmissionStandard = (
    emissionStandard: RoadMethodologyDetails['emissionStandard'],
    options?: { shortForm?: boolean },
): string => {
    let name: string | undefined
    switch (emissionStandard) {
        case RoadEmissionStandard.EURO1:
            name = 'EURO 1'
            break
        case RoadEmissionStandard.EURO2:
            name = 'EURO 2'
            break
        case RoadEmissionStandard.EURO3:
            name = 'EURO 3'
            break
        case RoadEmissionStandard.EURO4:
            name = 'EURO 4'
            break
        case RoadEmissionStandard.EURO5:
            name = 'EURO 5'
            break
        case RoadEmissionStandard.EURO6:
            name = 'EURO 6'
    }
    if (!name) {
        throw new Error(`Unsupported emission standard: ${emissionStandard}`)
    }

    return options?.shortForm ? name : `${name} standard`
}

export const getLoadFactorLabel = (loadFactor: number): string => {
    return `${Big(loadFactor).mul(100)}% load factor`
}

export const getEmptyRunsFactorLabel = (emptyRunsFactor: number): string => {
    return `${Big(emptyRunsFactor).mul(100)}% empty runs`
}

// eslint-disable-next-line complexity
export const getRoadGrandientAndSituationLabel = (
    gradient: RoadMethodologyDetails['gradient'] | undefined,
    situation: RoadMethodologyDetails['situation'] | undefined,
): string => {
    switch (`${gradient}/${situation}`) {
        case 'flat/city_urban_freeflow':
            return 'City, flat, freeflow traffic'
        case 'hilly/city_urban_freeflow':
            return 'City, hilly, freeflow traffic'
        case 'mountainous/city_urban_freeflow':
            return 'City, mountainous, freeflow traffic'
        case 'flat/motorway_urban_freeflow':
            return 'Motorway, flat, freeflow traffic'
        case 'hilly/motorway_urban_freeflow':
            return 'Motorway, hilly, freeflow traffic'
        case 'mountainous/motorway_urban_freeflow':
            return 'Motorway, mountainous, freeflow traffic'
        case 'flat/motorway_rural_freeflow':
            return 'Rural motorway, flat, freeflow traffic'
        case 'hilly/motorway_rural_freeflow':
            return 'Rural motorway, hilly, freeflow traffic'
        case 'mountainous/motorway_rural_freeflow':
            return 'Rural motorway, mountainous, freeflow traffic'
        case 'undefined/city_urban_freeflow':
            return 'City, freeflow traffic'
        case 'undefined/motorway_urban_freeflow':
            return 'Motorway, freeflow traffic'
        case 'undefined/motorway_rural_freeflow':
            return 'Rural, motorway, freeflow traffic'
        case 'flat/undefined':
            return 'Flat'
        case 'hilly/undefined':
            return 'Hilly'
        case 'mountainous/undefined':
            return 'Mountainous'
    }

    throw new Error(`Unsupported gradient/situation: ${gradient} / ${situation}`)
}
