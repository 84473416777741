import { countriesList, Text, Tile } from '@lune-fe/lune-ui-lib'
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import Co2OutlinedIcon from '@mui/icons-material/Co2Outlined'
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import PaymentsIcon from '@mui/icons-material/PaymentsOutlined'
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined'
import PublicOffOutlinedIcon from '@mui/icons-material/PublicOffOutlined'
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined'
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined'
import Box from '@mui/material/Box'
import { FC, useMemo } from 'react'

import useAccounts from 'hooks/useAccounts'
import { ProposalRequest } from 'models/openDapi'
import { formatNumbers, formatToCurrency } from 'utils'
import {
    carbonPermanenceList,
    offsetTypeList,
} from 'views/Proposals/ProposalRequestForm/ProjectRequirementsStep'
import { ProjectTypesWithLabels } from 'views/Proposals/ProposalRequestForm/shared'

interface ResultTileProps {
    // eslint-disable-next-line no-undef
    icon: JSX.Element | null
    // eslint-disable-next-line no-undef
    label: JSX.Element | string | null
    // eslint-disable-next-line no-undef
    subLabel?: JSX.Element | string | null
}

const ResultLabeledSectionAndTiles: FC<{
    label: string
    tiles: ResultTileProps[]
    dataTestId?: string
}> = ({ label, tiles, dataTestId }) => {
    return (
        <Box>
            <Text variant={'h6'} sx={{ mb: 4 }}>
                {label}
            </Text>
            {tiles.map((tile, index) => (
                <>
                    {tile.label && tile.icon && (
                        <Tile
                            sx={{ svg: { color: `Grey900` } }}
                            icon={tile.icon}
                            data-testId={`${dataTestId}-tile-${index}`}
                        >
                            <Text variant={'body2'}>{tile.label}</Text>
                            {tile.subLabel && (
                                <Text variant={'caption'} sx={{ color: `Grey600` }}>
                                    {tile.subLabel}
                                </Text>
                            )}
                        </Tile>
                    )}
                </>
            ))}
        </Box>
    )
}

export const Recap: FC<{ formValues: ProposalRequest }> = ({ formValues }) => {
    const { activeAccount } = useAccounts()
    const {
        quantity,
        price,
        pricePerTonne,
        registry,
        carbonPermanence,
        offsetType,
        projectType,
        geographiesExcluded,
        geographiesIncluded,
        readyToBuy,
        numberOfBundles,
    } = formValues

    const offsettingTiles = useMemo(() => {
        const tiles: ResultTileProps[] = []
        if (quantity) {
            tiles.push({
                label: `${formatNumbers(quantity.amount, 2)} tCO₂`,
                icon: <Co2OutlinedIcon />,
            })
        }
        if (activeAccount) {
            if (pricePerTonne) {
                tiles.push({
                    label: `${formatToCurrency(pricePerTonne, activeAccount.currency)}/tCO₂`,
                    icon: <PaymentsIcon />,
                })
            }
            if (price) {
                tiles.push({
                    label: `${formatToCurrency(price, activeAccount.currency)}`,
                    icon: <PaymentsIcon />,
                })
            }
        }
        return tiles
    }, [activeAccount, price, pricePerTonne, quantity])

    const goalsTiles = useMemo(() => {
        const tiles: ResultTileProps[] = [
            {
                label: readyToBuy
                    ? "I'm ready to buy, I want to see portfolio options"
                    : "I'm browsing, will need to seek approval",
                icon: <FlagOutlinedIcon />,
            },
        ]
        return tiles
    }, [readyToBuy])

    const geographyTiles = useMemo(() => {
        const tiles: ResultTileProps[] = []
        if (geographiesIncluded?.items.length) {
            tiles.push({
                label: `Prioritise ${geographiesIncluded.items
                    .map((countryCode) => countriesList.find((c) => c[2] === countryCode))
                    .map((c) => c?.[0])
                    .join(', ')}`,
                icon: <PublicOutlinedIcon />,
                subLabel: geographiesIncluded.mustHave ? 'Must have' : null,
            })
        }
        if (geographiesExcluded?.items.length) {
            tiles.push({
                label: `Exclude ${geographiesExcluded.items
                    .map((countryCode) => countriesList.find((c) => c[2] === countryCode))
                    .map((c) => c?.[0])
                    .join(', ')}`,
                icon: <PublicOffOutlinedIcon />,
                subLabel: geographiesExcluded.mustHave ? 'Must have' : null,
            })
        }
        return tiles
    }, [geographiesIncluded, geographiesExcluded])

    const projectRequirementsTiles = useMemo(() => {
        const tiles: ResultTileProps[] = []
        tiles.push({
            label: `${numberOfBundles[0]} - ${numberOfBundles[1]} projects`,
            icon: <PinDropOutlinedIcon />,
        })
        if (offsetType?.items.length) {
            tiles.push({
                label: `${offsetType.items
                    .map((item) => offsetTypeList.find((i) => i.value === item)?.label)
                    .join(', ')}`,
                icon: <CloudDownloadOutlinedIcon />,
                subLabel: offsetType.mustHave ? 'Must have' : null,
            })
        }
        if (carbonPermanence?.items.length) {
            tiles.push({
                label: `${carbonPermanence.items
                    .map((item) => carbonPermanenceList.find((i) => i.value === item)?.label)
                    .join(', ')}`,
                icon: <LockOutlinedIcon />,
                subLabel: carbonPermanence.mustHave ? 'Must have' : null,
            })
        }
        if (registry?.items.length) {
            tiles.push({
                label: `${registry.items.join(', ')}`,
                icon: <VerifiedOutlinedIcon />,
                subLabel: registry.mustHave ? 'Must have' : null,
            })
        }
        if (projectType?.items.length) {
            tiles.push({
                label: `${projectType.items
                    .map((item) => ProjectTypesWithLabels[item])
                    .join(', ')}`,
                icon: <CategoryOutlinedIcon />,
                subLabel: projectType.mustHave ? 'Must have' : null,
            })
        }
        return tiles
    }, [carbonPermanence, projectType, offsetType, numberOfBundles, registry])

    return (
        <>
            {!!offsettingTiles.length && (
                <ResultLabeledSectionAndTiles
                    label={'Offsetting'}
                    dataTestId={'proposal-request-recap-offsetting'}
                    tiles={offsettingTiles}
                />
            )}
            <ResultLabeledSectionAndTiles
                label={'Goals'}
                dataTestId={'proposal-request-recap-goals'}
                tiles={goalsTiles}
            />
            {!!geographyTiles.length && (
                <ResultLabeledSectionAndTiles
                    label={'Geography'}
                    dataTestId={'proposal-request-recap-geography'}
                    tiles={geographyTiles}
                />
            )}
            {!!projectRequirementsTiles.length && (
                <ResultLabeledSectionAndTiles
                    label={'Project requirements'}
                    dataTestId={'proposal-request-recap-project-requirements'}
                    tiles={projectRequirementsTiles}
                />
            )}
        </>
    )
}

export default Recap
