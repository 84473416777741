import { FC } from 'react'

import { ResultLabeledSectionAndTiles } from 'views/CalculateEmissions'
import { buildCompanyFootprintBreakdownTile } from 'views/CalculateEmissions/CompanyCarbonFootprint/buildCompanyFootprintBreakdownTile'
import {
    Categories,
    ChartType,
    Scopes,
} from 'views/CalculateEmissions/CompanyCarbonFootprint/CompanyCarbonFootprintResults'
import { ResultTileProps } from 'views/CalculateEmissions/Shared/ResultLabeledSectionAndTiles'

const CompanyCarbonFootprintResultsBreakdown: FC<{
    chartType: string
    estimation: any
}> = ({ chartType, estimation }) => {
    const toTile = (breakDown: { name: string; inputs: string[] }) => {
        return (
            <ResultLabeledSectionAndTiles
                label={breakDown.name}
                tiles={breakDown.inputs.map((input) => {
                    const tile = buildCompanyFootprintBreakdownTile(input, estimation.request)
                    if (tile) {
                        return tile as ResultTileProps
                    } else {
                        return {
                            label: null,
                            icon: null,
                        }
                    }
                })}
            />
        )
    }
    return (
        <>
            {chartType === ChartType.BY_SCOPE ? (
                <>
                    {Object.entries(Scopes)
                        .filter(
                            ([key]) => estimation[key as keyof typeof estimation].amount !== '0',
                        )
                        .map((scope) => toTile(scope[1]))}
                </>
            ) : (
                <>
                    {Object.entries(Categories)
                        .filter(
                            ([key]) =>
                                (estimation.components[key as keyof typeof estimation.components]
                                    ?.amount ?? '0') !== '0',
                        )
                        .map((category) => toTile(category[1]))}
                </>
            )}
        </>
    )
}

export default CompanyCarbonFootprintResultsBreakdown
