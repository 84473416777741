import type { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import { Breakpoint } from '@mui/system';
import React, { RefObject, useEffect, useRef, useState } from 'react';

const centeredProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

// eslint-disable-next-line complexity
const ModalContentComponent = ({
  children,
  header,
  subheader,
  actions,
  withoutSpacing,
  stickyFooter,
  fullScreen,
}: {
  children?: React.ReactNode;
  header?: React.ReactNode;
  subheader?: React.ReactNode;
  actions?: React.ReactNode;
  withoutSpacing?: boolean;
  stickyFooter?: boolean;
  fullScreen?: boolean;
}) => {
  const modalContentRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const [isModalContentScrollable, setIsModalContentScrollable] = useState<boolean>(false);

  useEffect(() => {
    function handleResize() {
      const modalRef = modalContentRef.current;
      if (modalRef) {
        setIsModalContentScrollable(modalRef.scrollHeight > modalRef.clientHeight);
      }
    }

    if (!fullScreen) {
      window.addEventListener('resize', handleResize);
    }

    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const modalRef = modalContentRef.current;
    if (actions && stickyFooter && !fullScreen && modalRef) {
      setIsModalContentScrollable(modalRef.scrollHeight > modalRef.clientHeight);
    }
  }, [actions, stickyFooter]);

  return (
    <Box
      ref={modalContentRef}
      sx={{
        p: withoutSpacing ? 0 : 6,
        pb: !actions ? 6 : 0,
        overflow: 'auto',
        position: 'relative',
      }}
    >
      {header && (
        <Box className={'modal-header'} {...centeredProperties}>
          {header}
        </Box>
      )}
      {subheader && (
        <Box className={'modal-subheader'} {...centeredProperties} mt={2}>
          {subheader}
        </Box>
      )}
      {children && (
        <Box
          className={'modal-content'}
          sx={{
            display: 'flex',
            width: '100%',
            mt: withoutSpacing ? 0 : 6,
            mb: isModalContentScrollable ? 3 : 0,
          }}
        >
          {children}
        </Box>
      )}
      {actions && (
        <Box
          className={'modal-actions'}
          sx={{
            p: withoutSpacing ? 0 : 6,
            ...(stickyFooter && {
              position: 'sticky',
              bottom: 0,
              left: withoutSpacing ? '0' : '-48px',
              right: withoutSpacing ? '0' : '-48px',
              mr: withoutSpacing ? 0 : -6,
              ml: withoutSpacing ? 0 : -6,
              width: 'auto',
              ...(isModalContentScrollable && {
                background: 'white',
                boxShadow:
                  '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)',
              }),
            }),
            ...centeredProperties,
          }}
          columnGap={1}
        >
          {actions}
        </Box>
      )}
    </Box>
  );
};

/**
 * Controlled Modal component
 * @param open - Used to show/hide Modal
 * @param children - Children nodes that go inside Modal tags, will be rendered inside Modal content
 * @param header - Nodes that will be rendered on the Modal header.
 * Can be used to render a modal title
 * @param subheader - Nodes that will be rendered on the Modal header.
 * Can be used to render a modal description
 * @param actions - Nodes that will be rendered on the Modal footer.
 * Can be used to render modal action buttons
 * @param className - Override or extend the classes applied to the component
 * @param disableEscapeKeyDown - `false` by default; used to disable closing modal on ESC press
 * @param fullScreen - If `true`, the modal is full-screen.
 * @param fullWidth - If true, the dialog stretches to `maxWidth`.
 * Notice that the dialog width grow is limited by the default margin.
 * @param maxWidth - Determine the max-width of the Modal. The Modal width grows with the size of the screen.
 * Set to `false` to disable maxWidth
 * @param onClose - Callback fired when the component requests to be closed.
 * should be used to set 'open' prop to false in order to close the modal
 */
const Modal = ({
  open,
  children,
  header,
  subheader,
  actions,
  className,
  disableEscapeKeyDown,
  fullScreen,
  fullWidth,
  maxWidth,
  onClose,
  sx,
  withoutSpacing,
  stickyFooter = true,
  ...rest
}: {
  open: boolean;
  children?: React.ReactNode;
  onClose: (event: any, reason: string) => void;
  header?: React.ReactNode;
  subheader?: React.ReactNode;
  actions?: React.ReactNode;
  className?: string;
  disableEscapeKeyDown?: boolean;
  fullScreen?: boolean;
  fullWidth?: boolean;
  maxWidth?: false | Breakpoint | undefined;
  withoutSpacing?: boolean;
  sx?: SxProps;
  stickyFooter?: boolean;
}): JSX.Element => {
  return (
    <Dialog
      open={open}
      className={className || ''}
      onClose={onClose}
      {...(fullWidth && { fullWidth })}
      {...(maxWidth && { maxWidth })}
      {...(fullScreen && { fullScreen })}
      {...(disableEscapeKeyDown && { disableEscapeKeyDown })}
      sx={{
        '.MuiPaper-root': {
          p: 0,
          boxShadow: '0px 24px 64px rgba(0, 0, 0, 0.15)',
          borderRadius: '24px',
          maxHeight: fullScreen ? '100vh' : 'calc(100vh - 32px)',
          boxSizing: 'border-box',
          overflow: 'hidden',
          zIndex: 9,
        },
        ...(sx || ''),
      }}
      {...rest}
    >
      <ModalContentComponent
        header={header}
        actions={actions}
        subheader={subheader}
        stickyFooter={stickyFooter}
        fullScreen={fullScreen}
        withoutSpacing={withoutSpacing}
      >
        {children}
      </ModalContentComponent>
    </Dialog>
  );
};

export default Modal;
