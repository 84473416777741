import { ContainerShippingMethod } from '@lune-climate/lune'
import { AutocompleteSelect, Text } from '@lune-fe/lune-ui-lib'
import { FC, memo, useEffect, useMemo, useState } from 'react'

import { getTradeLaneLabel } from 'views/CalculateEmissions/Logistics/LogisticsUtils'

type ListOption = {
    group: string
    label: string
    value: string
}

export interface Props {
    value?: string
    onChange: (value: string | undefined) => void
    error?: string
    disabled?: boolean
}

const TradeLane: FC<Props> = ({ value, onChange, error, disabled }) => {
    const [tradeLaneList, setTradeLaneList] = useState<ListOption[]>([])

    useEffect(() => {
        const list = Object.entries(ContainerShippingMethod.trade_lane).map(([key, value]) => {
            return {
                label: getTradeLaneLabel(value),
                value,
                group: key.includes('DISAGGREGATED') ? 'Disaggregated' : 'Aggregated',
            }
        })
        setTradeLaneList(list)
    }, [])

    const selectedValue = useMemo(() => {
        const selectedMethod = tradeLaneList.find((tradeLane) => tradeLane.value === value)
        return selectedMethod
            ? {
                  label: selectedMethod.label,
                  value: selectedMethod.value,
                  group: selectedMethod.group,
              }
            : {
                  label: '',
                  value: '',
                  group: '',
              }
    }, [value, tradeLaneList])

    return (
        <AutocompleteSelect
            disabled={disabled}
            label={'Trade lane (Optional)'}
            error={error}
            onChange={(selected: ListOption | undefined) => onChange(selected?.value)}
            value={selectedValue as ListOption}
            items={tradeLaneList}
            groupBy={(option: ListOption) => option.group}
            customOptions={(option) => (
                <Text variant={'body3'} sx={{ ml: 4 }}>
                    {option.label}
                </Text>
            )}
        />
    )
}

export default memo(TradeLane)
