import { Text } from '@lune-fe/lune-ui-lib'
import Box from '@mui/material/Box'
import { FC, useEffect, useState } from 'react'

import useAccountCurrency from 'hooks/useAccountCurrency'
import useIsTestMode from 'hooks/useIsTestMode'
import { OrderType } from 'models/order'
import { formatNumbers } from 'utils'
import { BundleData } from 'views/Dashboard/Charts/OrdersChartConfig'

interface TotalPlacedAnalytics {
    totalPlacedOffsetValue: string
    totalPlacedOffsetQuantity: string
}

const Totals: FC<{ data: BundleData[]; analyticsType: OrderType }> = ({ data, analyticsType }) => {
    const [analytics, setAnalytics] = useState<TotalPlacedAnalytics>()
    const isTestMode = useIsTestMode()

    useEffect(() => {
        const quantity: number = data
            .map((d: BundleData) => d.timeseries[d.timeseries.length - 1].quantity)
            .reduce((a, b) => Number(a) + Number(b), 0)
        const value: number = data
            .map((d: BundleData) => d.timeseries[d.timeseries.length - 1].value)
            .reduce((a, b) => Number(a) + Number(b), 0)
        setAnalytics({
            totalPlacedOffsetQuantity: quantity.toString(),
            totalPlacedOffsetValue: value.toString(),
        })
    }, [data])

    const toCurrency = useAccountCurrency()
    const sharedSx = {
        position: 'absolute',
        left: 0,
        transition: 'all .3s ease-in 0s',
        lineHeight: 'unset',
    }

    return (
        <Box
            sx={{
                position: 'relative',
                height: '65px',
                // 320 is the buttons group fixed width
                width: 'calc(100% - 320px)',
                '.MuiTypography-root': {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    width: '100%',
                },
            }}
        >
            <Text
                data-testid={`orders-total-quantity-${isTestMode ? 'test' : 'live'}`}
                variant={'h4'}
                sx={{
                    ...sharedSx,
                    top: analyticsType === OrderType.VALUE ? '43px' : 0,
                    fontSize: analyticsType === OrderType.VALUE ? '14px' : '36px',
                    zIndex: analyticsType === OrderType.VALUE ? '9' : '0',
                }}
            >
                {analytics?.totalPlacedOffsetQuantity && (
                    <>
                        {formatNumbers(Math.round(Number(analytics.totalPlacedOffsetQuantity)))}{' '}
                        tCO₂ purchased
                    </>
                )}
            </Text>
            <Text
                data-testid={'orders-total-value'}
                variant={'h6'}
                sx={{
                    ...sharedSx,
                    top: analyticsType === OrderType.VALUE ? 0 : '43px',
                    fontSize: analyticsType === OrderType.VALUE ? '36px' : '14px',
                    zIndex: analyticsType === OrderType.VALUE ? '0' : '9',
                }}
            >
                {analytics?.totalPlacedOffsetValue && (
                    <>{toCurrency?.(analytics.totalPlacedOffsetValue)} spent</>
                )}
            </Text>
        </Box>
    )
}

export default Totals
